import { imageConfigs } from '../../configs';

//Method for uploading and resizing an image
export const ImageUploadResize = ({ noResize } = {}) => {
    return new Promise((resolve) => {
        const fileElm = document.createElement('input');
        fileElm.setAttribute('type', 'file');
        fileElm.setAttribute('value', 'picture');
        fileElm.setAttribute('accept', 'image/jpg, image/jpeg, image/png, image/gif');
        fileElm.setAttribute('hidden', true);
        fileElm.click();
        fileElm.onchange = () => {
            const file = fileElm.files[0];
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                const type = reader.result.substring(reader.result.indexOf('/') + 1, reader.result.indexOf(';'));
                if (noResize) resolve({ type, base64Data: reader.result });
                else {
                    const img = new Image();
                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        canvas.width = imageConfigs.maxWidth;
                        canvas.height = imageConfigs.maxHeight;
                        const scale = Math.max(canvas.width / img.width, canvas.height / img.height);
                        const x = (canvas.width / 2) - (img.width / 2) * scale;
                        const y = (canvas.height / 2) - (img.height / 2) * scale;
                        ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
                        const base64Data = canvas.toDataURL('image/' + type);
                        fileElm.remove();
                        img.remove();
                        canvas.remove();
                        resolve({ type, base64Data });
                    }
                    img.src = reader.result;
                }
            }, false);
            if (file) reader.readAsDataURL(file);
        }
    });
}
