import Profile from './Profile/Profile';
import Settings from './Settings/Settings';
import Statistics from './Statistics/Statistics';
import Regions from './Regions/Regions';
import Categories from './Categories/Categories';
import Configs from './Configs/Configs';
import Promos from './Promos/Promos';
import Ads from './Ads/Ads';
import Notifications from './Notifications/Notifications';
import Files from './Files/Files';
import Users from './Users/Users';
import Stores from './Stores/Stores';
import Lists from './Lists/Lists';
import Products from './Products/Products';
import Reviews from './Reviews/Reviews';
import Orders from './Orders/Orders';
import Support from './Support/Support';

import { AccountCircle, Settings as SettingsIcon, Assessment, People, LocationCity, MenuBook, Tune, LocalOffer, ViewCarousel,
    NotificationsActive, PhotoLibrary, Storefront, Receipt, Message } from '@material-ui/icons';

export { Splash } from './Splash/Splash';
export { Login } from './Login/Login';

export const MENU_STACK = {
    profile: { icon: AccountCircle, component: Profile },
    settings: { icon: SettingsIcon, component: Settings }
};

export const ADMIN_STACK = {
    statistics: { icon: Assessment, component: Statistics },
    regions: { icon: LocationCity, component: Regions },
    categories: { icon: MenuBook, component: Categories },
    configs: { icon: Tune, component: Configs }
};

export const MAIN_STACK = {
    promos: { icon: LocalOffer, component: Promos },
    ads: { icon: ViewCarousel, component: Ads },
    notifications: { icon: NotificationsActive, component: Notifications },
    files: { icon: PhotoLibrary, component: Files },
    users: { icon: People, component: Users },
    stores: { icon: Storefront, component: Stores },
    lists: { icon: null, component: Lists },
    products: { icon: null, component: Products },
    reviews: { icon: null, component: Reviews },
    orders: { icon: Receipt, component: Orders },
    support: { icon: Message, component: Support }
};
