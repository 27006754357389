import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme, Paper, Box, Typography, Switch, Button, OutlinedInput, IconButton, Dialog, DialogTitle, DialogContent,
  DialogActions, CircularProgress} from '@material-ui/core';
import { Delete, Add, Error } from '@material-ui/icons';
import { callFunction, getConfig } from '../../api';
import { useSnackbar } from 'notistack';
import { useLoading } from '../../utils';

const Configs = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const showLoading = useLoading();
  //Used states
  const [ preparationTime, setPreparationTime ] = useState('');
  const [ timePerKm, setTimePerKm ] = useState('');
  const [ supportNumbers, setSupportNumbers ] = useState([]);
  const [ foodAndroid, setFoodAndroid ] = useState('');
  const [ foodIOS, setFoodIOS ] = useState('');
  const [ driverAndroid, setDriverAndroid ] = useState('');
  const [ driverIOS, setDriverIOS ] = useState('');
  const [ managerAndroid, setManagerAndroid ] = useState('');
  const [ managerIOS, setManagerIOS ] = useState('');
  const [ pickupEnabled, setPickupEnabled ] = useState(true);
  const [ homeSections, setHomeSections ] = useState(false);
  const [ cartFloatButton, setCartFloatButton ] = useState(true);
  const [ driverRealtime, setDriverRealtime ] = useState(true);
  const [ noDriverHandleAdmin, setNoDriverHandleAdmin ] = useState(false);
  const [ sendNotifsToAll, setSendNotifsToAll ] = useState(false);
  const [ sendManagerNotifs, setSendManagerNotifs ] = useState(false);
  const [ showSmsHashButton, setShowSmsHashButton ] = useState(false);
  const [ supplementsAutoComplete, setSupplementsAutoComplete ] = useState([]);
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(false);
  const [ showAddDialog, setShowAddDialog ] = useState(false);
  //Methods
  const save = () => {
    if (!preparationTime || !timePerKm) enqueueSnackbar(t('common.inputError'), { variant: 'error' });
    else {
      const configs = {
        tripDuration: { preparationTime: parseInt(preparationTime), timePerKm: parseInt(timePerKm) },
        supportNumbers,
        storeUrls: {
            food: { android: foodAndroid, ios: foodIOS },
            driver: { android: driverAndroid, ios: driverIOS },
            manager: { android: managerAndroid, ios: managerIOS }
        },
        pickupEnabled,
        homeSections,
        cartFloatButton,
        driverRealtime,
        noDriverHandleAdmin,
        sendNotifsToAll,
        sendManagerNotifs,
        showSmsHashButton,
        supplementsAutoComplete
      };
      showLoading(true);
      callFunction({ funcName: 'updateConfigs', params: { configs } })
      .then(() => enqueueSnackbar(t('configs.saveSuccess'), { variant: 'success' }))
      .catch(() => enqueueSnackbar(t('configs.saveError'), { variant: 'error' }))
      .finally(() => showLoading(false));
    }
  }

  const addSupportNumber = () => {
    const number = document.getElementById('phoneNumber').value;
    if (!number) enqueueSnackbar(t('common.inputError'), { variant: 'error' });
    else {
      setSupportNumbers([ ...supportNumbers, number ]);
      setShowAddDialog(false);
    }
  }

  const deleteSupportNumber = (number) => {
    const index = supportNumbers.indexOf(number);
    if (index > -1) setSupportNumbers(supportNumbers.filter((_, i) => i !== index));
  }

  const fetch = () => {
    setLoading(true);
    setError(false);
    getConfig()
    .then((configs) => {
      const tripDuration = configs.get('tripDuration');
      const supportNumbers = configs.get('supportNumbers');
      const storeUrls = configs.get('storeUrls');
      const pickupEnabled = configs.get('pickupEnabled');
      const homeSections = configs.get('homeSections');
      const cartFloatButton = configs.get('cartFloatButton');
      const driverRealtime = configs.get('driverRealtime');
      const noDriverHandleAdmin = configs.get('noDriverHandleAdmin');
      const sendNotifsToAll = configs.get('sendNotifsToAll');
      const sendManagerNotifs = configs.get('sendManagerNotifs');
      const showSmsHashButton = configs.get('showSmsHashButton');
      const supplementsAutoComplete = configs.get('supplementsAutoComplete');
      setPreparationTime(tripDuration.preparationTime);
      setTimePerKm(tripDuration.timePerKm);
      setSupportNumbers(supportNumbers);
      setFoodAndroid(storeUrls.food.android);
      setFoodIOS(storeUrls.food.ios);
      setDriverAndroid(storeUrls.driver.android);
      setDriverIOS(storeUrls.driver.ios);
      setManagerAndroid(storeUrls.manager.android);
      setManagerIOS(storeUrls.manager.ios);
      setPickupEnabled(pickupEnabled);
      setHomeSections(homeSections);
      setCartFloatButton(cartFloatButton);
      setDriverRealtime(driverRealtime);
      setNoDriverHandleAdmin(noDriverHandleAdmin);
      setSendNotifsToAll(sendNotifsToAll);
      setSendManagerNotifs(sendManagerNotifs);
      setShowSmsHashButton(showSmsHashButton);
      setSupplementsAutoComplete(supplementsAutoComplete);
    })
    .catch(() => setError(true))
    .finally(() => setLoading(false));
  }
  //Effect for fetching data
  useEffect(() => {
    fetch();
  }, []);
  //Render page
  return <Paper elevation={1} style={{ maxWidth: 600, margin: 'auto' }}>
    <Box display="flex" flexDirection="column" alignItems="center">
      {/*----Title----*/}
      <Box mt={theme.spacing(0.2)}/>
      <Typography variant="h6"><b>{t('menu.configs')}</b></Typography>
      <Box mt={theme.spacing(0.6)}/>
      {/*----Loading----*/}
      {loading && <>
        <CircularProgress />
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Error----*/}
      {!loading && error && <>
        <Error color="error" style={{ width: theme.spacing(12), height: theme.spacing(12) }}/>
        <Typography color="textSecondary">{t('common.problemMsg')}</Typography>
        <Box mt={theme.spacing(0.2)}/>
        <Button variant="contained" color="primary" style={{ paddingInline: theme.spacing(3), height: 35 }} onClick={fetch}>
          {t('common.tryAgain')}
        </Button>
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Success----*/}
      {!loading && !error && <>
        {/*----Trip duration----*/}
        <Typography style={{ width: '70%' }}><b>{t('configs.tripDuration')}</b></Typography>
        <Box mt={theme.spacing(0.1)}/>
        <Box display="flex" alignItems="center" justifyContent="space-between" width='65%'>
          <Typography>{t('configs.preparationTime')}</Typography>
          <OutlinedInput value={preparationTime} onChange={(e) => setPreparationTime(e.target.value)} type="number" style={{ width: 80, height: 30 }}/>
        </Box>
        <Box mt={theme.spacing(0.1)}/>
        <Box display="flex" alignItems="center" justifyContent="space-between" width='65%'>
          <Typography>{t('configs.timePerKm')}</Typography>
          <OutlinedInput value={timePerKm} onChange={(e) => setTimePerKm(e.target.value)} type="number" style={{ width: 80, height: 30 }}/>
        </Box>
        {/*----Support numbers----*/}
        <Box mt={theme.spacing(0.3)}/>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="70%">
          <Typography><b>{t('configs.supportNumbers')}</b></Typography>
          <IconButton size="small" onClick={() => setShowAddDialog(true)}><Add color="primary"/></IconButton>
        </Box>
        <Box mt={theme.spacing(0.1)}/>
        {supportNumbers.map((number, i) => <Box key={i} display="flex" alignItems="center" justifyContent="space-between" width='65%'>
          <Typography>{number}</Typography>
          <IconButton size="small" onClick={() => deleteSupportNumber(number)}><Delete color="error"/></IconButton>
        </Box>)}
        {supportNumbers.length === 0 && <Typography color="textSecondary">{t('configs.noSupportNumbersMsg')}</Typography>}
        {/*----Store Urls----*/}
        <Box mt={theme.spacing(0.3)}/>
        <Typography style={{ width: '70%' }}><b>{t('configs.storeUrls')}</b></Typography>
        {/*----Food app----*/}
        <Box mt={theme.spacing(0.1)}/>
        <Typography style={{ width: '65%' }}>{t('common.food')}</Typography>
        <Box mt={theme.spacing(0.1)}/>
        <OutlinedInput placeholder="Android" value={foodAndroid} onChange={(e) => setFoodAndroid(e.target.value)} style={{ width: '60%', height: 35 }}/>
        <Box mt={theme.spacing(0.1)}/>
        <OutlinedInput placeholder="iOS" value={foodIOS} onChange={(e) => setFoodIOS(e.target.value)} style={{ width: '60%', height: 35 }}/>
        {/*----Driver app----*/}
        <Box mt={theme.spacing(0.2)}/>
        <Typography style={{ width: '65%' }}>{t('common.driver')}</Typography>
        <Box mt={theme.spacing(0.1)}/>
        <OutlinedInput placeholder="Android" value={driverAndroid} onChange={(e) => setDriverAndroid(e.target.value)} style={{ width: '60%', height: 35 }}/>
        <Box mt={theme.spacing(0.1)}/>
        <OutlinedInput placeholder="iOS" value={driverIOS} onChange={(e) => setDriverIOS(e.target.value)} style={{ width: '60%', height: 35 }}/>
        {/*----Manager app----*/}
        <Box mt={theme.spacing(0.2)}/>
        <Typography style={{ width: '65%' }}>{t('common.manager')}</Typography>
        <Box mt={theme.spacing(0.1)}/>
        <OutlinedInput placeholder="Android" value={managerAndroid} onChange={(e) => setManagerAndroid(e.target.value)} style={{ width: '60%', height: 35 }}/>
        <Box mt={theme.spacing(0.1)}/>
        <OutlinedInput placeholder="iOS" value={managerIOS} onChange={(e) => setManagerIOS(e.target.value)} style={{ width: '60%', height: 35 }}/>
        {/*----Other----*/}
        <Box mt={theme.spacing(0.3)}/>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="70%">
          <Typography><b>{t('configs.pickupEnabled')}</b></Typography>
          <Switch checked={pickupEnabled} onChange={(e) => setPickupEnabled(e.target.checked)} color="primary"/>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="70%">
          <Typography><b>{t('configs.homeSections')}</b></Typography>
          <Switch checked={homeSections} onChange={(e) => setHomeSections(e.target.checked)} color="primary"/>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="70%">
          <Typography><b>{t('configs.cartFloatButton')}</b></Typography>
          <Switch checked={cartFloatButton} onChange={(e) => setCartFloatButton(e.target.checked)} color="primary"/>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="70%">
          <Typography><b>{t('configs.driverRealtime')}</b></Typography>
          <Switch checked={driverRealtime} onChange={(e) => setDriverRealtime(e.target.checked)} color="primary"/>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="70%">
          <Typography><b>{t('configs.noDriverHandleAdmin')}</b></Typography>
          <Switch checked={noDriverHandleAdmin} onChange={(e) => setNoDriverHandleAdmin(e.target.checked)} color="primary"/>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="70%">
          <Typography><b>{t('configs.sendNotifsToAll')}</b></Typography>
          <Switch checked={sendNotifsToAll} onChange={(e) => setSendNotifsToAll(e.target.checked)} color="primary"/>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="70%">
          <Typography><b>{t('configs.sendManagerNotifs')}</b></Typography>
          <Switch checked={sendManagerNotifs} onChange={(e) => setSendManagerNotifs(e.target.checked)} color="primary"/>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="70%">
          <Typography><b>{t('configs.showSmsHashButton')}</b></Typography>
          <Switch checked={showSmsHashButton} onChange={(e) => setShowSmsHashButton(e.target.checked)} color="primary"/>
        </Box>
        <Box mt={theme.spacing(0.1)}/>
        <Typography style={{ width: '70%' }}><b>{t('configs.supplementsAutoComplete')}</b></Typography>
        <Box mt={theme.spacing(0.1)}/>
        <OutlinedInput style={{ width: '70%', height: 35 }} value={supplementsAutoComplete.join(',')} onChange={(e) => {
          let newArr = [];
          if (e.target.value) newArr = e.target.value.split(',');
          setSupplementsAutoComplete(newArr);
        }}/>
        {/*----Save button----*/}
        <Box mt={theme.spacing(0.4)}/>
        <Button variant="contained" color="primary" onClick={save} style={{ paddingInline: theme.spacing(6) }}>{t('configs.save')}</Button>
        <Box mt={theme.spacing(0.4)}/>
      </>}
      {/*----Add support number dialog----*/}
      <Dialog open={showAddDialog} onClose={() => setShowAddDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('configs.addSupportNumber')}</DialogTitle>
        <DialogContent>
          <OutlinedInput autoFocus id="phoneNumber" placeholder={t('configs.phoneNumber')} fullWidth type="phone"/>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAddDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={addSupportNumber} color="primary">{t('common.add')}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  </Paper>;
}

export default Configs;
