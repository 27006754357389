import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme, Paper, Box, Typography, CircularProgress, Button, IconButton, Checkbox, Toolbar, Tooltip, TableContainer, Table,
  TableHead, TableBody, TableCell, TableRow, TablePagination, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText,
  OutlinedInput, Select, MenuItem, InputAdornment } from '@material-ui/core';
import { Error, Add, Edit, Delete, Search, GetApp } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { get, post, put, del, getPolygon } from '../../api';
import { useLoading, Map, Marker, Polygon, getPolygonCenter } from '../../utils';
import { getDir, getRTL } from '../../localization';
import { collections, currencies, fetchCountOptions, fetchCount } from '../../configs';

const Regions = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const showLoading = useLoading();
  //Used states
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(false);
  const [ items, setItems ] = useState([]);
  const [ count, setCount ] = useState(0);
  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(fetchCount);
  const [ selected, setSelected ] = useState([]);
  const [ filter, setFilter ] = useState('name');
  const [ searchText, setSearchText ] = useState('');
  const [ showDeleteDialog, setShowDeleteDialog ] = useState(false);
  const [ showActionDialog, setShowActionDialog ] = useState(false);
  const [ action, setAction ] = useState(null);
  const [ id, setId ] = useState('');
  const [ name, setName ] = useState('');
  const [ currency, setCurrency ] = useState(currencies[0]);
  const [ service, setService ] = useState('');
  const [ servicePickup, setServicePickup ] = useState('');
  const [ initial, setInitial ] = useState('');
  const [ minKms, setMinKms ] = useState('');
  const [ initialExtra, setInitialExtra ] = useState('');
  const [ minKmsExtra, setMinKmsExtra ] = useState('');
  const [ perExtraKm, setPerExtraKm ] = useState('');
  const [ geofence, setGeofence ] = useState([]);
  const [ zoom, setZoom ] = useState(7);
  const [ center, setCenter ] = useState({ lat: 0, lng: 0 });
  //Used attributes
  const mapRef = useRef(null);
  const empty = count === 0;
  const dir = getDir();
  const align = !getRTL() ? 'left' : 'right';
  const headers = [
    { id: 'id', label: t('regions.id'), width: '25%', align, disablePadding: true },
    { id: 'name', label: t('regions.name'), width: '25%', align, disablePadding: false },
    { id: 'currency', label: t('regions.currency'), width: '25%', align, disablePadding: false },
    { id: 'dateCreated', label: t('regions.dateCreated'), width: '25%', align, disablePadding: false }
  ];
  const filters = [
    { value: 'objectId', label: t('regions.id') },
    { value: 'name', label: t('regions.name') }
  ];
  //Methods
  const exportItems = () => {
    const header = headers.map((el) => el.label).join(',');
    const selectedItems = items.filter((item) => selected.includes(item.id))
    .map((item) => item.id + ',' + item.name + ',' + t('currency.' + item.currency) + ',' + item.dateCreated);
    const data = header + '\n' + selectedItems.join('\n');
    var encodedUri = encodeURI(data);
    var link = document.createElement("a");
    link.setAttribute("href", "data:text/csv;charset=utf-8," + encodedUri);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
  }

  const add = () => {
    setAction('add');
    setShowActionDialog(true);
    setId('');
    setName('');
    setCurrency(currencies[0]);
    setService('');
    setServicePickup('');
    setInitial('');
    setMinKms('');
    setInitialExtra('');
    setMinKmsExtra('');
    setPerExtraKm('');
    setGeofence([]);
    setZoom(7);
    setCenter({ lat: 0, lng: 0 });
  }

  const edit = () => {
    if (selected.length > 1) {
      enqueueSnackbar(t('regions.oneOnly'), { variant: 'error' });
      return;
    }
    setAction('edit');
    setShowActionDialog(true);
    const item = items.find((el) => el.id === selected[0]);
    setId(item.id);
    setName(item.name);
    setCurrency(item.currency);
    setService(item.fees.food.service);
    setServicePickup(item.fees.food.servicePickup);
    setInitial(item.fees.food.initial);
    setMinKms(item.fees.food.minKms);
    setInitialExtra(item.fees.food.initialExtra || '');
    setMinKmsExtra(item.fees.food.minKmsExtra || '');
    setPerExtraKm(item.fees.food.perExtraKm);
    setGeofence(item.geofence);
    setZoom(item.zoom);
    const newCenter = getPolygonCenter({ polygonArr: item.geofence });
    setCenter({ lat: newCenter[0], lng: newCenter[1] });
  }

  const remove = () => {
    setShowDeleteDialog(true);
  }

  const itemAction = () => {
    if (!name || !service || !servicePickup || !initial || !minKms || !perExtraKm) {
      enqueueSnackbar(t('common.inputError'), { variant: 'error' });
      return;
    } else if (geofence.length < 3) {
      enqueueSnackbar(t('regions.geofenceError'), { variant: 'error' });
      return;
    }
    const params = { name, currency, zoom, geofence: getPolygon({ polygonArr: geofence }), fees: { food: {
      service: parseInt(service), servicePickup: parseInt(servicePickup), perExtraKm: parseInt(perExtraKm),
      initial: parseInt(initial), minKms: parseFloat(parseFloat(minKms).toFixed(1)),
      initialExtra: initialExtra ? parseInt(initialExtra) : null,
      minKmsExtra: minKmsExtra ? parseFloat(parseFloat(minKmsExtra).toFixed(1)) : null
    }}};
    if (action === 'add') {
      showLoading(true);
      post({ collection: collections.City, noACL: true, params })
      .then(() => {
        enqueueSnackbar(t('regions.addSuccess'), { variant: 'success' });
        setShowActionDialog(false);
        const newPage = 0;
        setPage(newPage);
        fetch({ newPage });
      })
      .catch(() => enqueueSnackbar(t('regions.addError'), { variant: 'error' }))
      .finally(() => showLoading(false));
    } else if (action === 'edit') {
      showLoading(true);
      put({ collection: collections.City, queryParams: [{ equalTo: { key: 'objectId', value: id } }], params })
      .then(() => {
        enqueueSnackbar(t('regions.editSuccess'), { variant: 'success' });
        setShowActionDialog(false);
        fetch();
      })
      .catch(() => enqueueSnackbar(t('regions.editError'), { variant: 'error' }))
      .finally(() => showLoading(false));
    }
  }

  const itemDelete = async () => {
    try {
      showLoading(true);
      for (const id of selected) {
        await del({ collection: collections.City, queryParams: [{ equalTo: { key: 'objectId', value: id } }] });
      }
      enqueueSnackbar(t('regions.deleteSuccess'), { variant: 'success' });
      setShowDeleteDialog(false);
      setSelected([]);
      fetch();
    } catch {
      enqueueSnackbar(t('regions.deleteError'), { variant: 'error' });
    } finally {
      showLoading(false);
    }
  }

  const search = () => {
    const newPage = 0;
    setPage(newPage);
    fetch({ newPage });
  }

  const selectAll = (e) => {
    if (e.target.checked) setSelected(items.map((el) => el.id));
    else setSelected([]);
  }

  const selectItem = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) newSelected = newSelected.concat(selected, id);
    else if (selectedIndex === 0) newSelected = newSelected.concat(selected.slice(1));
    else if (selectedIndex === selected.length - 1) newSelected = newSelected.concat(selected.slice(0, -1));
    else if (selectedIndex > 0) newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    setSelected(newSelected);
  }

  const changePage = (e, newPage) => {
    setPage(newPage);
    fetch({ newPage });
  }

  const changeRowsPerPage = (e) => {
    const newPage = 0;
    const newRowsPerPage = parseInt(e.target.value);
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
    fetch({ newPage, newRowsPerPage });
  }

  const fetch = ({ newPage, newRowsPerPage } = {}) => {
    const usedPage = newPage !== undefined ? newPage : page;
    const usedRowsPerPage = newRowsPerPage !== undefined ? newRowsPerPage : rowsPerPage;
    setLoading(true);
    setError(false);
    get({ collection: collections.City, withCount: true, queryParams: [
      { descending: 'createdAt' },
      { limit: usedRowsPerPage },
      { skip: usedPage * usedRowsPerPage },
      searchText ? { [filter === 'name' ? 'fullText' : 'startsWith']: { key: filter, value: searchText } } : {}
    ]})
    .then((data) => {
      setCount(data.count);
      setItems(data.results.map((item) => { return {
        id: item.objectId, name: item.name, currency: item.currency, fees: item.fees, geofence: item.geofence.coordinates,
        zoom: item.zoom, dateCreated: (new Date(item.createdAt)).toLocaleDateString()
      }}));
    })
    .catch(() => setError(true))
    .finally(() => setLoading(false));
  }
  //Effect for fetching data
  useEffect(fetch, []);
  //Render page
  return <Paper elevation={1}>
    <Box display="flex" flexDirection="column" alignItems="center" paddingX={theme.spacing(0.5)}>
      {/*----Title----*/}
      <Box mt={theme.spacing(0.2)}/>
      <Typography variant="h6"><b>{t('menu.regions')}</b></Typography>
      <Box mt={theme.spacing(0.2)}/>
      {/*----Content----*/}
      <Toolbar style={{ width: '100%', overflowX: 'auto', overflowY: 'hidden',
        backgroundColor: selected.length > 0 ? theme.palette.primary.main + '20' : 'transparent' }}>
        {selected.length > 0 ? <>
          <Typography style={{ flex: 1 }} color="inherit" variant="subtitle1">{selected.length + ' ' + t('common.selected')}</Typography>
          <Tooltip title={t('common.export')}><IconButton onClick={exportItems}><GetApp color="primary"/></IconButton></Tooltip>
          <Tooltip title={t('common.delete')}><IconButton onClick={remove}><Delete color="error"/></IconButton></Tooltip>
          <Tooltip title={t('common.edit')}><IconButton onClick={edit}><Edit color="action"/></IconButton></Tooltip>
        </> : <>
          <OutlinedInput placeholder={t('common.search')} style={{ minWidth: 200, height: 38 }}
            value={searchText} onChange={(e) => setSearchText(e.target.value)} onKeyUp={(e) => { if (e.key === 'Enter') search() }}
            endAdornment={<InputAdornment position="end">
              <IconButton size="small" onClick={search}><Search color="primary"/></IconButton>
            </InputAdornment>}
          />
          <Box mr={theme.spacing(0.2)}/>
          <Select variant="outlined" style={{ height: 38 }} MenuProps={{ elevation: 2, dir }} value={filter} onChange={(e) => setFilter(e.target.value)}>
            {filters.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
          </Select>
          <Box flex={1}/>
          <Tooltip title={t('common.add')}><IconButton onClick={add}><Add color="primary"/></IconButton></Tooltip>
        </>}
      </Toolbar>
      <TableContainer>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox color="primary" indeterminate={selected.length > 0 && selected.length < items.length}
                  checked={items.length > 0 && selected.length === items.length} onChange={selectAll}/>
              </TableCell>
              {headers.map((item) => (
                <TableCell key={item.id} width={item.width} align={item.align} padding={item.disablePadding ? 'none' : 'default'}>
                  <b>{item.label}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/*----Success----*/}
            {!loading && !error && !empty && items.map((item) => {
              const isItemSelected = selected.indexOf(item.id) !== -1;
              return <TableRow hover key={item.id} selected={isItemSelected} onClick={(e) => selectItem(item.id)}>
                <TableCell padding="checkbox"><Checkbox color="primary" checked={isItemSelected}/></TableCell>
                <TableCell align={align} padding="none">{item.id}</TableCell>
                <TableCell align={align}>{item.name}</TableCell>
                <TableCell align={align}>{t('currency.' + item.currency)}</TableCell>
                <TableCell align={align}>{item.dateCreated}</TableCell>
              </TableRow>;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/*----Delete confirmation dialog----*/}
      <Dialog dir={dir} open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('regions.dialog_delete')}</DialogTitle>
        <DialogContent><DialogContentText>{t('common.confirmMsg')}</DialogContentText></DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={itemDelete} color="primary">{t('common.delete')}</Button>
        </DialogActions>
      </Dialog>
      {/*----Add/Edit dialog----*/}
      <Dialog dir={dir} open={showActionDialog} onClose={() => setShowActionDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('regions.dialog_' + action)}</DialogTitle>
        <DialogContent>
          <OutlinedInput placeholder={t('regions.name')} autoFocus fullWidth type="text" value={name} onChange={(e) => setName(e.target.value)}/>
          <Box mt={theme.spacing(0.15)}/>
          <Box display="flex" alignItems="center">
            <Typography><b>{t('regions.currency')}</b></Typography>
            <Box mr={theme.spacing(0.4)}/>
            <Select variant="outlined" MenuProps={{ elevation: 2 }} value={currency} onChange={(e) => setCurrency(e.target.value)}>
              {currencies.map((item) => <MenuItem key={item} value={item}>{t('currency.' + item)}</MenuItem>)}
            </Select>
          </Box>
          <Box mt={theme.spacing(0.15)}/>
          <Typography><b>{t('regions.fees')}</b></Typography>
          <Box paddingX={theme.spacing(0.25)}>
            <Box mt={theme.spacing(0.15)}/>
            <Typography>{t('common.food')}</Typography>
            <Box mt={theme.spacing(0.15)}/>
            <OutlinedInput placeholder={t('regions.serviceFee')} fullWidth type="number" value={service} onChange={(e) => setService(e.target.value)}/>
            <Box mt={theme.spacing(0.15)}/>
            <OutlinedInput placeholder={t('regions.serviceFeePickup')} fullWidth type="number" value={servicePickup} onChange={(e) => setServicePickup(e.target.value)}/>
            <Box mt={theme.spacing(0.15)}/>
            <OutlinedInput placeholder={t('regions.minCost')} fullWidth type="number" value={initial} onChange={(e) => setInitial(e.target.value)}/>
            <Box mt={theme.spacing(0.15)}/>
            <OutlinedInput placeholder={t('regions.minKms')} fullWidth type="number" value={minKms} onChange={(e) => setMinKms(e.target.value)}/>
            <Box mt={theme.spacing(0.15)}/>
            <OutlinedInput placeholder={t('regions.minCostExtra')} fullWidth type="number" value={initialExtra} onChange={(e) => setInitialExtra(e.target.value)}/>
            <Box mt={theme.spacing(0.15)}/>
            <OutlinedInput placeholder={t('regions.minKmsExtra')} fullWidth type="number" value={minKmsExtra} onChange={(e) => setMinKmsExtra(e.target.value)}/>
            <Box mt={theme.spacing(0.15)}/>
            <OutlinedInput placeholder={t('regions.costPerKm')} fullWidth type="number" value={perExtraKm} onChange={(e) => setPerExtraKm(e.target.value)}/>
            <Box mt={theme.spacing(0.15)}/>
          </Box>
          <Typography><b>{t('regions.geofence')}</b></Typography>
          <Box mt={theme.spacing(0.15)}/>
          <Box paddingX={theme.spacing(0.25)} width="100%">
            <Map height={400} mapRef={mapRef} zoom={zoom} center={center} onZoomChanged={() => setZoom(mapRef.current.getZoom())}
              onCenterChanged={() => setCenter(mapRef.current.getCenter())}
              onClick={(e) => setGeofence([...geofence, [e.latLng.lat(), e.latLng.lng()]])}>
              {geofence.map((el, i) => <Marker key={i} position={{ lat: el[0], lng: el[1] }}
                onClick={() => setGeofence(geofence.filter((_, j) => j !== i))}/>)}
              <Polygon path={geofence.map((el) => { return { lat: el[0], lng: el[1] } })}/>
            </Map>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowActionDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={itemAction} color="primary">{t('common.' + action)}</Button>
        </DialogActions>
      </Dialog>
      {/*----Loading----*/}
      {loading && <>
        <Box mt={theme.spacing(0.8)}/>
        <CircularProgress />
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Error----*/}
      {!loading && error && <>
        <Box mt={theme.spacing(0.6)}/>
        <Error color="error" style={{ width: theme.spacing(12), height: theme.spacing(12) }}/>
        <Typography color="textSecondary">{t('common.problemMsg')}</Typography>
        <Box mt={theme.spacing(0.2)}/>
        <Button variant="contained" color="primary" style={{ paddingInline: theme.spacing(3), height: 35 }} onClick={fetch}>
          {t('common.tryAgain')}
        </Button>
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Empty----*/}
      {!loading && !error && empty && <>
        <Box mt={theme.spacing(0.4)}/>
        <Typography color="textSecondary">{t('regions.emptyMsg')}</Typography>
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Success----*/}
      {!loading && !error && !empty && <>
        <TablePagination rowsPerPageOptions={fetchCountOptions} rowsPerPage={rowsPerPage} page={page} count={count}
          onChangePage={changePage} onChangeRowsPerPage={changeRowsPerPage} labelRowsPerPage={t('common.rowsPerPage')}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('common.of')} ${count}`}
          SelectProps={{ MenuProps: { elevation: 2 } }} component="div" style={{ alignSelf: 'flex-end' }}/>
        <Box mt={theme.spacing(0.4)}/>
      </>}
    </Box>
  </Paper>;
}

export default Regions;
