import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme, Paper, Box, Typography, CircularProgress, Button, IconButton, Checkbox, Toolbar, Tooltip, TableContainer, Table,
  TableHead, TableBody, TableCell, TableRow, TablePagination, OutlinedInput, InputAdornment, Select, MenuItem, Dialog, DialogContent,
  DialogTitle, DialogActions, DialogContentText, Avatar, FormControlLabel } from '@material-ui/core';
import { Error, Add, Edit, Delete, Search, Block, Map as MapIcon, Check, GetApp } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { get, callFunction } from '../../api';
import { useLoading, Map, Marker } from '../../utils';
import { getDir, getRTL } from '../../localization';
import { fetchCountOptions, fetchCount, appTypes as nativeAppTypes, staffTypes, collections } from '../../configs';
import * as EmailValidator from 'email-validator';

const Users = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const showLoading = useLoading();
  const { user } = useSelector(state => state);
  const appTypes = [...nativeAppTypes];
  if (user.staffType !== staffTypes.admin) appTypes.splice(appTypes.indexOf('staff'), 1);
  //Used states
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(false);
  const [ items, setItems ] = useState([]);
  const [ count, setCount ] = useState(0);
  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(fetchCount);
  const [ selected, setSelected ] = useState([]);
  const [ filter, setFilter ] = useState('objectId');
  const [ appTypeFilter, setAppTypeFilter ] = useState('/');
  const [ cityFilter, setCityFilter ] = useState('/');
  const [ disabledFilter, setDisabledFilter ] = useState(false);
  const [ activeFilter, setActiveFilter ] = useState(false);
  const [ searchText, setSearchText ] = useState('');
  const [ showDeleteDialog, setShowDeleteDialog ] = useState(false);
  const [ showEnableToggleDialog, setShowEnableToggleDialog ] = useState(false);
  const [ showLocationMapDialog, setShowLocationMapDialog ] = useState(false);
  const [ showActionDialog, setShowActionDialog ] = useState(false);
  const [ action, setAction ] = useState(null);
  const [ cities, setCities ] = useState([]);
  const [ id, setId ] = useState('');
  const [ fullname, setFullname ] = useState('');
  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ phone, setPhone ] = useState('');
  const [ city, setCity ] = useState('');
  const [ enabled, setEnabled ] = useState(true);
  const [ appType, setAppType ] = useState([]);
  const [ staffType, setStaffType ] = useState(staffTypes.staff);
  const [ zoom, setZoom ] = useState(7);
  const [ center, setCenter ] = useState({ lat: 0, lng: 0 });
  const [ mapName, setMapName ] = useState('');
  //Used attributes
  const mapRef = useRef(null);
  const empty = count === 0;
  const dir = getDir();
  const align = !getRTL() ? 'left' : 'right';
  const headers = [
    { id: 'id', label: t('users.id'), width: '10%', align, disablePadding: true },
    { id: 'picture', label: t('users.picture'), width: '10%', align, disablePadding: false },
    { id: 'fullname', label: t('users.fullname'), width: '10%', align, disablePadding: false },
    { id: 'username', label: t('users.username'), width: '10%', align, disablePadding: false },
    { id: 'email', label: t('users.email'), width: '10%', align, disablePadding: false },
    { id: 'phone', label: t('users.phone'), width: '10%', align, disablePadding: false },
    { id: 'status', label: t('users.status'), width: '10%', align, disablePadding: false },
    { id: 'data', label: t('users.data'), width: '15%', align, disablePadding: false },
    { id: 'dateCreated', label: t('users.dateCreated'), width: '15%', align, disablePadding: false }
  ];
  const filters = [
    { value: 'objectId', label: t('users.id') },
    { value: 'fullname', label: t('users.fullname') },
    { value: 'username', label: t('users.username') },
    { value: 'email', label: t('users.email') },
    { value: 'phone', label: t('users.phone') }
  ];
  //Methods
  const exportItems = () => {
    const header = headers.map((el) => el.label).join(',');
    const selectedItems = items.filter((item) => selected.includes(item.id))
    .map((item) => item.id + ',/,' + item.fullname + ',' + item.username + ',' + item.email + ',' + item.phone +
      ',' + (item.enabled ? t('users.enabled') : t('users.disabled')) + ',/,' + item.dateCreated);
    const data = header + '\n' + selectedItems.join('\n');
    var encodedUri = encodeURI(data);
    var link = document.createElement("a");
    link.setAttribute("href", "data:text/csv;charset=utf-8," + encodedUri);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
  }

  const add = () => {
    setAction('add');
    setShowActionDialog(true);
    setId('');
    setFullname('');
    setUsername('');
    setPassword('');
    setEmail('');
    setPhone('');
    setCity(cities.length > 0 ? cities[0].objectId : '');
    setEnabled(true);
    setAppType([]);
    setStaffType(staffTypes.staff);
  }

  const edit = () => {
    if (selected.length > 1) {
      enqueueSnackbar(t('users.oneOnly'), { variant: 'error' });
      return;
    }
    setAction('edit');
    setShowActionDialog(true);
    const item = items.find((el) => el.id === selected[0]);
    setId(item.id);
    setFullname(item.fullname);
    setUsername(item.username);
    setPassword('');
    setEmail(item.email);
    setPhone(item.phone);
    setCity(item.data.city ? item.data.city.id : '');
    setEnabled(item.enabled);
    setAppType(item.appType);
    setStaffType(item.data.staffType ? item.data.staffType : staffTypes.staff);
  }
  
  const remove = () => {
    setShowDeleteDialog(true);
  }

  const enableToggle = () => {
    setShowEnableToggleDialog(true);
  }

  const locationMap = () => {
    let position = { lat: 0, lng: 0 };
    const item = items.find((el) => el.id === selected[0]);
    if (appTypeFilter === 'food' && item.data.address && item.data.address.get('location')) {
      position = { lat: item.data.address.get('location').latitude, lng: item.data.address.get('location').longitude };
    } else if (appTypeFilter === 'driver' && item.data.driverLocation) {
      position = { lat: item.data.driverLocation.latitude, lng: item.data.driverLocation.longitude };
    } else if (appTypeFilter === 'manager' && item.data.managerStore && item.data.managerStore.get('location')) {
      position = { lat: item.data.managerStore.get('location').latitude, lng: item.data.managerStore.get('location').longitude };
    }
    setCenter(position);
    setMapName('');
    setShowLocationMapDialog(true);
  }

  const itemAction = () => {
    if (!EmailValidator.validate(email)) {
      enqueueSnackbar(t('users.emailError'), { variant: 'error' });
      return;
    } else if (!fullname || !city || (appType.includes('staff') && !staffType) ||
      (action === 'add' && (!username || !password)) || (action === 'edit' && !phone)) {
      enqueueSnackbar(t('common.inputError'), { variant: 'error' });
      return;
    }
    const params = { fullname, email, appType, cityId: city };
    if (password) params.password = password;
    if (phone) params.phone = phone;
    if (appType.includes('staff')) params.staffType = staffType;
    if (action === 'add') {
      params.username = username;
      params.enabled = enabled;
      showLoading(true);
      callFunction({ funcName: 'addUser', params })
      .then(() => {
        enqueueSnackbar(t('users.addSuccess'), { variant: 'success' });
        setShowActionDialog(false);
        const newPage = 0;
        setPage(newPage);
        fetch({ newPage });
      })
      .catch(() => enqueueSnackbar(t('users.addError'), { variant: 'error' }))
      .finally(() => showLoading(false));
    } else if (action === 'edit') {
      params.id = id;
      showLoading(true);
      callFunction({ funcName: 'editUser', params })
      .then(() => {
        enqueueSnackbar(t('users.editSuccess'), { variant: 'success' });
        setShowActionDialog(false);
        fetch();
      })
      .catch(() => enqueueSnackbar(t('users.editError'), { variant: 'error' }))
      .finally(() => showLoading(false));
    }
  }

  const itemDelete = () => {
    showLoading(true);
    callFunction({ funcName: 'deleteUsers', params: { ids: selected } })
    .then(() => {
      enqueueSnackbar(t('users.deleteSuccess'), { variant: 'success' });
      setShowDeleteDialog(false);
      setSelected([]);
      fetch();
    })
    .catch(() => enqueueSnackbar(t('users.deleteError'), { variant: 'error' }))
    .finally(() => showLoading(false));
  }

  const itemToggleEnable = () => {
    showLoading(true);
    callFunction({ funcName: 'toggleEnableUsers', params: { ids: selected } })
    .then(() => {
      enqueueSnackbar(t('users.enableSuccess'), { variant: 'success' });
      setShowEnableToggleDialog(false);
      fetch();
    })
    .catch(() => enqueueSnackbar(t('users.enableError'), { variant: 'error' }))
    .finally(() => showLoading(false));
  }

  const search = ({ newAppTypeFilter, newCityFilter, newDisabledFilter, newActiveFilter } = {}) => {
    const newPage = 0;
    setPage(newPage);
    if (newAppTypeFilter !== undefined) setAppTypeFilter(newAppTypeFilter);
    if (newCityFilter !== undefined) setCityFilter(newCityFilter);
    if (newDisabledFilter !== undefined) setDisabledFilter(newDisabledFilter);
    if (newActiveFilter !== undefined) setActiveFilter(newActiveFilter);
    fetch({ newPage, newAppTypeFilter, newCityFilter, newDisabledFilter, newActiveFilter });
  }

  const selectAll = (e) => {
    if (e.target.checked) setSelected(items.map((el) => el.id));
    else setSelected([]);
  }

  const selectItem = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) newSelected = newSelected.concat(selected, id);
    else if (selectedIndex === 0) newSelected = newSelected.concat(selected.slice(1));
    else if (selectedIndex === selected.length - 1) newSelected = newSelected.concat(selected.slice(0, -1));
    else if (selectedIndex > 0) newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    setSelected(newSelected);
  }

  const changePage = (e, newPage) => {
    setPage(newPage);
    fetch({ newPage });
  }

  const changeRowsPerPage = (e) => {
    const newPage = 0;
    const newRowsPerPage = parseInt(e.target.value);
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
    fetch({ newPage, newRowsPerPage });
  }

  const fetch = ({ newPage, newRowsPerPage, newAppTypeFilter, newCityFilter, newDisabledFilter, newActiveFilter } = {}) => {
    const usedPage = newPage !== undefined ? newPage : page;
    const usedRowsPerPage = newRowsPerPage !== undefined ? newRowsPerPage : rowsPerPage;
    const usedAppTypeFilter = newAppTypeFilter !== undefined ? newAppTypeFilter : appTypeFilter;
    const usedCityFilter = newCityFilter !== undefined ? newCityFilter : cityFilter;
    const usedDisabledFilter = newDisabledFilter !== undefined ? newDisabledFilter : disabledFilter;
    const usedActiveFilter = newActiveFilter !== undefined ? newActiveFilter : activeFilter;
    const query = {};
    if (searchText) query.search = { key: filter, value: searchText };
    if (filter === 'fullname') query.fullText = true;
    if (usedAppTypeFilter !== '/') query.appType = usedAppTypeFilter;
    if (usedCityFilter !== '/') query.cityId = usedCityFilter;
    if (usedDisabledFilter) query.disabled = true;
    if (usedActiveFilter) query.driverActive = true;
    setLoading(true);
    setError(false);
    callFunction({ funcName: 'getUsers', params: { limit: usedRowsPerPage, skip: usedPage * usedRowsPerPage, ...query } })
    .then((data) => {
      setCount(data.count);
      setItems(data.results.map((item) => {
        const facebookPhoto = item.authData && item.authData.facebook && item.authData.facebook.photo ? item.authData.facebook.photo : undefined;
        const googlePhoto = item.authData && item.authData.google && item.authData.google.photo ? item.authData.google.photo : undefined;
        const usedPicture = item.picture ? item.picture._url : googlePhoto ? googlePhoto : facebookPhoto ? facebookPhoto : undefined;
        return {
          id: item.objectId, fullname: item.fullname, username: item.username, email: item.email, phone: item.phone,
          picture: usedPicture, enabled: item.enabled, appType: item.appType, dateCreated: (new Date(item.createdAt)).toLocaleDateString(),
          data: item
        }
      }));
    })
    .catch(() => setError(true))
    .finally(() => setLoading(false));
  }

  const initFetch = () => {
    //Fetch regions
    get({ collection: collections.City, queryParams: [ { descending: 'createdAt' } ]})
    .then((data) => setCities(data)).catch(() => {});
    //Fetch items
    fetch();
  }
  //Effect for fetching data
  useEffect(initFetch, []);
  //Render page
  return <Paper elevation={1}>
    <Box display="flex" flexDirection="column" alignItems="center" paddingX={theme.spacing(0.5)}>
      {/*----Title----*/}
      <Box mt={theme.spacing(0.2)}/>
      <Typography variant="h6"><b>{t('menu.users')}</b></Typography>
      <Box mt={theme.spacing(0.2)}/>
      {/*----Content----*/}
      <Toolbar style={{ width: '100%', overflowX: 'auto', overflowY: 'hidden',
        backgroundColor: selected.length > 0 ? theme.palette.primary.main + '20' : 'transparent' }}>
        {selected.length > 0 ? <>
          <Typography style={{ flex: 1 }} color="inherit" variant="subtitle1">{selected.length + ' ' + t('common.selected')}</Typography>
          <Tooltip title={t('common.export')}><IconButton onClick={exportItems}><GetApp color="primary"/></IconButton></Tooltip>
          {appTypeFilter !== '/' && appTypeFilter !== 'staff' && (
            <Tooltip title={t('users.locationMap')}><IconButton onClick={locationMap}><MapIcon color="secondary"/></IconButton></Tooltip>
          )}
          <Tooltip title={t('users.enableDisable')}><IconButton onClick={enableToggle}><Block color="action"/></IconButton></Tooltip>
          <Tooltip title={t('common.delete')}><IconButton onClick={remove}><Delete color="error"/></IconButton></Tooltip>
          <Tooltip title={t('common.edit')}><IconButton onClick={edit}><Edit color="action"/></IconButton></Tooltip>
        </> : <>
          <OutlinedInput placeholder={t('common.search')} style={{ minWidth: 200, height: 38 }}
            value={searchText} onChange={(e) => setSearchText(e.target.value)} onKeyUp={(e) => { if (e.key === 'Enter') search() }}
            endAdornment={<InputAdornment position="end">
              <IconButton size="small" onClick={search}><Search color="primary"/></IconButton>
            </InputAdornment>}
          />
          <Box mr={theme.spacing(0.2)}/>
          <Select variant="outlined" style={{ height: 38 }} MenuProps={{ elevation: 2, dir }} value={filter} onChange={(e) => setFilter(e.target.value)}>
            {filters.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
          </Select>
          <Box mr={theme.spacing(0.4)}/>
          <Typography>{t('users.appType')}</Typography>
          <Box mr={theme.spacing(0.2)}/>
          <Select variant="outlined" style={{ height: 38 }} MenuProps={{ elevation: 2 }} value={appTypeFilter}
            onChange={(e) => search({ newAppTypeFilter: e.target.value })}>
            <MenuItem value="/">/</MenuItem>
            {appTypes.map((item) => <MenuItem key={item} value={item}>{t('common.' + item)}</MenuItem>)}
          </Select>
          <Box mr={theme.spacing(0.4)}/>
          <Typography>{t('users.region')}</Typography>
          <Box mr={theme.spacing(0.2)}/>
          <Select variant="outlined" style={{ height: 38 }} MenuProps={{ elevation: 2 }} value={cityFilter}
            onChange={(e) => search({ newCityFilter: e.target.value })}>
            <MenuItem value="/">/</MenuItem>
            {cities.map((item) => <MenuItem key={item.objectId} value={item.objectId}>{item.name}</MenuItem>)}
          </Select>
          <Box mr={theme.spacing(0.4)}/>
          <FormControlLabel
            control={<Checkbox checked={disabledFilter} onChange={(e) => search({ newDisabledFilter: e.target.checked })} color="primary"/>}
            label={t('users.showDisabled')}/>
          {appTypeFilter === 'driver' && <>
            <Box mr={theme.spacing(0.2)}/>
            <FormControlLabel
              control={<Checkbox checked={activeFilter} onChange={(e) => search({ newActiveFilter: e.target.checked })} color="primary"/>}
              label={t('users.showActive')}/>
          </>}
          <Box flex={1}/>
          <Tooltip title={t('common.add')}><IconButton onClick={add}><Add color="primary"/></IconButton></Tooltip>
        </>}
      </Toolbar>
      <TableContainer>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox color="primary" indeterminate={selected.length > 0 && selected.length < items.length}
                  checked={items.length > 0 && selected.length === items.length} onChange={selectAll}/>
              </TableCell>
              {headers.map((item) => (
                <TableCell key={item.id} width={item.width} align={item.align} padding={item.disablePadding ? 'none' : 'default'}>
                  <b>{item.label}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/*----Success----*/}
            {!loading && !error && !empty && items.map((item) => {
              const isItemSelected = selected.indexOf(item.id) !== -1;
              return <TableRow hover key={item.id} selected={isItemSelected} onClick={(e) => selectItem(item.id)}>
                <TableCell padding="checkbox"><Checkbox color="primary" checked={isItemSelected}/></TableCell>
                <TableCell align={align} padding="none">{item.id}</TableCell>
                <TableCell align={align}><Avatar alt={item.fullname} src={item.picture}/></TableCell>
                <TableCell align={align}>{item.fullname}</TableCell>
                <TableCell align={align}>{item.username}</TableCell>
                <TableCell align={align}>{item.email}</TableCell>
                <TableCell align={align}>{item.phone}</TableCell>
                <TableCell align={align}>
                  {item.enabled ? (
                    <Box display="flex" alignItems="center">
                      <Check style={{ color: 'green' }}/>
                      <div>{t('users.enabled')}</div>
                    </Box>
                  ) : (
                    <Box display="flex" alignItems="center">
                      <Block color="error"/>
                      <div>{t('users.disabled')}</div>
                    </Box>
                  )}
                </TableCell>
                <TableCell align={align}>
                  {appTypeFilter === 'food' ? <>
                    <b>{t('users.region') + ': '}</b><>{item.data.city ? item.data.city.get('name') : ''}</><br/>
                    <b>{t('users.cartItems') + ': '}</b><>{item.data.cartFood.length.toString()}</><br/>
                    <b>{t('users.promosUsed') + ': '}</b><>{item.data.promosUsed.length.toString()}</>
                  </> : appTypeFilter === 'driver' ? <>
                    <b>{t('users.region') + ': '}</b><>{item.data.city ? item.data.city.get('name') : ''}</><br/>
                    <b>{t('users.status') + ': '}</b>{item.data.driverActive ? (
                      <div style={{ color: 'green', display: 'inline' }}>{t('users.active')}</div>
                    ) : (
                      <div style={{ color: 'red', display: 'inline' }}>{t('users.inactive')}</div>
                    )}<br/>
                    <b>{t('users.driverRating') + ': '}</b><>{item.data.driverRating.toString()}</><br/>
                    <b>{t('users.driverReviews') + ': '}</b><>{item.data.driverParams.reviews.toString()}</>
                  </> : appTypeFilter === 'manager' && item.data.managerStore ? <>
                    <b>{t('users.region') + ': '}</b><>{item.data.managerStore.get('city') ? item.data.managerStore.get('city').get('name') : ''}</><br/>
                    <b>{t('users.store') + ': '}</b><>{item.data.managerStore.get('name')}</><br/>
                    <b>{t('users.storeRating') + ': '}</b><>{item.data.managerStore.get('rating').toString()}</><br/>
                    <b>{t('users.storeReviews') + ': '}</b><>{item.data.managerStore.get('reviews').toString()}</>
                  </> : appTypeFilter === 'staff' ? <>
                    <b>{t('users.region') + ': '}</b><>{item.data.city ? item.data.city.get('name') : ''}</><br/>
                    <b>{t('users.staffType') + ': '}</b><>{t('users.' + item.data.staffType)}</>
                  </> : '/'}
                </TableCell>
                <TableCell align={align}>{item.dateCreated}</TableCell>
              </TableRow>;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/*----Delete confirmation dialog----*/}
      <Dialog dir={dir} open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('users.dialog_delete')}</DialogTitle>
        <DialogContent><DialogContentText>{t('common.confirmMsg')}</DialogContentText></DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={itemDelete} color="primary">{t('common.delete')}</Button>
        </DialogActions>
      </Dialog>
      {/*----Enable/Disable Toggle confirmation dialog----*/}
      <Dialog dir={dir} open={showEnableToggleDialog} onClose={() => setShowEnableToggleDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('users.dialog_enableDisable')}</DialogTitle>
        <DialogContent><DialogContentText>{t('common.confirmMsg')}</DialogContentText></DialogContent>
        <DialogActions>
          <Button onClick={() => setShowEnableToggleDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={itemToggleEnable} color="primary">{t('common.ok')}</Button>
        </DialogActions>
      </Dialog>
      {/*----Add/Edit dialog----*/}
      <Dialog dir={dir} open={showActionDialog} onClose={() => setShowActionDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('users.dialog_' + action)}</DialogTitle>
        <DialogContent>
          <OutlinedInput placeholder={t('users.fullname')} autoFocus fullWidth type="text" value={fullname} onChange={(e) => setFullname(e.target.value)}/>
          <Box mt={theme.spacing(0.15)}/>
          <OutlinedInput disabled={action === 'edit'} placeholder={t('users.username')} fullWidth type="text" value={username} onChange={(e) => setUsername(e.target.value)}/>
          <Box mt={theme.spacing(0.15)}/>
          <OutlinedInput placeholder={t('users.password')} fullWidth type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
          <Box mt={theme.spacing(0.15)}/>
          <OutlinedInput placeholder={t('users.email')} fullWidth type="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
          <Box mt={theme.spacing(0.15)}/>
          <OutlinedInput placeholder={t('users.phone')} fullWidth type="phone" value={phone} onChange={(e) => setPhone(e.target.value)}/>
          <Box mt={theme.spacing(0.15)}/>
          {action === 'add' && <>
            <Box mt={theme.spacing(0.15)}/>
            <FormControlLabel
              control={<Checkbox color="primary" checked={enabled} onChange={(e) => setEnabled(e.target.checked)}/>}
              label={t('users.enabled')}
            />
          </>}
          <Box display="flex" alignItems="center">
            <Typography>{t('users.appType')}</Typography>
            <Box mr={theme.spacing(0.4)}/>
            <Box display="flex" flexDirection="column" justifyContent="center">
              {appTypes.map((el) => <FormControlLabel label={t('common.' + el)}
                control={<Checkbox color="primary" checked={appType.includes(el)} onChange={(e) => {
                  const newAppType = [...appType];
                  if (e.target.checked) newAppType.push(el);
                  else newAppType.splice(newAppType.indexOf(el), 1);
                  setAppType(newAppType);
                }}/>}
              />)}
            </Box>
          </Box>
          <Box mt={theme.spacing(0.15)}/>
          <Box display="flex" alignItems="center">
            <Typography>{t('users.region')}</Typography>
            <Box mr={theme.spacing(0.4)}/>
            <Select variant="outlined" MenuProps={{ elevation: 2 }} value={city} onChange={(e) => setCity(e.target.value)}>
              {cities.map((item) => <MenuItem key={item.objectId} value={item.objectId}>{item.name}</MenuItem>)}
            </Select>
          </Box>
          {appType.includes('staff') && <>
            <Box mt={theme.spacing(0.15)}/>
            <Box display="flex" alignItems="center">
              <Typography>{t('users.staffType')}</Typography>
              <Box mr={theme.spacing(0.4)}/>
              <Select variant="outlined" MenuProps={{ elevation: 2 }} value={staffType} onChange={(e) => setStaffType(e.target.value)}>
                <MenuItem value={staffTypes.admin}>{t('users.Admin')}</MenuItem>
                <MenuItem value={staffTypes.staff}>{t('users.Staff')}</MenuItem>
              </Select>
            </Box>
          </>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowActionDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={itemAction} color="primary">{t('common.' + action)}</Button>
        </DialogActions>
      </Dialog>
      {/*----Location Map dialog----*/}
      <Dialog dir={dir} open={showLocationMapDialog} onClose={() => setShowLocationMapDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('users.locationMap')}</DialogTitle>
        <DialogContent>
          {mapName && <>
            <Typography style={{ textAlign: 'center' }}>{mapName}</Typography>
            <Box mt={theme.spacing(0.2)}/>
          </>}
          <Map height={500} mapRef={mapRef} zoom={zoom} center={center}
            onZoomChanged={() => setZoom(mapRef.current.getZoom())} onCenterChanged={() => setCenter(mapRef.current.getCenter())}>
            {items.filter((item) => selected.includes(item.id)).map((item, i) => {
              let position = { lat: 0, lng: 0 };
              if (appTypeFilter === 'food' && item.data.address && item.data.address.get('location')) {
                position = { lat: item.data.address.get('location').latitude, lng: item.data.address.get('location').longitude };
              } else if (appTypeFilter === 'driver' && item.data.driverLocation) {
                position = { lat: item.data.driverLocation.latitude, lng: item.data.driverLocation.longitude };
              } else if (appTypeFilter === 'manager' && item.data.managerStore && item.data.managerStore.get('location')) {
                position = { lat: item.data.managerStore.get('location').latitude, lng: item.data.managerStore.get('location').longitude };
              }
              return <Marker key={i} position={position} onClick={() => {
                setMapName(appTypeFilter === 'manager' && item.data.managerStore ? item.data.managerStore.get('name') : item.fullname);
              }}/>
            })}
          </Map>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowLocationMapDialog(false)} color="primary">{t('common.cancel')}</Button>
        </DialogActions>
      </Dialog>
      {/*----Loading----*/}
      {loading && <>
        <Box mt={theme.spacing(0.8)}/>
        <CircularProgress />
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Error----*/}
      {!loading && error && <>
        <Box mt={theme.spacing(0.6)}/>
        <Error color="error" style={{ width: theme.spacing(12), height: theme.spacing(12) }}/>
        <Typography color="textSecondary">{t('common.problemMsg')}</Typography>
        <Box mt={theme.spacing(0.2)}/>
        <Button variant="contained" color="primary" style={{ paddingInline: theme.spacing(3), height: 35 }} onClick={initFetch}>
          {t('common.tryAgain')}
        </Button>
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Empty----*/}
      {!loading && !error && empty && <>
        <Box mt={theme.spacing(0.4)}/>
        <Typography color="textSecondary">{t('users.emptyMsg')}</Typography>
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Success----*/}
      {!loading && !error && !empty && <>
        <TablePagination rowsPerPageOptions={fetchCountOptions} rowsPerPage={rowsPerPage} page={page} count={count}
          onChangePage={changePage} onChangeRowsPerPage={changeRowsPerPage} labelRowsPerPage={t('common.rowsPerPage')}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('common.of')} ${count}`}
          SelectProps={{ MenuProps: { elevation: 2 } }} component="div" style={{ alignSelf: 'flex-end' }}/>
        <Box mt={theme.spacing(0.4)}/>
      </>}
    </Box>
  </Paper>;
}

export default Users;
