import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme, Paper, Box, Typography, CircularProgress, IconButton, MenuItem, Select } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Error, Close, Search } from '@material-ui/icons';
import { get, getPointerFromId } from '../../api';
import { collections } from '../../configs';

const FINISHED_ORDER_STATUS = 2;
const DATA_LIMIT = 10000;

const Statistics = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  //Used states
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ items, setItems ] = useState({});
  const [ cities, setCities ] = useState([]);
  const [ cityFilter, setCityFilter ] = useState('/');
  const [ fromDate, setFromDate ] = useState(null);
  const [ toDate, setToDate ] = useState(null);
  //Used parameters
  const empty = Object.keys(items).length === 0;
  //Methods
  const fetch = async () => {
    const commonQuery = [
      { limit: DATA_LIMIT },
      cityFilter === '/' ? {} : { equalTo: { key: 'city', value: getPointerFromId({ collection: collections.City, objectId: cityFilter }) } },
      fromDate ? { greaterThanOrEqualTo: { key: 'createdAt', value: fromDate } } : {},
      toDate ? { lessThanOrEqualTo: { key: 'createdAt', value: toDate } } : {}
    ];
    const orderQuery = [
      { include: 'restaurant' },
      { include: 'city' },
      { include: 'promo' }
    ];
    setLoading(true);
    setError(false);
    try {
      const users = await get({ collection: collections.User, queryParams: [ ...commonQuery ]});
      const reviews = await get({ collection: collections.Review, queryParams: [ ...commonQuery ]});
      const orders = await get({ collection: collections.Order, queryParams: [ ...commonQuery, ...orderQuery ]});
      setItems({ users, reviews, orders });
    }
    catch { setError(true) }
    finally { setLoading(false) }
  }

  //Effect for fetching data
  useEffect(() => {
    //Fetch regions
    get({ collection: collections.City, queryParams: [ { descending: 'createdAt' } ]})
    .then((data) => setCities(data)).catch(() => {});
  }, []);
  //Render page
  return <Box display="flex" flexDirection="column" alignItems="center">
    {/*----Title----*/}
    <Typography variant="h6"><b>{t('menu.statistics')}</b></Typography>
    <Box mt={theme.spacing(0.4)}/>
    {/*----Filters----*/}
    <Box display="flex" width="100%" alignItems="center" paddingX={theme.spacing(0.5)}>
      <Typography>{t('statistics.region')}</Typography>
      <Box mr={theme.spacing(0.2)}/>
      <Select variant="outlined" style={{ height: 38 }} MenuProps={{ elevation: 2 }} value={cityFilter}
        onChange={(e) => setCityFilter(e.target.value)}>
        <MenuItem value="/">/</MenuItem>
        {cities.map((item) => <MenuItem key={item.objectId} value={item.objectId}>{item.name}</MenuItem>)}
      </Select>
      <Box mr={theme.spacing(0.4)}/>
      <Box display="flex" style={{ marginTop: -25 }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker disableToolbar variant="inline" format="MM/dd/yyyy" margin="normal" label={t('statistics.from')}
            value={fromDate} onChange={(date) => {
              if (date) date.setHours(0, 0, 0, 0);
              setFromDate(date);
            }} PopoverProps={{ PaperProps: { elevation: 2 } }}/>
          {fromDate && <IconButton size="small" style={{ alignSelf: 'center', marginTop: 25 }} onClick={() => setFromDate(null)}><Close color="action"/></IconButton>}
          <Box mr={theme.spacing(0.4)}/>
          <KeyboardDatePicker disableToolbar variant="inline" format="MM/dd/yyyy" margin="normal" label={t('statistics.to')}
            value={toDate} onChange={(date) => {
              if (date) date.setHours(23, 59, 59, 999);
              setToDate(date);
            }} PopoverProps={{ PaperProps: { elevation: 2 } }}/>
          {toDate && <IconButton size="small" style={{ alignSelf: 'center', marginTop: 25 }} onClick={() => setToDate(null)}><Close color="action"/></IconButton>}
        </MuiPickersUtilsProvider>
      </Box>
      <Box flex={1}/>
      <Box mr={theme.spacing(0.2)}/>
      <IconButton onClick={fetch}><Search color="primary"/></IconButton>
    </Box>
    <Box mt={theme.spacing(0.4)}/>
    {/*----Data----*/}
    {(loading || error || !empty) && <Box display="flex" justifyContent="space-between"
      padding={theme.spacing(0.5)} style={{ width: "100%", overflowX: 'auto', overflowY: 'hidden' }}>
      {/*----Users----*/}
      <Paper elevation={1} style={{ width: 200 }}>
        <Box display="flex" flexDirection="column" alignItems="center" padding={theme.spacing(0.5)}>
          {/*----Loading----*/}
          {loading && <CircularProgress />}
          {/*----Error----*/}
          {!loading && error && <Error color="error" style={{ width: theme.spacing(12), height: theme.spacing(12) }}/>}
          {/*----Success----*/}
          {!loading && !error && items.users && <>
            <Typography style={{ textAlign: 'center' }}><b>{t('statistics.users') + ': '}</b>{items.users.length.toString()}</Typography>
            <Box mt={theme.spacing(0.2)}/>
            <Typography style={{ textAlign: 'center' }} color="textSecondary">
              <b>{t('statistics.customers') + ': '}</b>{items.users.filter((item) => item.appType.includes('food')).length.toString()}
            </Typography>
            <Typography style={{ textAlign: 'center' }} color="textSecondary">
              <b>{t('statistics.drivers') + ': '}</b>{items.users.filter((item) => item.appType.includes('driver')).length.toString()}
            </Typography>
            <Typography style={{ textAlign: 'center' }} color="textSecondary">
              <b>{t('statistics.managers') + ': '}</b>{items.users.filter((item) => item.appType.includes('manager')).length.toString()}
            </Typography>
            <Typography style={{ textAlign: 'center' }} color="textSecondary">
              <b>{t('statistics.staff') + ': '}</b>{items.users.filter((item) => item.appType.includes('staff')).length.toString()}
            </Typography>
          </>}
        </Box>
      </Paper>
      <Box mr={theme.spacing(0.4)}/>
      {/*----Reviews----*/}
      <Paper elevation={1} style={{ width: 200 }}>
        <Box display="flex" flexDirection="column" alignItems="center" padding={theme.spacing(0.5)}>
          {/*----Loading----*/}
          {loading && <CircularProgress />}
          {/*----Error----*/}
          {!loading && error && <Error color="error" style={{ width: theme.spacing(12), height: theme.spacing(12) }}/>}
          {/*----Success----*/}
          {!loading && !error && items.reviews && <>
            <Typography style={{ textAlign: 'center' }}><b>{t('statistics.reviews') + ': '}</b>{items.reviews.length.toString()}</Typography>
            <Box mt={theme.spacing(0.2)}/>
            <Typography style={{ textAlign: 'center' }} color="textSecondary">
              <b>{t('statistics.stores') + ': '}</b>{items.reviews.filter((item) => item.restaurant).length.toString()}
            </Typography>
            <Typography style={{ textAlign: 'center' }} color="textSecondary">
              <b>{t('statistics.drivers') + ': '}</b>{items.reviews.filter((item) => item.driver).length.toString()}
            </Typography>
          </>}
        </Box>
      </Paper>
      <Box mr={theme.spacing(0.4)}/>
      {/*----Promos----*/}
      <Paper elevation={1} style={{ width: 200 }}>
        <Box display="flex" flexDirection="column" alignItems="center" padding={theme.spacing(0.5)}>
          {/*----Loading----*/}
          {loading && <CircularProgress />}
          {/*----Error----*/}
          {!loading && error && <Error color="error" style={{ width: theme.spacing(12), height: theme.spacing(12) }}/>}
          {/*----Success----*/}
          {!loading && !error && items.orders && <>
            <Typography style={{ textAlign: 'center' }}><b>{t('statistics.promos') + ': '}</b>{items.orders.filter((item) => item.promo && !item.canceled).length.toString()}</Typography>
            <Box mt={theme.spacing(0.2)}/>
            <Typography style={{ textAlign: 'center' }} color="textSecondary">
              <b>{t('statistics.regions') + ': '}</b>{items.orders.filter((item) => item.promo && item.promo.city && !item.canceled).length.toString()}
            </Typography>
            <Typography style={{ textAlign: 'center' }} color="textSecondary">
              <b>{t('statistics.stores') + ': '}</b>{items.orders.filter((item) => item.promo && item.promo.restaurant && !item.canceled).length.toString()}
            </Typography>
          </>}
        </Box>
      </Paper>
      <Box mr={theme.spacing(0.4)}/>
      {/*----Orders----*/}
      <Paper elevation={1} style={{ width: 200 }}>
        <Box display="flex" flexDirection="column" alignItems="center" padding={theme.spacing(0.5)}>
          {/*----Loading----*/}
          {loading && <CircularProgress />}
          {/*----Error----*/}
          {!loading && error && <Error color="error" style={{ width: theme.spacing(12), height: theme.spacing(12) }}/>}
          {/*----Success----*/}
          {!loading && !error && items.orders && <>
            <Typography style={{ textAlign: 'center' }}><b>{t('statistics.orders') + ': '}</b>{items.orders.length.toString()}</Typography>
            <Box mt={theme.spacing(0.2)}/>
            <Typography style={{ textAlign: 'center' }} color="textSecondary">
              <b>{t('statistics.canceled') + ': '}</b>{items.orders.filter((item) => item.canceled).length.toString()}
            </Typography>
            <Typography style={{ textAlign: 'center' }} color="textSecondary">
              <b>{t('statistics.delivery') + ': '}</b>{items.orders.filter((item) => item.deliveryType === 'delivery' && !item.canceled).length.toString()}
            </Typography>
            <Typography style={{ textAlign: 'center' }} color="textSecondary">
              <b>{t('statistics.pickup') + ': '}</b>{items.orders.filter((item) => item.deliveryType === 'pickup' && !item.canceled).length.toString()}
            </Typography>
            <Typography style={{ textAlign: 'center' }} color="textSecondary">
              <b>{t('statistics.placed') + ': '}</b>{items.orders.filter((item) => item.status === 0 && !item.canceled).length.toString()}
            </Typography>
            <Typography style={{ textAlign: 'center' }} color="textSecondary">
              <b>{t('statistics.confirmed') + ': '}</b>{items.orders.filter((item) => item.status === 1 && !item.canceled).length.toString()}
            </Typography>
            <Typography style={{ textAlign: 'center' }} color="textSecondary">
              <b>{t('statistics.onTheWay') + ': '}</b>{items.orders.filter((item) => item.status === 2 && !item.canceled).length.toString()}
            </Typography>
            <Typography style={{ textAlign: 'center' }} color="textSecondary">
              <b>{t('statistics.delivered') + ': '}</b>{items.orders.filter((item) => item.status === 3 && !item.canceled).length.toString()}
            </Typography>
          </>}
        </Box>
      </Paper>
      <Box mr={theme.spacing(0.4)}/>
      {/*----Revenue----*/}
      <Paper elevation={1} style={{ width: 200 }}>
        <Box display="flex" flexDirection="column" alignItems="center" padding={theme.spacing(0.5)}>
          {/*----Loading----*/}
          {loading && <CircularProgress />}
          {/*----Error----*/}
          {!loading && error && <Error color="error" style={{ width: theme.spacing(12), height: theme.spacing(12) }}/>}
          {/*----Success----*/}
          {!loading && !error && items.orders && (() => {
            let storesRevenue = 0, serviceRevenue = 0, currency = '';
            items.orders.filter((item) => !!item.restaurant).forEach((item) => {
              if (!currency) currency = item.city ? t('currency.' + item.city.currency) : '';
              if (!item.canceled && item.status >= FINISHED_ORDER_STATUS) {
                serviceRevenue += !item.options.freeDelivery ? item.options.service : item.options.service - item.options.delivery;
                storesRevenue += (item.options.itemsTotal - (item.options.discount || 0)) * item.restaurant.fee;
              }
            });
            return <>
              <Typography style={{ textAlign: 'center' }}><b>{t('statistics.revenue') + ': '}</b>{(storesRevenue + serviceRevenue).toFixed(1) + currency}</Typography>
              <Box mt={theme.spacing(0.2)}/>
              <Typography style={{ textAlign: 'center' }} color="textSecondary">
                <b>{t('statistics.stores') + ': '}</b>{storesRevenue.toFixed(1) + currency}
              </Typography>
              <Typography style={{ textAlign: 'center' }} color="textSecondary">
                <b>{t('statistics.service') + ': '}</b>{serviceRevenue.toFixed(1) + currency}
              </Typography>
            </>;
          })()}
        </Box>
      </Paper>
    </Box>}
  </Box>;
}

export default Statistics;
