import React, { useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme, Paper, Box, Typography, Select, MenuItem, Switch, Button } from '@material-ui/core';
import { languages } from './../../localization';
import { dispatchUser, putUser } from '../../api';
import { useSnackbar } from 'notistack';
import { useLoading } from '../../utils';
import { dispatchTheme } from './../../theme';

const Settings = () => {
  const theme = useTheme();
  const store = useStore();
  const { t } = useTranslation();
  const { user } = useSelector(state => state);
  const { enqueueSnackbar } = useSnackbar();
  const showLoading = useLoading();
  //Used states
  const [ language, setLanguage ] = useState(user.language);
  const [ darkMode, setDarkMode ] = useState(user.theme === 'dark');
  const [ alertSound, setAlertSound ] = useState(localStorage.getItem('ALERT') === "true");
  //Methods
  const save = () => {
    showLoading(true);
    const newTheme = darkMode ? 'dark' : 'light';
    putUser({ params: { language, theme: newTheme } })
    .then((savedUser) => {
      localStorage.setItem('ALERT', alertSound ? "true" : null);
      dispatchUser(store, savedUser);
      enqueueSnackbar(t('settings.saveSuccess'), { variant: 'success' });
      if (newTheme !== user.theme) dispatchTheme(store, savedUser.theme);
      if (language !== user.language) document.location.reload();
    })
    .catch(() => enqueueSnackbar(t('settings.saveError'), { variant: 'error' }))
    .finally(() => showLoading(false));
  }
  //Render page
  return <Paper elevation={1} style={{ maxWidth: 600, margin: 'auto' }}>
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box mt={theme.spacing(0.2)}/>
      <Typography variant="h6"><b>{t('menu.settings')}</b></Typography>
      <Box mt={theme.spacing(0.6)}/>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="70%">
        <Typography>{t('settings.language')}</Typography>
        <Select variant="outlined" MenuProps={{ elevation: 2 }} value={language} onChange={(e) => setLanguage(e.target.value)}>
          {Object.keys(languages).map((key) => <MenuItem key={key} value={key}>{languages[key].name}</MenuItem>)}
        </Select>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="70%">
        <Typography>{t('settings.darkMode')}</Typography>
        <Switch checked={darkMode} onChange={(e) => setDarkMode(e.target.checked)} color="primary"/>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="70%">
        <Typography>{t('settings.alertSound')}</Typography>
        <Switch checked={alertSound} onChange={(e) => setAlertSound(e.target.checked)} color="primary"/>
      </Box>
      <Box mt={theme.spacing(0.4)}/>
      <Button variant="contained" color="primary" onClick={save}
        style={{ paddingInline: theme.spacing(6) }}>{t('settings.save')}</Button>
      <Box mt={theme.spacing(0.4)}/>
    </Box>
  </Paper>;
}

export default Settings;
