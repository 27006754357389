import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme, Paper, Box, Typography, CircularProgress, Button, IconButton, Checkbox, Toolbar, Tooltip, TableContainer, Table,
  TableHead, TableBody, TableCell, TableRow, TablePagination, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText,
  OutlinedInput, Select, MenuItem, InputAdornment, FormControlLabel } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Error, Add, Edit, Delete, Search, Assessment, GetApp } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { get, post, getPointerFromId, callFunction } from '../../api';
import { useLoading } from '../../utils';
import { getDir, getRTL } from '../../localization';
import { collections, promoTypes, promoUseTypes, fetchCountOptions, fetchCount } from '../../configs';

const Promos = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const showLoading = useLoading();
  //Used states
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(false);
  const [ items, setItems ] = useState([]);
  const [ count, setCount ] = useState(0);
  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(fetchCount);
  const [ selected, setSelected ] = useState([]);
  const [ filter, setFilter ] = useState('code');
  const [ searchText, setSearchText ] = useState('');
  const [ cityFilter, setCityFilter ] = useState('/');
  const [ expiredFilter, setExpiredFilter ] = useState(false);
  const [ showDeleteDialog, setShowDeleteDialog ] = useState(false);
  const [ showActionDialog, setShowActionDialog ] = useState(false);
  const [ showUsageDialog, setShowUsageDialog ] = useState(false);
  const [ usageCountLoading, setUsageCountLoading ] = useState(false);
  const [ usageCountMsg, setUsageCountMsg ] = useState('');
  const [ action, setAction ] = useState(null);
  const [ cities, setCities ] = useState([]);
  const [ id, setId ] = useState('');
  const [ code, setCode ] = useState('');
  const [ value, setValue ] = useState('');
  const [ type, setType ] = useState(promoTypes[0]);
  const [ useType, setUseType ] = useState(promoUseTypes[0]);
  const [ minCost, setMinCost ] = useState('');
  const [ maxDiscountValue, setMaxDiscountValue ] = useState('');
  const [ city, setCity ] = useState('/');
  const [ restaurant, setRestaurant ] = useState('');
  const [ food, setFood ] = useState('');
  const [ expirationDate, setExpirationDate ] = useState(new Date());
  //Used attributes
  const empty = count === 0;
  const dir = getDir();
  const align = !getRTL() ? 'left' : 'right';
  const headers = [
    { id: 'id', label: t('promos.id'), width: '10%', align, disablePadding: true },
    { id: 'code', label: t('promos.code'), width: '10%', align, disablePadding: false },
    { id: 'value', label: t('promos.value'), width: '10%', align, disablePadding: false },
    { id: 'type', label: t('promos.type'), width: '10%', align, disablePadding: false },
    { id: 'useType', label: t('promos.useType'), width: '10%', align, disablePadding: false },
    { id: 'minCost', label: t('promos.minCost'), width: '10%', align, disablePadding: false },
    { id: 'maxDiscountValue', label: t('promos.maxDiscountValue'), width: '10%', align, disablePadding: false },
    { id: 'target', label: t('promos.target'), width: '10%', align, disablePadding: false },
    { id: 'expirationDate', label: t('promos.expirationDate'), width: '10%', align, disablePadding: false },
    { id: 'dateCreated', label: t('promos.dateCreated'), width: '10%', align, disablePadding: false }
  ];
  const filters = [
    { value: 'objectId', label: t('promos.id') },
    { value: 'code', label: t('promos.code') },
    { value: 'restaurant', label: t('promos.storeId') },
    { value: 'food', label: t('promos.foodId') }
  ];
  //Methods
  const exportItems = () => {
    const header = headers.map((el) => el.label).join(',');
    const selectedItems = items.filter((item) => selected.includes(item.id))
    .map((item) => item.id + ',' + item.code + ',' + (item.value ? item.value : '/') + ',' + t('promos.' + item.type) +
      ',' + t('promos.' + item.useType) + ',' + (item.minCost ? item.minCost : '/') + ',' + (item.maxDiscountValue ? item.maxDiscountValue : '/') +
      ',' + (
        item.city ? t('promos.region') + ': ' + item.city.name :
        item.food ? t('promos.food') + ': ' + item.food.name :
        item.restaurant ? t('promos.store') + ': ' + item.restaurant.name :
        '/'
      ) + ',' + item.expirationDate.toLocaleDateString() + ',' + item.dateCreated);
    const data = header + '\n' + selectedItems.join('\n');
    var encodedUri = encodeURI(data);
    var link = document.createElement("a");
    link.setAttribute("href", "data:text/csv;charset=utf-8," + encodedUri);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
  }

  const add = () => {
    setAction('add');
    setShowActionDialog(true);
    setId('');
    setCode('');
    setValue('');
    setType(promoTypes[0]);
    setUseType(promoUseTypes[0]);
    setMinCost('');
    setMaxDiscountValue('');
    setCity('/');
    setRestaurant('');
    setFood('');
    setExpirationDate(new Date());
  }

  const edit = () => {
    if (selected.length > 1) {
      enqueueSnackbar(t('promos.oneOnly'), { variant: 'error' });
      return;
    }
    setAction('edit');
    setShowActionDialog(true);
    const item = items.find((el) => el.id === selected[0]);
    setId(item.id);
    setCode(item.code);
    setValue(item.value ? item.value : '');
    setType(item.type);
    setUseType(item.useType);
    setMinCost(item.minCost ? item.minCost : '');
    setMaxDiscountValue(item.maxDiscountValue ? item.maxDiscountValue : '');
    setCity(item.city ? item.city.objectId : '/');
    setRestaurant(item.restaurant ? item.restaurant.objectId : '');
    setFood(item.food ? item.food.objectId : '');
    setExpirationDate(item.expirationDate);
  }

  const showUsage = () => {
    if (selected.length > 1) {
      enqueueSnackbar(t('promos.oneOnly'), { variant: 'error' });
      return;
    }
    const item = items.find((el) => el.id === selected[0]);
    setShowUsageDialog(true);
    setUsageCountLoading(true);
    get({ collection: collections.Order, count: true, queryParams: [
      { equalTo: { key: 'promo', value: getPointerFromId({ collection: collections.Promo, objectId: item.id }) } },
      { equalTo: { key: 'canceled', value: false } }
    ]})
    .then((result) => setUsageCountMsg(t('promos.usageCountMsg').replace('%s', result)))
    .catch(() => {})
    .finally(() => setUsageCountLoading(false));
  }

  const remove = () => {
    setShowDeleteDialog(true);
  }

  const itemAction = () => {
    if (!code || !type || !useType || (!value && type !== 'freedelivery' && type !== 'freeall')) {
      enqueueSnackbar(t('common.inputError'), { variant: 'error' });
      return;
    }
    const now = new Date();
    if (expirationDate <= now) {
      enqueueSnackbar(t('promos.expirationError'), { variant: 'error' });
      return;
    }
    showLoading(true);
    const usedCode = code.toUpperCase();
    const params = { code: usedCode, type, useType, appType: 'food',
      expirationDate: action === 'add' ? expirationDate : expirationDate.toISOString()
    };
    if (city !== '/') {
      if (action === 'add') params.city = getPointerFromId({ collection: collections.City, objectId: city });
      else params.cityId = city;
    } else if (restaurant) {
      if (action === 'add') params.restaurant = getPointerFromId({ collection: collections.Restaurant, objectId: restaurant });
      else params.restaurantId = restaurant;
      if (food) {
        if (action === 'add') params.food = getPointerFromId({ collection: collections.Food, objectId: food });
        else params.foodId = food;
      }
    }
    if (type !== 'freedelivery' && type !== 'freeall') params.value = parseInt(value);
    else params.value = null;
    if (minCost) params.minCost = parseInt(minCost);
    else params.minCost = null;
    if (maxDiscountValue && (type === 'percentage' || type === 'percentage+free')) params.maxDiscountValue = parseInt(maxDiscountValue);
    else params.maxDiscountValue = null;
    let promise = null;
    if (action === 'add') {
      promise = callFunction({ funcName: 'uniquePromo', params: { code: usedCode } })
      .then(() => post({ collection: collections.Promo, isPublic: true, params }));
    } else if (action === 'edit') {
      params.id = id;
      promise = callFunction({ funcName: 'editPromo', params });
    }
    promise.then((obj) => {
      if (city === '/' && restaurant) return callFunction({ funcName: 'assignPromo', params: {
        id: obj && action === 'add' ? obj.objectId : id, restaurantId: restaurant
      }});
    }).then(() => {
      if (action === 'add') {
        enqueueSnackbar(t('promos.addSuccess'), { variant: 'success' });
        setShowActionDialog(false);
        const newPage = 0;
        setPage(newPage);
        fetch({ newPage });
      } else if (action === 'edit') {
        enqueueSnackbar(t('promos.editSuccess'), { variant: 'success' });
        setShowActionDialog(false);
        fetch();
      }
    })
    .catch((error) => {
      if (typeof error === 'object' && error.message === 'PROMO_EXISTS') enqueueSnackbar(t('promos.codeTakenError'), { variant: 'error' });
      else if (action === 'add') enqueueSnackbar(t('promos.addError'), { variant: 'error' });
      else if (action === 'edit') enqueueSnackbar(t('promos.editError'), { variant: 'error' });
    })
    .finally(() => showLoading(false));
  }

  const itemDelete = () => {
    showLoading(true);
    callFunction({ funcName: 'deletePromos', params: { ids: selected } })
    .then(() => {
      enqueueSnackbar(t('promos.deleteSuccess'), { variant: 'success' });
      setShowDeleteDialog(false);
      setSelected([]);
      fetch();
    })
    .catch(() => enqueueSnackbar(t('promos.deleteError'), { variant: 'error' }))
    .finally(() => showLoading(false));
  }

  const search = ({ newCityFilter, newExpiredFilter } = {}) => {
    const newPage = 0;
    setPage(newPage);
    if (newCityFilter !== undefined) setCityFilter(newCityFilter);
    if (newExpiredFilter !== undefined) setExpiredFilter(newExpiredFilter);
    fetch({ newPage, newCityFilter, newExpiredFilter });
  }

  const selectAll = (e) => {
    if (e.target.checked) setSelected(items.map((el) => el.id));
    else setSelected([]);
  }

  const selectItem = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) newSelected = newSelected.concat(selected, id);
    else if (selectedIndex === 0) newSelected = newSelected.concat(selected.slice(1));
    else if (selectedIndex === selected.length - 1) newSelected = newSelected.concat(selected.slice(0, -1));
    else if (selectedIndex > 0) newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    setSelected(newSelected);
  }

  const changePage = (e, newPage) => {
    setPage(newPage);
    fetch({ newPage });
  }

  const changeRowsPerPage = (e) => {
    const newPage = 0;
    const newRowsPerPage = parseInt(e.target.value);
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
    fetch({ newPage, newRowsPerPage });
  }

  const fetch = ({ newPage, newRowsPerPage, newCityFilter, newExpiredFilter } = {}) => {
    const usedPage = newPage !== undefined ? newPage : page;
    const usedRowsPerPage = newRowsPerPage !== undefined ? newRowsPerPage : rowsPerPage;
    const usedCityFilter = newCityFilter !== undefined ? newCityFilter : cityFilter;
    const usedExpiredFilter = newExpiredFilter !== undefined ? newExpiredFilter : expiredFilter;
    const now = new Date();
    setLoading(true);
    setError(false);
    get({ collection: collections.Promo, withCount: true, queryParams: [
      { descending: 'createdAt' },
      { limit: usedRowsPerPage },
      { skip: usedPage * usedRowsPerPage },
      { include: 'city' },
      { include: 'restaurant' },
      { include: 'food' },
      usedExpiredFilter ? { lessThanOrEqualTo: { key: 'expirationDate', value: now } } : {},
      usedCityFilter === '/' ? {} : { equalTo: { key: 'city', value: getPointerFromId({ collection: collections.City, objectId: usedCityFilter }) } },
      searchText ? filter === 'restaurant' ? {
        equalTo: { key: 'restaurant', value: getPointerFromId({ collection: collections.Restaurant, objectId: searchText }) }
      } : filter === 'food' ? {
        equalTo: { key: 'food', value: getPointerFromId({ collection: collections.Food, objectId: searchText }) }
      } : { startsWith: { key: filter, value: searchText } } : {}
    ]})
    .then((data) => {
      setCount(data.count);
      setItems(data.results.map((item) => { return {
        id: item.objectId, code: item.code, value: item.value, type: item.type, useType: item.useType, minCost: item.minCost, maxDiscountValue: item.maxDiscountValue,
        city: item.city, restaurant: item.restaurant, food: item.food, expirationDate: new Date(item.expirationDate.iso),
        dateCreated: (new Date(item.createdAt)).toLocaleDateString()
      }}));
    })
    .catch(() => setError(true))
    .finally(() => setLoading(false));
  }

  const initFetch = () => {
    //Fetch regions
    get({ collection: collections.City, queryParams: [ { descending: 'createdAt' } ]})
    .then((data) => setCities(data)).catch(() => {});
    //Fetch items
    fetch();
  }
  //Effect for fetching data
  useEffect(initFetch, []);
  //Render page
  return <Paper elevation={1}>
    <Box display="flex" flexDirection="column" alignItems="center" paddingX={theme.spacing(0.5)}>
      {/*----Title----*/}
      <Box mt={theme.spacing(0.2)}/>
      <Typography variant="h6"><b>{t('menu.promos')}</b></Typography>
      <Box mt={theme.spacing(0.2)}/>
      {/*----Content----*/}
      <Toolbar style={{ width: '100%', overflowX: 'auto', overflowY: 'hidden',
        backgroundColor: selected.length > 0 ? theme.palette.primary.main + '20' : 'transparent' }}>
        {selected.length > 0 ? <>
          <Typography style={{ flex: 1 }} color="inherit" variant="subtitle1">{selected.length + ' ' + t('common.selected')}</Typography>
          <Tooltip title={t('common.export')}><IconButton onClick={exportItems}><GetApp color="primary"/></IconButton></Tooltip>
          <Tooltip title={t('promos.usageCount')}><IconButton onClick={showUsage}><Assessment color="secondary"/></IconButton></Tooltip>
          <Tooltip title={t('common.delete')}><IconButton onClick={remove}><Delete color="error"/></IconButton></Tooltip>
          <Tooltip title={t('common.edit')}><IconButton onClick={edit}><Edit color="action"/></IconButton></Tooltip>
        </> : <>
          <OutlinedInput placeholder={t('common.search')} style={{ minWidth: 200, height: 38 }}
            value={searchText} onChange={(e) => setSearchText(e.target.value)} onKeyUp={(e) => { if (e.key === 'Enter') search() }}
            endAdornment={<InputAdornment position="end">
              <IconButton size="small" onClick={search}><Search color="primary"/></IconButton>
            </InputAdornment>}
          />
          <Box mr={theme.spacing(0.2)}/>
          <Select variant="outlined" style={{ height: 38 }} MenuProps={{ elevation: 2, dir }} value={filter} onChange={(e) => setFilter(e.target.value)}>
            {filters.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
          </Select>
          <Box mr={theme.spacing(0.4)}/>
          <Typography>{t('promos.region')}</Typography>
          <Box mr={theme.spacing(0.2)}/>
          <Select variant="outlined" style={{ height: 38 }} MenuProps={{ elevation: 2 }} value={cityFilter}
            onChange={(e) => search({ newCityFilter: e.target.value })}>
            <MenuItem value="/">/</MenuItem>
            {cities.map((item) => <MenuItem key={item.objectId} value={item.objectId}>{item.name}</MenuItem>)}
          </Select>
          <Box mr={theme.spacing(0.4)}/>
          <FormControlLabel
            control={<Checkbox checked={expiredFilter} onChange={(e) => search({ newExpiredFilter: e.target.checked })} color="primary"/>}
            label={t('promos.showExpired')}/>
          <Box flex={1}/>
          <Tooltip title={t('common.add')}><IconButton onClick={add}><Add color="primary"/></IconButton></Tooltip>
        </>}
      </Toolbar>
      <TableContainer>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox color="primary" indeterminate={selected.length > 0 && selected.length < items.length}
                  checked={items.length > 0 && selected.length === items.length} onChange={selectAll}/>
              </TableCell>
              {headers.map((item) => (
                <TableCell key={item.id} width={item.width} align={item.align} padding={item.disablePadding ? 'none' : 'default'}>
                  <b>{item.label}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/*----Success----*/}
            {!loading && !error && !empty && items.map((item) => {
              const isItemSelected = selected.indexOf(item.id) !== -1;
              return <TableRow hover key={item.id} selected={isItemSelected} onClick={(e) => selectItem(item.id)}>
                <TableCell padding="checkbox"><Checkbox color="primary" checked={isItemSelected}/></TableCell>
                <TableCell align={align} padding="none">{item.id}</TableCell>
                <TableCell align={align}>{item.code}</TableCell>
                <TableCell align={align}>{item.value ? item.value : '/'}</TableCell>
                <TableCell align={align}>{t('promos.' + item.type)}</TableCell>
                <TableCell align={align}>{t('promos.' + item.useType)}</TableCell>
                <TableCell align={align}>{item.minCost ? item.minCost : '/'}</TableCell>
                <TableCell align={align}>{item.maxDiscountValue ? item.maxDiscountValue : '/'}</TableCell>
                <TableCell align={align}>{item.city ? <>
                  <b>{t('promos.region') + ': '}</b><>{item.city.name}</>
                </> : item.food ? <>
                  <b>{t('promos.food') + ': '}</b><>{item.food.name}</>
                </> : item.restaurant ? <>
                  <b>{t('promos.store') + ': '}</b><>{item.restaurant.name}</>
                </> : '/'}</TableCell>
                <TableCell align={align}>{item.expirationDate.toLocaleDateString()}</TableCell>
                <TableCell align={align}>{item.dateCreated}</TableCell>
              </TableRow>;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/*----Delete confirmation dialog----*/}
      <Dialog dir={dir} open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('promos.dialog_delete')}</DialogTitle>
        <DialogContent><DialogContentText>{t('common.confirmMsg')}</DialogContentText></DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={itemDelete} color="primary">{t('common.delete')}</Button>
        </DialogActions>
      </Dialog>
      {/*----Usage count dialog----*/}
      <Dialog dir={dir} open={showUsageDialog} onClose={() => setShowUsageDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('promos.usageCount')}</DialogTitle>
        <DialogContent>
          {usageCountLoading && <Box style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress/></Box>}
          {!usageCountLoading && <DialogContentText>{usageCountMsg}</DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowUsageDialog(false)} color="primary">{t('common.cancel')}</Button>
        </DialogActions>
      </Dialog>
      {/*----Add/Edit dialog----*/}
      <Dialog dir={dir} open={showActionDialog} onClose={() => setShowActionDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('promos.dialog_' + action)}</DialogTitle>
        <DialogContent>
          <OutlinedInput disabled={action === 'edit'} placeholder={t('promos.code')} autoFocus fullWidth value={code} onChange={(e) => setCode(e.target.value)}/>
          <Box mt={theme.spacing(0.15)}/>
          <OutlinedInput disabled={type === 'freedelivery' || type === 'freeall'} placeholder={t('promos.value')} type="number" fullWidth value={value} onChange={(e) => setValue(e.target.value)}/>
          <Box mt={theme.spacing(0.15)}/>
          <OutlinedInput placeholder={t('promos.minCost')} type="number" fullWidth value={minCost} onChange={(e) => setMinCost(e.target.value)}/>
          <Box mt={theme.spacing(0.15)}/>
          <OutlinedInput disabled={type !== 'percentage' && type !== 'percentage+free'} placeholder={t('promos.maxDiscountValue')} type="number" fullWidth value={maxDiscountValue} onChange={(e) => setMaxDiscountValue(e.target.value)}/>
          <Box mt={theme.spacing(0.15)}/>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker disableToolbar variant="inline" format="MM/dd/yyyy" margin="normal" label={t('promos.expirationDate')}
              value={expirationDate} onChange={(date) => setExpirationDate(date)} PopoverProps={{ PaperProps: { elevation: 2 } }}/>
          </MuiPickersUtilsProvider>
          <Box mt={theme.spacing(0.15)}/>
          <Box display="flex" alignItems="center">
            <Typography><b>{t('promos.type')}</b></Typography>
            <Box mr={theme.spacing(0.4)}/>
            <Select variant="outlined" MenuProps={{ elevation: 2 }} value={type} onChange={(e) => setType(e.target.value)}>
              {promoTypes.map((item) => <MenuItem key={item} value={item}>{t('promos.' + item)}</MenuItem>)}
            </Select>
          </Box>
          <Box mt={theme.spacing(0.15)}/>
          <Box display="flex" alignItems="center">
            <Typography><b>{t('promos.useType')}</b></Typography>
            <Box mr={theme.spacing(0.4)}/>
            <Select variant="outlined" MenuProps={{ elevation: 2 }} value={useType} onChange={(e) => setUseType(e.target.value)}>
              {promoUseTypes.map((item) => <MenuItem key={item} value={item}>{t('promos.' + item)}</MenuItem>)}
            </Select>
          </Box>
          <Box mt={theme.spacing(0.15)}/>
          <Box display="flex" alignItems="center">
            <Typography><b>{t('promos.region')}</b></Typography>
            <Box mr={theme.spacing(0.4)}/>
            <Select variant="outlined" MenuProps={{ elevation: 2 }} value={city} onChange={(e) => setCity(e.target.value)}>
              <MenuItem value="/">/</MenuItem>
              {cities.map((item) => <MenuItem key={item.objectId} value={item.objectId}>{item.name}</MenuItem>)}
            </Select>
          </Box>
          <Box mt={theme.spacing(0.15)}/>
          <OutlinedInput disabled={city !== '/'} placeholder={t('promos.storeId')} fullWidth value={restaurant} onChange={(e) => setRestaurant(e.target.value)}/>
          <Box mt={theme.spacing(0.15)}/>
          <OutlinedInput disabled={city !== '/' || !restaurant} placeholder={t('promos.foodId')} fullWidth value={food} onChange={(e) => setFood(e.target.value)}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowActionDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={itemAction} color="primary">{t('common.' + action)}</Button>
        </DialogActions>
      </Dialog>
      {/*----Loading----*/}
      {loading && <>
        <Box mt={theme.spacing(0.8)}/>
        <CircularProgress />
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Error----*/}
      {!loading && error && <>
        <Box mt={theme.spacing(0.6)}/>
        <Error color="error" style={{ width: theme.spacing(12), height: theme.spacing(12) }}/>
        <Typography color="textSecondary">{t('common.problemMsg')}</Typography>
        <Box mt={theme.spacing(0.2)}/>
        <Button variant="contained" color="primary" style={{ paddingInline: theme.spacing(3), height: 35 }} onClick={initFetch}>
          {t('common.tryAgain')}
        </Button>
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Empty----*/}
      {!loading && !error && empty && <>
        <Box mt={theme.spacing(0.4)}/>
        <Typography color="textSecondary">{t('promos.emptyMsg')}</Typography>
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Success----*/}
      {!loading && !error && !empty && <>
        <TablePagination rowsPerPageOptions={fetchCountOptions} rowsPerPage={rowsPerPage} page={page} count={count}
          onChangePage={changePage} onChangeRowsPerPage={changeRowsPerPage} labelRowsPerPage={t('common.rowsPerPage')}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('common.of')} ${count}`}
          SelectProps={{ MenuProps: { elevation: 2 } }} component="div" style={{ alignSelf: 'flex-end' }}/>
        <Box mt={theme.spacing(0.4)}/>
      </>}
    </Box>
  </Paper>;
}

export default Promos;
