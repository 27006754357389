
//Action types
const SET_THEME = 'SET_THEME';

//Action creators
const setThemeAction = (theme) => {
    return { type: SET_THEME, payload: theme };
};

//Reducers
export const themeReducer = (state = 'light', action) => {
    switch (action.type) {
        case SET_THEME:
            return action.payload;
        default:
            return state;
    }
};

//Save theme in the store
export const dispatchTheme = (store, theme) => {
    store.dispatch(setThemeAction(theme));
}
