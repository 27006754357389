import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { collections } from "./../configs";
import { dispatchTheme, themeMaker } from "./../theme";
import { ThemeProvider, CssBaseline, Slide } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { LoadingProvider } from "./../utils";
import { BrowserRouter as Router } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { initLocalization } from './../localization';
import { get, getUser, logout, dispatchUser } from "./../api";
import { Splash, Login } from "./../pages";
import MainStack from "./stacks/MainStack";

const Root = () => {
  const { user, update, theme } = useSelector(state => state);
  const store = useStore();
  //Used states
  const [ loading, setLoading ] = useState(true);
  const [ locale, setLocale ] = useState(null);
  //Effect for handling navigation and user data
  useEffect(() => {
    let language = null, finalUser = null;
    getUser().then((savedUser) => {
      if (!savedUser) throw new Error();
      return get({ collection: collections.User, isFindOne: true, queryParams: [
        { equalTo: { key: 'objectId', value: savedUser.objectId } }
      ]});
    }).then((fetchedUser) => {
      if (!fetchedUser) throw new Error();
      finalUser = fetchedUser;
      if (finalUser.language) language = finalUser.language;
      if (finalUser.theme) dispatchTheme(store, finalUser.theme);
      dispatchUser(store, finalUser);
    }).catch((error) => {
      if (typeof error === 'string' && error.includes('209')) logout().catch(() => {}).finally(() => dispatchUser(store, null));
    }).finally(() => {
      if (!update) {
        setLocale(language);
        initLocalization(language);
      }
      setLoading(false);
    });
  }, [update, store]);
  //Render app
  return (
    <ThemeProvider theme={themeMaker(theme, locale)}>
      <CssBaseline/>
      <I18nextProvider i18n={i18next}>
        <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={3000} TransitionComponent={Slide}>
          <LoadingProvider>
            {loading ? <Splash/> : !user ? <Login/> : <Router><MainStack/></Router>}
          </LoadingProvider>
        </SnackbarProvider>
      </I18nextProvider>
    </ThemeProvider>
  );
};

export default Root;
