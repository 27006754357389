import React from 'react';
import { CircularProgress, useTheme } from '@material-ui/core';

export const Splash = () => {
    const theme = useTheme();
    //Render component
    return <div style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center',
        backgroundColor: theme.palette.background.default }}>
        <CircularProgress/>
    </div>;
}
