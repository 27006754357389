import React, { createContext, useContext, useState } from "react";
import { useTheme, Box, CircularProgress } from '@material-ui/core';

//Creating loading context
const LoadingContext = createContext();

//Creating loading provider
export const LoadingProvider = ({ children }) => {
    const theme = useTheme();
    const [ loading, setLoading ] = useState(false);
    return (
        <LoadingContext.Provider value={setLoading}>
            {children}
            {loading && <Box style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', display: 'flex',
                alignItems: 'center', justifyContent: 'center', backgroundColor: '#000000b0', zIndex: theme.zIndex.modal + 1 }}>
                <CircularProgress/>
            </Box>}
        </LoadingContext.Provider>
    );
}

//Creating a hook function to provide loading
export const useLoading = () => {
    return useContext(LoadingContext);
}
