import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useTheme, Paper, Box, Typography, CircularProgress, Button, IconButton, Checkbox, Toolbar, Tooltip, TableContainer, Table,
  TableHead, TableBody, TableCell, TableRow, TablePagination, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText,
  OutlinedInput, MenuItem, Select, InputAdornment, FormControlLabel, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Error, Add, Edit, Delete, Close, Search, ArrowForward, ArrowBack, Check, Block, GetApp, FileCopy } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { get, post, callFunction, getConfig, getPointerFromId, postFile, delFile } from '../../api';
import { useLoading, ImageUploadResize } from '../../utils';
import { getDir, getRTL } from '../../localization';
import { collections, fetchCountOptions, fetchCount } from '../../configs';

const Products = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const showLoading = useLoading();
  const history = useHistory();
  let { c_id, s_id, l_id, l_name } = useParams();
  if (l_name) l_name = decodeURIComponent(escape(window.atob(l_name.replace('***', '/'))));
  //Used states
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(false);
  const [ items, setItems ] = useState([]);
  const [ count, setCount ] = useState(0);
  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(fetchCount);
  const [ selected, setSelected ] = useState([]);
  const [ filter, setFilter ] = useState('name');
  const [ searchText, setSearchText ] = useState('');
  const [ showDeleteDialog, setShowDeleteDialog ] = useState(false);
  const [ showDuplicateDialog, setShowDuplicateDialog ] = useState(false);
  const [ showVariantsDialog, setShowVariantsDialog ] = useState(false);
  const [ showActionDialog, setShowActionDialog ] = useState(false);
  const [ action, setAction ] = useState(null);
  const [ id, setId ] = useState('');
  const [ name, setName ] = useState('');
  const [ description, setDescription ] = useState('');
  const [ picture, setPicture ] = useState(null);
  const [ pictureResponse, setPictureResponse ] = useState(null);
  const [ price, setPrice ] = useState('');
  const [ discountPrice, setDiscountPrice ] = useState('');
  const [ enabled, setEnabled ] = useState(true);
  const [ instructions, setInstructions ] = useState([]);
  const [ headerItems, setHeaderItems ] = useState([]);
  const [ variants, setVariants ] = useState([]);
  const [ instructionItem, setInstructionItem ] = useState({ name: '', cost: 0 });
  const [ instructionIndex, setInstructionIndex ] = useState(-1);
  const [ headerItem, setHeaderItem ] = useState({ name: '', from: 0, to: 1, min: '', max: '' });
  const [ headerIndex, setHeaderIndex ] = useState(-1);
  const [ variantItem, setVariantItem ] = useState({ name: '', values: [] });
  const [ variantValue, setVariantValue ] = useState({ name: '', cost: 0 });
  const [ variantIndex, setVariantIndex ] = useState(-1);
  const [ variantValueIndex, setVariantValueIndex ] = useState(-1);
  const [ supplementsAutoComplete, setSupplementsAutoComplete ] = useState([]);
  //Used attributes
  const instructionNameRef = useRef(null);
  const headerNameRef = useRef(null);
  const variantNameRef = useRef(null);
  const empty = count === 0;
  const dir = getDir();
  const align = !getRTL() ? 'left' : 'right';
  const headers = [
    { id: 'id', label: t('products.id'), width: '15%', align, disablePadding: true },
    { id: 'picture', label: t('products.picture'), width: '15%', align, disablePadding: false },
    { id: 'name', label: t('products.name'), width: '15%', align, disablePadding: false },
    { id: 'description', label: t('products.description'), width: '15%', align, disablePadding: false },
    { id: 'price', label: t('products.price'), width: '10%', align, disablePadding: false },
    { id: 'discountPrice', label: t('products.discountPrice'), width: '10%', align, disablePadding: false },
    { id: 'status', label: t('products.status'), width: '10%', align, disablePadding: false },
    { id: 'dateCreated', label: t('products.dateCreated'), width: '10%', align, disablePadding: false }
  ];
  const filters = [
    { value: 'objectId', label: t('products.id') },
    { value: 'name', label: t('products.name') }
  ];
  //Methods
  const exportItems = () => {
    const header = headers.map((el) => el.label).join(',');
    const selectedItems = items.filter((item) => selected.includes(item.id))
    .map((item) => {
      const currency = item.city ? t('currency.' + item.city.currency) : '';
      return item.id + ',/,' + item.name + ',' + (item.description ? item.description.replaceAll(',', '') : '/') +
      ',' + (item.price ? item.price.toString() + currency : '/') +
      ',' + (item.discountPrice ? item.discountPrice.toString() + currency : '/') +
      ',' + (item.enabled ? t('products.enabled') : t('products.disabled')) + ',' + item.dateCreated;
    });
    const data = header + '\n' + selectedItems.join('\n');
    var encodedUri = encodeURI(data);
    var link = document.createElement("a");
    link.setAttribute("href", "data:text/csv;charset=utf-8," + encodedUri);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
  }

  const add = () => {
    setAction('add');
    setShowActionDialog(true);
    setId('');
    setName('');
    setDescription('');
    setPicture(null);
    setPictureResponse(null);
    setPrice('');
    setDiscountPrice('');
    setEnabled(true);
    setInstructions([]);
    setHeaderItems([]);
    setVariants([]);
    setInstructionItem({ name: '', cost: 0 });
    setInstructionIndex(-1);
    setHeaderItem({ name: '', from: 0, to: 1, min: '', max: '' });
    setHeaderIndex(-1);
    setVariantItem({ name: '', values: [] });
    setVariantValue({ name: '', cost: 0 });
    setVariantIndex(-1);
    setVariantValueIndex(-1);
  }

  const edit = () => {
    if (selected.length > 1) {
      enqueueSnackbar(t('products.oneOnly'), { variant: 'error' });
      return;
    }
    setAction('edit');
    setShowActionDialog(true);
    const item = items.find((el) => el.id === selected[0]);
    setId(item.id);
    setName(item.name);
    setDescription(item.description);
    setPicture(item.picture);
    setPictureResponse(null);
    setPrice(item.price ? item.price.toString() : '');
    setDiscountPrice(item.discountPrice ? item.discountPrice.toString() : '');
    setEnabled(item.enabled);
    setInstructions(item.instructions ? item.instructions : []);
    setHeaderItems(item.headers ? item.headers : []);
    setVariants(item.variants ? item.variants : []);
    setInstructionItem({ name: '', cost: 0 });
    setInstructionIndex(-1);
    setHeaderItem({ name: '', from: 0, to: 1, min: '', max: '' });
    setHeaderIndex(-1);
    setVariantItem({ name: '', values: [] });
    setVariantValue({ name: '', cost: 0 });
    setVariantIndex(-1);
    setVariantValueIndex(-1);
  }

  const remove = () => {
    setShowDeleteDialog(true);
  }

  const duplicate = () => {
    if (selected.length > 1) {
      enqueueSnackbar(t('products.oneOnly'), { variant: 'error' });
      return;
    }
    setShowDuplicateDialog(true);
    const item = items.find((el) => el.id === selected[0]);
    setId(item.id);
  }

  const showVariants = (op, index) => {
    if (op === 'Edit') {
      setVariantItem({ ...variants[index] });
      setVariantValue({ name: '', cost: 0 });
      setVariantIndex(index);
      setVariantValueIndex(-1);
    } else {
      setVariantItem({ name: '', values: [] });
      setVariantValue({ name: '', cost: 0 });
      setVariantIndex(-1);
      setVariantValueIndex(-1);
    }
    setShowVariantsDialog(true);
  }

  const changeInstruction = () => {
    if (!instructionItem.name || (instructionItem.cost !== 0 && !instructionItem.cost)) {
      enqueueSnackbar(t('common.inputError'), { variant: 'error' });
      return;
    }
    if (instructionIndex === -1) {
      setInstructions([ ...instructions, { id: Math.random().toString().slice(-8), name: instructionItem.name, cost: instructionItem.cost } ]);
    } else {
      const newInstruction = { id: instructions[instructionIndex].id, name: instructionItem.name, cost: instructionItem.cost };
      const tmpInstructions = [ ...instructions ];
      tmpInstructions[instructionIndex] = newInstruction;
      setInstructions(tmpInstructions);
    }
    setInstructionItem({ name: '', cost: 0 });
    setInstructionIndex(-1);
    instructionNameRef.current.focus();
  }

  const deleteInstruction = (index) => {
    const tmpArr = [...instructions];
    tmpArr.splice(index, 1);
    setInstructions(tmpArr);
    setInstructionIndex(-1);
  }

  const changeHeader = () => {
    if (!headerItem.name || (headerItem.from !== 0 && !headerItem.from) || !headerItem.to || headerItem.from >= headerItem.to) {
      enqueueSnackbar(t('common.inputError'), { variant: 'error' });
      return;
    }
    if (headerIndex === -1) {
      setHeaderItems([ ...headerItems, { name: headerItem.name, from: headerItem.from, to: headerItem.to, min: headerItem.min, max: headerItem.max } ]);
    } else {
      const newHeader = { name: headerItem.name, from: headerItem.from, to: headerItem.to, min: headerItem.min, max: headerItem.max };
      const tmpHeaders = [ ...headerItems ];
      tmpHeaders[headerIndex] = newHeader;
      setHeaderItems(tmpHeaders);
    }
    setHeaderItem({ name: '', from: 0, to: 1, min: '', max: '' });
    setHeaderIndex(-1);
    headerNameRef.current.focus();
  }

  const deleteHeader = (index) => {
    const tmpArr = [ ...headerItems ];
    tmpArr.splice(index, 1);
    setHeaderItems(tmpArr);
    setHeaderIndex(-1);
  }

  const changeVariant = () => {
    if (!variantItem.name || !variantItem.values || variantItem.values.length === 0) {
      enqueueSnackbar(t('common.inputError'), { variant: 'error' });
      return;
    }
    if (variantIndex === -1) {
      setVariants([ ...variants, { id: Math.random().toString().slice(-8), name: variantItem.name, values: variantItem.values } ]);
    } else {
      const newVariant = { id: variants[variantIndex].id, name: variantItem.name, values: variantItem.values };
      const tmpVariants = [ ...variants ];
      tmpVariants[variantIndex] = newVariant;
      setVariants(tmpVariants);
    }
    setVariantItem({ name: '', values: [] });
    setVariantValue({ name: '', cost: 0 });
    setVariantIndex(-1);
    setVariantValueIndex(-1);
    setShowVariantsDialog(false);
  }

  const deleteVariant = (index) => {
    const tmpArr = [...variants];
    tmpArr.splice(index, 1);
    setVariants(tmpArr);
  }

  const duplicateVariant = (index) => {
    setVariants([ ...variants, { ...variants[index], id: Math.random().toString().slice(-8) } ]);
  }

  const changeVariantValue = () => {
    if (!variantValue.name || (variantValue.cost !== 0 && !variantValue.cost)) {
      enqueueSnackbar(t('common.inputError'), { variant: 'error' });
      return;
    }
    if (variantValueIndex === -1) {
      setVariantItem({ ...variantItem, values: [ ...variantItem.values, { name: variantValue.name, cost: variantValue.cost } ] });
    } else {
      const newVariantValue = { name: variantValue.name, cost: variantValue.cost };
      const tmpVariantItem = { ...variantItem };
      tmpVariantItem.values[variantValueIndex] = newVariantValue;
      setVariantItem(tmpVariantItem);
    }
    setVariantValue({ name: '', cost: 0 });
    setVariantValueIndex(-1);
    variantNameRef.current.focus();
  }

  const deleteVariantValue = (index) => {
    const tmpArr = [...variantItem.values];
    tmpArr.splice(index, 1);
    setVariantItem({ ...variantItem, values: tmpArr });
    setVariantValueIndex(-1);
  }

  const itemAction = () => {
    const priceVal = price ? parseInt(price) : null;
    const discountPriceVal = discountPrice ? parseInt(discountPrice) : 0;
    if (!name || !priceVal) {
      enqueueSnackbar(t('common.inputError'), { variant: 'error' });
      return;
    } else if (discountPriceVal >= priceVal) {
      enqueueSnackbar(t('products.discountError'), { variant: 'error' });
      return;
    }
    showLoading(true);
    const params = { name, description, price: priceVal, discountPrice: discountPriceVal, enabled, variants, instructions,
      isDiscount: discountPriceVal > 0 && discountPriceVal < priceVal, headers: headerItems };
    const promise = pictureResponse ? (
      postFile({ filename: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 6) + '.' + pictureResponse.type, base64Data: pictureResponse.base64Data })
    ) : Promise.resolve();
    promise.then((file) => {
      if (file) {
        params.picture = file;
        if (picture) delFile({ filename: picture.name }).catch(() => {});
      }
      if (action === 'add') {
        params.city = getPointerFromId({ collection: collections.City, objectId: c_id });
        params.restaurant = getPointerFromId({ collection: collections.Restaurant, objectId: s_id });
        params.list = getPointerFromId({ collection: collections.List, objectId: l_id });
        return post({ collection: collections.Food, isPublic: true, params });
      } else if (action === 'edit') {
        params.id = id;
        return callFunction({ funcName: 'editProduct', params });
      }
    }).then((obj) => {
      return callFunction({ funcName: 'assignProduct', params: {
        id: obj && action === 'add' ? obj.objectId : id, restaurantId: s_id
      }});
    }).then(() => {
      if (action === 'add') {
        enqueueSnackbar(t('products.addSuccess'), { variant: 'success' });
        setShowActionDialog(false);
        const newPage = 0;
        setPage(newPage);
        fetch({ newPage });
      } else if (action === 'edit') {
        enqueueSnackbar(t('products.editSuccess'), { variant: 'success' });
        setShowActionDialog(false);
        fetch();
      }
    })
    .catch(() => {
      if (action === 'add') enqueueSnackbar(t('products.addError'), { variant: 'error' });
      else if (action === 'edit') enqueueSnackbar(t('products.editError'), { variant: 'error' });
    })
    .finally(() => showLoading(false));
  }

  const itemDelete = () => {
    showLoading(true);
    callFunction({ funcName: 'deleteProducts', params: { ids: selected } })
    .then(() => {
      enqueueSnackbar(t('products.deleteSuccess'), { variant: 'success' });
      setShowDeleteDialog(false);
      setSelected([]);
      fetch();
    })
    .catch(() => enqueueSnackbar(t('products.deleteError'), { variant: 'error' }))
    .finally(() => showLoading(false));
  }

  const itemDuplicate = () => {
    showLoading(true);
    callFunction({ funcName: 'duplicateProduct', params: { id } })
    .then(() => {
      enqueueSnackbar(t('products.duplicateSuccess'), { variant: 'success' });
      setShowDuplicateDialog(false);
      setSelected([]);
      fetch();
    })
    .catch(() => enqueueSnackbar(t('products.duplicateError'), { variant: 'error' }))
    .finally(() => showLoading(false));
  }

  const search = () => {
    const newPage = 0;
    setPage(newPage);
    fetch({ newPage });
  }

  const selectAll = (e) => {
    if (e.target.checked) setSelected(items.map((el) => el.id));
    else setSelected([]);
  }

  const selectItem = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) newSelected = newSelected.concat(selected, id);
    else if (selectedIndex === 0) newSelected = newSelected.concat(selected.slice(1));
    else if (selectedIndex === selected.length - 1) newSelected = newSelected.concat(selected.slice(0, -1));
    else if (selectedIndex > 0) newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    setSelected(newSelected);
  }

  const changePage = (e, newPage) => {
    setPage(newPage);
    fetch({ newPage });
  }

  const changeRowsPerPage = (e) => {
    const newPage = 0;
    const newRowsPerPage = parseInt(e.target.value);
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
    fetch({ newPage, newRowsPerPage });
  }

  const fetch = ({ newPage, newRowsPerPage } = {}) => {
    const usedPage = newPage !== undefined ? newPage : page;
    const usedRowsPerPage = newRowsPerPage !== undefined ? newRowsPerPage : rowsPerPage;
    setLoading(true);
    setError(false);
    get({ collection: collections.Food, withCount: true, queryParams: [
      { descending: 'createdAt' },
      { include: 'city' },
      { limit: usedRowsPerPage },
      { skip: usedPage * usedRowsPerPage },
      { equalTo: { key: 'list', value: getPointerFromId({ collection: collections.List, objectId: l_id }) } },
      searchText ? { [filter === 'name' ? 'fullText' : 'startsWith']: { key: filter, value: searchText } } : {}
    ]})
    .then((data) => {
      setCount(data.count);
      setItems(data.results.map((item) => { return {
        id: item.objectId, name: item.name, description: item.description, price: item.price, discountPrice: item.discountPrice,
        picture: item.picture, enabled: item.enabled, variants: item.variants, instructions: item.instructions, headers: item.headers,
        city: item.city, dateCreated: (new Date(item.createdAt)).toLocaleDateString()
      }}));
    })
    .then(() => getConfig())
    .then((configs) => setSupplementsAutoComplete(configs.get('supplementsAutoComplete')))
    .catch(() => setError(true))
    .finally(() => setLoading(false));
  }
  //Effect for fetching data
  useEffect(fetch, []);
  //Render page
  return <Paper elevation={1}>
    <Box display="flex" flexDirection="column" alignItems="center" paddingX={theme.spacing(0.5)}>
      {/*----Title----*/}
      <Box mt={theme.spacing(0.2)}/>
      <Typography variant="h6"><b>{t('products.products')}</b>{': ' + l_name}</Typography>
      <Box mt={theme.spacing(0.2)}/>
      {/*----Content----*/}
      <Toolbar style={{ width: '100%', overflowX: 'auto', overflowY: 'hidden',
        backgroundColor: selected.length > 0 ? theme.palette.primary.main + '20' : 'transparent' }}>
        {selected.length > 0 ? <>
          <Typography style={{ flex: 1 }} color="inherit" variant="subtitle1">{selected.length + ' ' + t('common.selected')}</Typography>
          <Tooltip title={t('common.export')}><IconButton onClick={exportItems}><GetApp color="primary"/></IconButton></Tooltip>
          <Tooltip title={t('products.duplicate')}><IconButton onClick={duplicate}><FileCopy color="secondary"/></IconButton></Tooltip>
          <Tooltip title={t('common.delete')}><IconButton onClick={remove}><Delete color="error"/></IconButton></Tooltip>
          <Tooltip title={t('common.edit')}><IconButton onClick={edit}><Edit color="action"/></IconButton></Tooltip>
        </> : <>
          <Tooltip title={t('common.goBack')}>
            <IconButton onClick={() => history.goBack()}>
              {dir === 'rtl' ? <ArrowForward color="action"/> : <ArrowBack color="action"/>}
            </IconButton>
          </Tooltip>
          <Box mr={theme.spacing(0.2)}/>
          <OutlinedInput placeholder={t('common.search')} style={{ minWidth: 200, height: 38 }}
            value={searchText} onChange={(e) => setSearchText(e.target.value)} onKeyUp={(e) => { if (e.key === 'Enter') search() }}
            endAdornment={<InputAdornment position="end">
              <IconButton size="small" onClick={search}><Search color="primary"/></IconButton>
            </InputAdornment>}
          />
          <Box mr={theme.spacing(0.2)}/>
          <Select variant="outlined" style={{ height: 38 }} MenuProps={{ elevation: 2, dir }} value={filter} onChange={(e) => setFilter(e.target.value)}>
            {filters.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
          </Select>
          <Box flex={1}/>
          <Tooltip title={t('common.add')}><IconButton onClick={add}><Add color="primary"/></IconButton></Tooltip>
        </>}
      </Toolbar>
      <TableContainer>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox color="primary" indeterminate={selected.length > 0 && selected.length < items.length}
                  checked={items.length > 0 && selected.length === items.length} onChange={selectAll}/>
              </TableCell>
              {headers.map((item) => (
                <TableCell key={item.id} width={item.width} align={item.align} padding={item.disablePadding ? 'none' : 'default'}>
                  <b>{item.label}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/*----Success----*/}
            {!loading && !error && !empty && items.map((item) => {
              const currency = item.city ? t('currency.' + item.city.currency) : '';
              const isItemSelected = selected.indexOf(item.id) !== -1;
              return <TableRow hover key={item.id} selected={isItemSelected} onClick={(e) => selectItem(item.id)}>
                <TableCell padding="checkbox"><Checkbox color="primary" checked={isItemSelected}/></TableCell>
                <TableCell align={align} padding="none">{item.id}</TableCell>
                <TableCell align={align}>{item.picture ? (
                  <a target="_blank" rel="noopener noreferrer" href={item.picture.url}>
                    <img alt={item.picture.name} src={item.picture.url} width='100%'></img>
                  </a>
                ) : ''}</TableCell>
                <TableCell align={align}>{item.name}</TableCell>
                <TableCell align={align}>{item.description}</TableCell>
                <TableCell align={align}>{item.price ? item.price.toString() + currency : '/'}</TableCell>
                <TableCell align={align}>{item.discountPrice ? item.discountPrice.toString() + currency : '/'}</TableCell>
                <TableCell align={align}>
                  {item.enabled ? (
                    <Box display="flex" alignItems="center">
                      <Check style={{ color: 'green' }}/>
                      <div>{t('products.enabled')}</div>
                    </Box>
                  ) : (
                    <Box display="flex" alignItems="center">
                      <Block color="error"/>
                      <div>{t('products.disabled')}</div>
                    </Box>
                  )}
                </TableCell>
                <TableCell align={align}>{item.dateCreated}</TableCell>
              </TableRow>;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/*----Delete confirmation dialog----*/}
      <Dialog dir={dir} open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('products.dialog_delete')}</DialogTitle>
        <DialogContent><DialogContentText>{t('common.confirmMsg')}</DialogContentText></DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={itemDelete} color="primary">{t('common.delete')}</Button>
        </DialogActions>
      </Dialog>
      {/*----Duplicate confirmation dialog----*/}
      <Dialog dir={dir} open={showDuplicateDialog} onClose={() => setShowDuplicateDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('products.dialog_duplicate')}</DialogTitle>
        <DialogContent><DialogContentText>{t('common.confirmMsg')}</DialogContentText></DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDuplicateDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={itemDuplicate} color="primary">{t('products.duplicate')}</Button>
        </DialogActions>
      </Dialog>
      {/*----Add/Edit dialog----*/}
      <Dialog dir={dir} open={showActionDialog} onClose={() => setShowActionDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('products.dialog_' + action)}</DialogTitle>
        <DialogContent>
          <OutlinedInput placeholder={t('products.name')} autoFocus fullWidth value={name} onChange={(e) => setName(e.target.value)}/>
          <Box mt={theme.spacing(0.15)}/>
          <OutlinedInput placeholder={t('products.description')} fullWidth value={description} onChange={(e) => setDescription(e.target.value)}/>
          <Box mt={theme.spacing(0.15)}/>
          <OutlinedInput placeholder={t('products.price')} fullWidth type="number" value={price} onChange={(e) => setPrice(e.target.value)}/>
          <Box mt={theme.spacing(0.15)}/>
          <OutlinedInput placeholder={t('products.discountedPrice')} fullWidth type="number" value={discountPrice} onChange={(e) => setDiscountPrice(e.target.value)}/>
          <Box mt={theme.spacing(0.15)}/>
          <Button color="primary" variant="contained"
            onClick={() => ImageUploadResize().then((imageData) => setPictureResponse(imageData)).catch(() => {})}>
            {picture || pictureResponse ? t('stores.changePicture') : t('stores.setPicture')}
          </Button>
          <Box mt={theme.spacing(0.15)}/>
          <FormControlLabel
            control={<Checkbox color="primary" checked={enabled} onChange={(e) => setEnabled(e.target.checked)}/>}
            label={t('stores.enabled')}
          />
          <Box mt={theme.spacing(0.15)}/>
          <Typography><b>{t('products.instructions')}</b></Typography>
          {instructions.length === 0 && <Typography color="textSecondary" style={{ textAlign: 'center' }}>{t('products.noInstructions')}</Typography>}
          {instructions.map((item, i) => <Box display="flex" flexDirection="row" alignItems="center">
            <Box mr={theme.spacing(0.25)}/>
            <Typography style={{ flex: 1 }}><b>{i.toString() + '- '}</b>{item.name + ' | +' + item.cost.toString()}</Typography>
            <IconButton onClick={() => deleteInstruction(i)}><Delete color="error"/></IconButton>
            <IconButton onClick={() => {
              setInstructionIndex(i); setInstructionItem({ ...instructions[i] }); instructionNameRef.current.focus();
            }}><Edit color="action"/></IconButton>
            <Box mr={theme.spacing(0.25)}/>
          </Box>)}
          <Box mt={theme.spacing(0.15)}/>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box mr={theme.spacing(0.25)}/>
            <Autocomplete freeSolo options={supplementsAutoComplete} style={{ flex: 1 }} value={instructionItem.name}
              onChange={(e, value) => setInstructionItem({ ...instructionItem, name: value })} 
              renderInput={(params) => <TextField {...params} inputRef={instructionNameRef} placeholder={t('products.name')} margin="dense"
                variant="outlined" value={instructionItem.name} onChange={(e) => setInstructionItem({ ...instructionItem, name: e.target.value })}/>}
            />
            <Box mr={theme.spacing(0.25)}/>
            <OutlinedInput placeholder={t('products.cost')} style={{ width: 100 }} type="number" onKeyUp={(e) => { if (e.key === 'Enter') changeInstruction() }}
              value={instructionItem.cost.toString()} onChange={(e) => setInstructionItem({ ...instructionItem, cost: parseInt(e.target.value)})}/>
            <Box mr={theme.spacing(0.25)}/>
            {instructionIndex === -1 ? <IconButton onClick={changeInstruction}><Add color="primary"/></IconButton> : <>
              <IconButton onClick={changeInstruction}><Check color="primary"/></IconButton>
              <IconButton onClick={() => {
                setInstructionIndex(-1); setInstructionItem({ name: '', cost: 0 }); instructionNameRef.current.focus();
              }}><Close color="action"/></IconButton>
            </>}
            <Box mr={theme.spacing(0.25)}/>
          </Box>
          <Box mt={theme.spacing(0.15)}/>
          <Typography><b>{t('products.headers')}</b></Typography>
          {headerItems.length === 0 && <Typography color="textSecondary" style={{ textAlign: 'center' }}>{t('products.noHeaders')}</Typography>}
          {headerItems.map((item, i) => <Box display="flex" flexDirection="row" alignItems="center">
            <Box mr={theme.spacing(0.25)}/>
            <Typography style={{ flex: 1 }}>{(() => {
              let msg = item.name + ' | ' + item.from.toString() + ' -> ' + item.to.toString();
              if (item.min || item.max) msg += ' |';
              if (item.min) msg += ' >= ' + item.min.toString();
              if (item.min && item.max) msg += ' &';
              if (item.max) msg += ' <= ' + item.max.toString();
              return msg;
            })()}</Typography>
            <IconButton onClick={() => deleteHeader(i)}><Delete color="error"/></IconButton>
            <IconButton onClick={() => {
              setHeaderIndex(i); setHeaderItem({ ...headerItems[i] }); headerNameRef.current.focus();
            }}><Edit color="action"/></IconButton>
            <Box mr={theme.spacing(0.25)}/>
          </Box>)}
          <Box mt={theme.spacing(0.15)}/>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box mr={theme.spacing(0.25)}/>
            <OutlinedInput inputProps={{ ref: headerNameRef }} placeholder={t('products.name')} style={{ flex: 1 }}
              value={headerItem.name} onChange={(e) => setHeaderItem({ ...headerItem, name: e.target.value})}/>
            <Box mr={theme.spacing(0.1)}/>
            <OutlinedInput placeholder={t('products.from')} style={{ width: 60 }} type="number"
              value={headerItem.from.toString()} onChange={(e) => setHeaderItem({ ...headerItem, from: parseInt(e.target.value)})}/>
            <Box mr={theme.spacing(0.1)}/>
            <OutlinedInput placeholder={t('products.to')} style={{ width: 60 }} type="number" onKeyUp={(e) => { if (e.key === 'Enter') changeHeader() }}
              value={headerItem.to.toString()} onChange={(e) => setHeaderItem({ ...headerItem, to: parseInt(e.target.value)})}/>
            <Box mr={theme.spacing(0.1)}/>
            <OutlinedInput placeholder={t('products.min')} style={{ width: 60 }} type="number"
              value={headerItem.min ? headerItem.min.toString() : ''} onChange={(e) => {
                const min = e.target.value ? parseInt(e.target.value) : 0;
                setHeaderItem({ ...headerItem, min: min || null})
              }}/>
            <Box mr={theme.spacing(0.1)}/>
            <OutlinedInput placeholder={t('products.max')} style={{ width: 60 }} type="number"
              value={headerItem.max ? headerItem.max.toString() : ''} onChange={(e) => {
                const max = e.target.value ? parseInt(e.target.value) : 0;
                setHeaderItem({ ...headerItem, max: max || null})
              }}/>
            {headerIndex === -1 ? <IconButton onClick={changeHeader}><Add color="primary"/></IconButton> : <>
              <IconButton onClick={changeHeader}><Check color="primary"/></IconButton>
              <IconButton onClick={() => {
                setHeaderIndex(-1); setHeaderItem({ name: '', from: 0, to: 1, min: '', max: '' }); headerNameRef.current.focus();
              }}><Close color="action"/></IconButton>
            </>}
            <Box mr={theme.spacing(0.25)}/>
          </Box>
          <Box mt={theme.spacing(0.15)}/>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography style={{ flex: 1 }}><b>{t('products.variants')}</b></Typography>
            <IconButton onClick={() => showVariants('Add')}><Add color="primary"/></IconButton>
          </Box>
          {variants.length === 0 && <Typography color="textSecondary" style={{ textAlign: 'center' }}>{t('products.noVariants')}</Typography>}
          {variants.map((item, i) => <Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box mr={theme.spacing(0.25)}/>
              <Typography style={{ flex: 1 }}>{item.name}</Typography>
              <IconButton onClick={() => duplicateVariant(i)}><FileCopy color="secondary"/></IconButton>
              <IconButton onClick={() => deleteVariant(i)}><Delete color="error"/></IconButton>
              <IconButton onClick={() => showVariants('Edit', i)}><Edit color="action"/></IconButton>
              <Box mr={theme.spacing(0.25)}/>
            </Box>
            {item.values.map((val) => <Box display="flex" flexDirection="row" alignItems="center">
              <Box mr={theme.spacing(0.5)}/>
              <Typography>{val.name + ' | +' + val.cost.toString()}</Typography>
            </Box>)}
          </Box>)}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowActionDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={itemAction} color="primary">{t('common.' + action)}</Button>
        </DialogActions>
      </Dialog>
      {/*----Variants dialog----*/}
      <Dialog dir={dir} open={showVariantsDialog} onClose={() => setShowVariantsDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('products.variants')}</DialogTitle>
        <DialogContent>
          <OutlinedInput placeholder={t('products.name')} autoFocus fullWidth value={variantItem.name} onChange={(e) => setVariantItem({ ...variantItem, name: e.target.value})}/>
          <Box mt={theme.spacing(0.15)}/>
          {variantItem.values.length === 0 && <Typography color="textSecondary" style={{ textAlign: 'center' }}>{t('products.noVariants')}</Typography>}
          {variantItem.values.map((item, i) => <Box display="flex" flexDirection="row" alignItems="center">
            <Box mr={theme.spacing(0.25)}/>
            <Typography style={{ flex: 1 }}>{item.name + ' | +' + item.cost.toString()}</Typography>
            <IconButton onClick={() => deleteVariantValue(i)}><Delete color="error"/></IconButton>
            <IconButton onClick={() => {
              setVariantValueIndex(i); setVariantValue({ ...variantItem.values[i] }); variantNameRef.current.focus();
            }}><Edit color="action"/></IconButton>
            <Box mr={theme.spacing(0.25)}/>
          </Box>)}
          <Box mt={theme.spacing(0.15)}/>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box mr={theme.spacing(0.25)}/>
            <OutlinedInput inputProps={{ ref: variantNameRef }} placeholder={t('products.name')} style={{ flex: 1 }}
              value={variantValue.name} onChange={(e) => setVariantValue({ ...variantValue, name: e.target.value})}/>
            <Box mr={theme.spacing(0.25)}/>
            <OutlinedInput placeholder={t('products.cost')} style={{ width: 100 }} type="number" onKeyUp={(e) => { if (e.key === 'Enter') changeVariantValue() }}
              value={variantValue.cost.toString()} onChange={(e) => setVariantValue({ ...variantValue, cost: parseInt(e.target.value)})}/>
            <Box mr={theme.spacing(0.25)}/>
            {variantValueIndex === -1 ? <IconButton onClick={changeVariantValue}><Add color="primary"/></IconButton> : <>
              <IconButton onClick={changeVariantValue}><Check color="primary"/></IconButton>
              <IconButton onClick={() => {
                setVariantValueIndex(-1); setVariantValue({ name: '', cost: 0 }); variantNameRef.current.focus();
              }}><Close color="action"/></IconButton>
            </>}
            <Box mr={theme.spacing(0.25)}/>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowVariantsDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={changeVariant} color="primary">{t('common.ok')}</Button>
        </DialogActions>
      </Dialog>
      {/*----Loading----*/}
      {loading && <>
        <Box mt={theme.spacing(0.8)}/>
        <CircularProgress />
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Error----*/}
      {!loading && error && <>
        <Box mt={theme.spacing(0.6)}/>
        <Error color="error" style={{ width: theme.spacing(12), height: theme.spacing(12) }}/>
        <Typography color="textSecondary">{t('common.problemMsg')}</Typography>
        <Box mt={theme.spacing(0.2)}/>
        <Button variant="contained" color="primary" style={{ paddingInline: theme.spacing(3), height: 35 }} onClick={fetch}>
          {t('common.tryAgain')}
        </Button>
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Empty----*/}
      {!loading && !error && empty && <>
        <Box mt={theme.spacing(0.4)}/>
        <Typography color="textSecondary">{t('products.emptyMsg')}</Typography>
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Success----*/}
      {!loading && !error && !empty && <>
        <TablePagination rowsPerPageOptions={fetchCountOptions} rowsPerPage={rowsPerPage} page={page} count={count}
          onChangePage={changePage} onChangeRowsPerPage={changeRowsPerPage} labelRowsPerPage={t('common.rowsPerPage')}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('common.of')} ${count}`}
          SelectProps={{ MenuProps: { elevation: 2 } }} component="div" style={{ alignSelf: 'flex-end' }}/>
        <Box mt={theme.spacing(0.4)}/>
      </>}
    </Box>
  </Paper>;
}

export default Products;
