import { createMuiTheme } from '@material-ui/core/styles';

export const themeMaker = (type, locale) => createMuiTheme({
    palette: {
        type: type,
        primary: {
            main: '#f2bc57'
        }
    },
    direction: locale === 'ar' ? 'rtl': 'ltr',
    typography: {
        allVariants: {
            fontFamily: locale === 'ar' ? 'Cairo' : 'Lato',
            fontWeight: 400
        }
    },
    shadows: [
        "none",
        "0px 0px 15px -4px rgba(0,0,0,0.1)",
        "0px 0px 15px -4px rgba(0,0,0,0.3)"
    ],
    props: {
        MuiButton: {
            disableElevation: true
        },
        MuiButtonBase: {
            TouchRippleProps: {
                style: {
                    color: '#ffffff'
                }
            }
        }
    },
    overrides: {
        MuiButton: {
            root: {
                height: 42,
                borderRadius: 21,
                padding: '0 30px',
                fontWeight: 700
            },
            containedPrimary: {
                '&:hover': {
                    backgroundColor: '#edce93'
                }
            }
        },
        MuiOutlinedInput: {
            root: {
                height: 48,
                borderRadius: 24
            }
        },
        MuiListItem: {
            root: {
                "&$selected": {
                    backgroundColor: type === 'light' ? "#f9efe1" : undefined,
                    "&:hover": {
                        backgroundColor: type === 'light' ? "#f9efe1" : undefined
                    }
                }
            },
            button: {
                "&:hover": {
                    backgroundColor: type === 'light' ? "#f7f4f0" : '#ffffff10'
                }
            }
        },
        MuiPaper: {
            rounded: {
                borderRadius: 15 
            }
        },
        MuiSwitch: {
            colorPrimary: {
                color: '#e1e1e1'
            }
        },
        MuiSelect: {
            outlined: {
                '&:focus': {
                    backgroundColor: 'transparent'
                }
            }
        },
        MuiTableRow: {
            root: {
                '&$selected': {
                    backgroundColor: '#f2bc5720',
                    "&:hover": {
                        backgroundColor: '#f2bc5720'
                    }
                }
            }
        }
    }
});

export { themeReducer, dispatchTheme } from './store';
