import { API_ERRORS } from "./../index";
var Parse = require('parse');

//Method for getting the current user from local storage
export const getUser = () => {
    return new Promise((resolve, reject) => {
        try {
            const user = Parse.User.current();
            let result = null;
            if (user) result = user.toJSON();
            resolve(result);
        } catch (error) {
            reject(API_ERRORS.FAILED_TO_GET_USER + ' - ' + error.code);
        }
    });
}

//Method for updating the user
export const putUser = ({ params } = {}) => {
    return new Promise((resolve, reject) => {
        //Check if any parameter is missing
        if (!params || Object.keys(params).length === 0) throw API_ERRORS.UPDATE_USER_PARAMS_MISSING;
        //Perform update
        try {
            const user = Parse.User.current();
            if (user) {
                //Extract parameters
                for (let key in params) user.set(key, params[key]);
                //Save the new user
                user.save().then((savedUser) => resolve(savedUser.toJSON()));
            } else {
                reject(API_ERRORS.FAILED_TO_UPDATE_USER);
            }
        } catch (error) {
            reject(API_ERRORS.FAILED_TO_UPDATE_USER + ' - ' + error.code);
        }
    });
}

//Method for logging in
export const login = ({ sessionToken } = {}) => {
    return new Promise((resolve, reject) => {
        Parse.User.become(sessionToken)
        .then((user) => resolve(user.toJSON()))
        .catch(() => reject());
    });
}

//Method for logging out
export const logout = () => {
    return new Promise((resolve, reject) => {
        Parse.User.logOut()
        .then(() => resolve())
        .catch((error) => {
            reject(API_ERRORS.FAILED_TO_LOGOUT + ' - ' + error.code);
        });
    });
}
