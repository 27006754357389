import React from 'react';
import PropTypes from 'prop-types';
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
import { googleMapApiKey } from "../../configs";

export { Marker, Polygon } from "react-google-maps";

const MapComponent = withScriptjs(withGoogleMap((props) => <GoogleMap {...props} ref={props.mapRef}/>));

export const Map = (props) => {
    return <MapComponent googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleMapApiKey}&v=3.exp`}
        containerElement={<div style={{ height: props.height || 400 }}/>} {...props}
        loadingElement={<div style={{ height: '100%' }}/>} mapElement={<div style={{ height: '100%' }}/>}
    />;
}

Map.propTypes = {
    height: PropTypes.number,
    mapRef: PropTypes.object,
    zoom: PropTypes.number,
    onZoomChanged: PropTypes.func,
    center: PropTypes.object,
    onCenterChanged: PropTypes.func,
    onClick: PropTypes.func,
}
