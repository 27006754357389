import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useTheme, Paper, Box, Typography, CircularProgress, Button, IconButton, Checkbox, Toolbar, Tooltip, TableContainer, Table,
  TableHead, TableBody, TableCell, TableRow, TablePagination, OutlinedInput, InputAdornment, Select, MenuItem, Dialog, DialogContent,
  DialogTitle, DialogActions, DialogContentText } from '@material-ui/core';
import { Error, Delete, Search, GetApp } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { get, callFunction } from '../../api';
import { useLoading } from '../../utils';
import { getDir, getRTL } from '../../localization';
import { collections, fetchCountOptions, fetchCount } from '../../configs';

const Support = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const showLoading = useLoading();
  const { s_id } = useParams();
  //Used states
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(false);
  const [ items, setItems ] = useState([]);
  const [ count, setCount ] = useState(0);
  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(fetchCount);
  const [ selected, setSelected ] = useState([]);
  const [ filter, setFilter ] = useState(s_id ? 'objectId' : 'fullname');
  const [ searchText, setSearchText ] = useState(s_id || '');
  const [ showDeleteDialog, setShowDeleteDialog ] = useState(false);
  //Used attributes
  const empty = count === 0;
  const dir = getDir();
  const align = !getRTL() ? 'left' : 'right';
  const headers = [
    { id: 'id', label: t('support.id'), width: '15%', align, disablePadding: true },
    { id: 'senderName', label: t('support.senderName'), width: '15%', align, disablePadding: false },
    { id: 'email', label: t('support.email'), width: '15%', align, disablePadding: false },
    { id: 'phone', label: t('support.phone'), width: '15%', align, disablePadding: false },
    { id: 'dateSent', label: t('support.dateSent'), width: '15%', align, disablePadding: false },
    { id: 'message', label: t('support.message'), width: '25%', align, disablePadding: false }
  ];
  const filters = [
    { value: 'objectId', label: t('support.id') },
    { value: 'fullname', label: t('support.senderName') },
    { value: 'email', label: t('support.email') },
    { value: 'phone', label: t('support.phone') }
  ];
  //Methods
  const exportItems = () => {
    const header = headers.map((el) => el.label).join(',');
    const selectedItems = items.filter((item) => selected.includes(item.id))
    .map((item) => item.id + ',' + item.senderName + ',' + item.email + ',' + item.phone + ',' + item.dateSent + ',' + item.message);
    const data = header + '\n' + selectedItems.join('\n');
    var encodedUri = encodeURI(data);
    var link = document.createElement("a");
    link.setAttribute("href", "data:text/csv;charset=utf-8," + encodedUri);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
  }

  const remove = () => {
    setShowDeleteDialog(true);
  }

  const itemDelete = () => {
    showLoading(true);
    callFunction({ funcName: 'deleteMessages', params: { ids: selected } })
    .then(() => {
      enqueueSnackbar(t('support.deleteSuccess'), { variant: 'success' });
      setShowDeleteDialog(false);
      setSelected([]);
      fetch();
    })
    .catch(() => enqueueSnackbar(t('support.deleteError'), { variant: 'error' }))
    .finally(() => showLoading(false));
  }

  const search = () => {
    const newPage = 0;
    setPage(newPage);
    fetch({ newPage });
  }

  const selectAll = (e) => {
    if (e.target.checked) setSelected(items.map((el) => el.id));
    else setSelected([]);
  }

  const selectItem = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) newSelected = newSelected.concat(selected, id);
    else if (selectedIndex === 0) newSelected = newSelected.concat(selected.slice(1));
    else if (selectedIndex === selected.length - 1) newSelected = newSelected.concat(selected.slice(0, -1));
    else if (selectedIndex > 0) newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    setSelected(newSelected);
  }

  const changePage = (e, newPage) => {
    setPage(newPage);
    fetch({ newPage });
  }

  const changeRowsPerPage = (e) => {
    const newPage = 0;
    const newRowsPerPage = parseInt(e.target.value);
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
    fetch({ newPage, newRowsPerPage });
  }

  const fetch = ({ newPage, newRowsPerPage } = {}) => {
    const usedPage = newPage !== undefined ? newPage : page;
    const usedRowsPerPage = newRowsPerPage !== undefined ? newRowsPerPage : rowsPerPage;
    setLoading(true);
    setError(false);
    get({ collection: collections.Message, withCount: true, queryParams: [
      { descending: 'createdAt' },
      { limit: usedRowsPerPage },
      { skip: usedPage * usedRowsPerPage },
      searchText ? { [filter === 'fullname' ? 'fullText' : 'startsWith']: { key: filter, value: searchText } } : {}
    ]})
    .then((data) => {
      setCount(data.count);
      setItems(data.results.map((item) => { return {
        id: item.objectId, senderId: item.user.objectId, senderName: item.fullname, email: item.email, phone: item.phone,
        dateSent: (new Date(item.createdAt)).toLocaleDateString(), message: item.message
      }}));
    })
    .catch(() => setError(true))
    .finally(() => setLoading(false));
  }
  //Effect for fetching data
  useEffect(fetch, []);
  //Render page
  return <Paper elevation={1}>
    <Box display="flex" flexDirection="column" alignItems="center" paddingX={theme.spacing(0.5)}>
      {/*----Title----*/}
      <Box mt={theme.spacing(0.2)}/>
      <Typography variant="h6"><b>{t('menu.support')}</b></Typography>
      <Box mt={theme.spacing(0.2)}/>
      {/*----Content----*/}
      <Toolbar style={{ width: '100%', overflowX: 'auto', overflowY: 'hidden',
        backgroundColor: selected.length > 0 ? theme.palette.primary.main + '20' : 'transparent' }}>
        {selected.length > 0 ? <>
          <Typography style={{ flex: 1 }} color="inherit" variant="subtitle1">{selected.length + ' ' + t('common.selected')}</Typography>
          <Tooltip title={t('common.export')}><IconButton onClick={exportItems}><GetApp color="primary"/></IconButton></Tooltip>
          <Tooltip title={t('common.delete')}><IconButton onClick={remove}><Delete color="error"/></IconButton></Tooltip>
        </> : <>
          <OutlinedInput placeholder={t('common.search')} style={{ minWidth: 200, height: 38 }}
            value={searchText} onChange={(e) => setSearchText(e.target.value)} onKeyUp={(e) => { if (e.key === 'Enter') search() }}
            endAdornment={<InputAdornment position="end">
              <IconButton size="small" onClick={search}><Search color="primary"/></IconButton>
            </InputAdornment>}
          />
          <Box mr={theme.spacing(0.2)}/>
          <Select variant="outlined" style={{ height: 38 }} MenuProps={{ elevation: 2, dir }} value={filter} onChange={(e) => setFilter(e.target.value)}>
            {filters.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
          </Select>
        </>}
      </Toolbar>
      <TableContainer>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox color="primary" indeterminate={selected.length > 0 && selected.length < items.length}
                  checked={items.length > 0 && selected.length === items.length} onChange={selectAll}/>
              </TableCell>
              {headers.map((item) => (
                <TableCell key={item.id} width={item.width} align={item.align} padding={item.disablePadding ? 'none' : 'default'}>
                  <b>{item.label}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/*----Success----*/}
            {!loading && !error && !empty && items.map((item) => {
              const isItemSelected = selected.indexOf(item.id) !== -1;
              return <TableRow hover key={item.id} selected={isItemSelected} onClick={(e) => selectItem(item.id)}>
                <TableCell padding="checkbox"><Checkbox color="primary" checked={isItemSelected}/></TableCell>
                <TableCell align={align} padding="none">{item.id}</TableCell>
                <TableCell align={align}>{item.senderName}<br/><b>{'#' + item.senderId}</b></TableCell>
                <TableCell align={align}>{item.email}</TableCell>
                <TableCell align={align}>{item.phone}</TableCell>
                <TableCell align={align}>{item.dateSent}</TableCell>
                <TableCell align={align}>{item.message}</TableCell>
              </TableRow>;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/*----Delete confirmation dialog----*/}
      <Dialog dir={dir} open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('support.dialog_delete')}</DialogTitle>
        <DialogContent><DialogContentText>{t('common.confirmMsg')}</DialogContentText></DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={itemDelete} color="primary">{t('common.delete')}</Button>
        </DialogActions>
      </Dialog>
      {/*----Loading----*/}
      {loading && <>
        <Box mt={theme.spacing(0.8)}/>
        <CircularProgress />
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Error----*/}
      {!loading && error && <>
        <Box mt={theme.spacing(0.6)}/>
        <Error color="error" style={{ width: theme.spacing(12), height: theme.spacing(12) }}/>
        <Typography color="textSecondary">{t('common.problemMsg')}</Typography>
        <Box mt={theme.spacing(0.2)}/>
        <Button variant="contained" color="primary" style={{ paddingInline: theme.spacing(3), height: 35 }} onClick={fetch}>
          {t('common.tryAgain')}
        </Button>
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Empty----*/}
      {!loading && !error && empty && <>
        <Box mt={theme.spacing(0.4)}/>
        <Typography color="textSecondary">{t('support.emptyMsg')}</Typography>
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Success----*/}
      {!loading && !error && !empty && <>
        <TablePagination rowsPerPageOptions={fetchCountOptions} rowsPerPage={rowsPerPage} page={page} count={count}
          onChangePage={changePage} onChangeRowsPerPage={changeRowsPerPage} labelRowsPerPage={t('common.rowsPerPage')}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('common.of')} ${count}`}
          SelectProps={{ MenuProps: { elevation: 2 } }} component="div" style={{ alignSelf: 'flex-end' }}/>
        <Box mt={theme.spacing(0.4)}/>
      </>}
    </Box>
  </Paper>;
}

export default Support;
