import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme, Paper, Box, Typography, CircularProgress, Button, IconButton, Checkbox, Toolbar, Tooltip, TableContainer, Table,
  TableHead, TableBody, TableCell, TableRow, TablePagination, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText,
  OutlinedInput } from '@material-ui/core';
import { Error, Add, Edit, Delete, GetApp } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { get, post, put, del, postFile, delFile } from '../../api';
import { ImageUploadResize, useLoading } from '../../utils';
import { getDir, getRTL, languages } from '../../localization';
import { collections, fetchCountOptions, fetchCount } from '../../configs';

const Categories = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const showLoading = useLoading();
  //Used states
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(false);
  const [ items, setItems ] = useState([]);
  const [ count, setCount ] = useState(0);
  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(fetchCount);
  const [ selected, setSelected ] = useState([]);
  const [ showDeleteDialog, setShowDeleteDialog ] = useState(false);
  const [ showActionDialog, setShowActionDialog ] = useState(false);
  const [ action, setAction ] = useState(null);
  const [ id, setId ] = useState('');
  const [ name, setName ] = useState('');
  const [ translations, setTranslations ] = useState({});
  const [ picture, setPicture ] = useState(null);
  const [ pictureResponse, setPictureResponse ] = useState(null);
  //Used attributes
  const empty = count === 0;
  const dir = getDir();
  const align = !getRTL() ? 'left' : 'right';
  const headers = [
    { id: 'id', label: t('categories.id'), width: '30%', align, disablePadding: true },
    { id: 'name', label: t('categories.name'), width: '30%', align, disablePadding: false },
    { id: 'dateCreated', label: t('categories.dateCreated'), width: '30%', align, disablePadding: false },
    { id: 'picture', label: t('categories.picture'), width: '10%', align, disablePadding: false }
  ];
  //Methods
  const exportItems = () => {
    const header = headers.map((el) => el.label).join(',');
    const selectedItems = items.filter((item) => selected.includes(item.id))
    .map((item) => item.id + ',' + item.name + ',' + item.dateCreated + ',/');
    const data = header + '\n' + selectedItems.join('\n');
    var encodedUri = encodeURI(data);
    var link = document.createElement("a");
    link.setAttribute("href", "data:text/csv;charset=utf-8," + encodedUri);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
  }

  const add = () => {
    setAction('add');
    setShowActionDialog(true);
    setId('');
    setName('');
    setTranslations({});
    setPicture(null);
    setPictureResponse(null);
  }

  const edit = () => {
    if (selected.length > 1) {
      enqueueSnackbar(t('categories.oneOnly'), { variant: 'error' });
      return;
    }
    setAction('edit');
    setShowActionDialog(true);
    const item = items.find((el) => el.id === selected[0]);
    setId(item.id);
    setName(item.name);
    setTranslations(item.translations);
    setPicture(item.picture);
    setPictureResponse(null);
  }

  const remove = () => {
    setShowDeleteDialog(true);
  }

  const itemAction = () => {
    if (!name || Object.keys(translations).length !== Object.keys(languages).length || (action === 'add' && !pictureResponse)) {
      enqueueSnackbar(t('common.inputError'), { variant: 'error' });
      return;
    }
    showLoading(true);
    const params = { name, translations };
    const promise = pictureResponse ? (
      postFile({ filename: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 6) + '.' + pictureResponse.type, base64Data: pictureResponse.base64Data })
    ) : Promise.resolve();
    promise.then((file) => {
      if (file) {
        params.picture = file;
        if (picture) delFile({ filename: picture.name }).catch(() => {});
      }
      if (action === 'add') return post({ collection: collections.Category, noACL: true, params });
      else if (action === 'edit') return put({ collection: collections.Category, queryParams: [{ equalTo: { key: 'objectId', value: id } }], params });
    }).then(() => {
      if (action === 'add') {
        enqueueSnackbar(t('categories.addSuccess'), { variant: 'success' });
        setShowActionDialog(false);
        const newPage = 0;
        setPage(newPage);
        fetch({ newPage });
      } else if (action === 'edit') {
        enqueueSnackbar(t('categories.editSuccess'), { variant: 'success' });
        setShowActionDialog(false);
        fetch();
      }
    })
    .catch(() => {
      if (action === 'add') enqueueSnackbar(t('categories.addError'), { variant: 'error' });
      else if (action === 'edit') enqueueSnackbar(t('categories.editError'), { variant: 'error' });
    })
    .finally(() => showLoading(false));
  }

  const itemDelete = async () => {
    try {
      showLoading(true);
      for (const id of selected) {
        await del({ collection: collections.Category, queryParams: [{ equalTo: { key: 'objectId', value: id } }] });
        const item = items.find((el) => el.id === id);
        if (item.picture) delFile({ filename: item.picture.name }).catch(() => {});
      }
      enqueueSnackbar(t('categories.deleteSuccess'), { variant: 'success' });
      setShowDeleteDialog(false);
      setSelected([]);
      fetch();
    } catch {
      enqueueSnackbar(t('categories.deleteError'), { variant: 'error' });
    } finally {
      showLoading(false);
    }
  }

  const selectAll = (e) => {
    if (e.target.checked) setSelected(items.map((el) => el.id));
    else setSelected([]);
  }

  const selectItem = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) newSelected = newSelected.concat(selected, id);
    else if (selectedIndex === 0) newSelected = newSelected.concat(selected.slice(1));
    else if (selectedIndex === selected.length - 1) newSelected = newSelected.concat(selected.slice(0, -1));
    else if (selectedIndex > 0) newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    setSelected(newSelected);
  }

  const changePage = (e, newPage) => {
    setPage(newPage);
    fetch({ newPage });
  }

  const changeRowsPerPage = (e) => {
    const newPage = 0;
    const newRowsPerPage = parseInt(e.target.value);
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
    fetch({ newPage, newRowsPerPage });
  }

  const fetch = ({ newPage, newRowsPerPage } = {}) => {
    const usedPage = newPage !== undefined ? newPage : page;
    const usedRowsPerPage = newRowsPerPage !== undefined ? newRowsPerPage : rowsPerPage;
    setLoading(true);
    setError(false);
    get({ collection: collections.Category, withCount: true, queryParams: [
      { descending: 'updatedAt' },
      { limit: usedRowsPerPage },
      { skip: usedPage * usedRowsPerPage },
    ]})
    .then((data) => {
      setCount(data.count);
      setItems(data.results.map((item) => { return {
        id: item.objectId, name: item.name, translations: item.translations, picture: item.picture,
        dateCreated: (new Date(item.createdAt)).toLocaleDateString()
      }}));
    })
    .catch(() => setError(true))
    .finally(() => setLoading(false));
  }
  //Effect for fetching data
  useEffect(fetch, []);
  //Render page
  return <Paper elevation={1}>
    <Box display="flex" flexDirection="column" alignItems="center" paddingX={theme.spacing(0.5)}>
      {/*----Title----*/}
      <Box mt={theme.spacing(0.2)}/>
      <Typography variant="h6"><b>{t('menu.categories')}</b></Typography>
      <Box mt={theme.spacing(0.2)}/>
      {/*----Content----*/}
      <Toolbar style={{ width: '100%', overflowX: 'auto', overflowY: 'hidden',
        backgroundColor: selected.length > 0 ? theme.palette.primary.main + '20' : 'transparent' }}>
        {selected.length > 0 ? <>
          <Typography style={{ flex: 1 }} color="inherit" variant="subtitle1">{selected.length + ' ' + t('common.selected')}</Typography>
          <Tooltip title={t('common.export')}><IconButton onClick={exportItems}><GetApp color="primary"/></IconButton></Tooltip>
          <Tooltip title={t('common.delete')}><IconButton onClick={remove}><Delete color="error"/></IconButton></Tooltip>
          <Tooltip title={t('common.edit')}><IconButton onClick={edit}><Edit color="action"/></IconButton></Tooltip>
        </> : <>
          <Box flex={1}/>
          <Tooltip title={t('common.add')}><IconButton onClick={add}><Add color="primary"/></IconButton></Tooltip>
        </>}
      </Toolbar>
      <TableContainer>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox color="primary" indeterminate={selected.length > 0 && selected.length < items.length}
                  checked={items.length > 0 && selected.length === items.length} onChange={selectAll}/>
              </TableCell>
              {headers.map((item) => (
                <TableCell key={item.id} width={item.width} align={item.align} padding={item.disablePadding ? 'none' : 'default'}>
                  <b>{item.label}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/*----Success----*/}
            {!loading && !error && !empty && items.map((item) => {
              const isItemSelected = selected.indexOf(item.id) !== -1;
              return <TableRow hover key={item.id} selected={isItemSelected} onClick={(e) => selectItem(item.id)}>
                <TableCell padding="checkbox"><Checkbox color="primary" checked={isItemSelected}/></TableCell>
                <TableCell align={align} padding="none">{item.id}</TableCell>
                <TableCell align={align}>{item.name}</TableCell>
                <TableCell align={align}>{item.dateCreated}</TableCell>
                <TableCell align={align}>{item.picture ? (
                  <a target="_blank" rel="noopener noreferrer" href={item.picture.url}>
                    <img alt={item.picture.name} src={item.picture.url} width='100%'></img>
                  </a>
                ) : ''}</TableCell>
              </TableRow>;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/*----Delete confirmation dialog----*/}
      <Dialog dir={dir} open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('categories.dialog_delete')}</DialogTitle>
        <DialogContent><DialogContentText>{t('common.confirmMsg')}</DialogContentText></DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={itemDelete} color="primary">{t('common.delete')}</Button>
        </DialogActions>
      </Dialog>
      {/*----Add/Edit dialog----*/}
      <Dialog dir={dir} open={showActionDialog} onClose={() => setShowActionDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('categories.dialog_' + action)}</DialogTitle>
        <DialogContent>
          <OutlinedInput placeholder={t('categories.name')} autoFocus fullWidth value={name} onChange={(e) => setName(e.target.value)}/>
          <Box mt={theme.spacing(0.15)}/>
          <Button color="primary" variant="contained"
            onClick={() => ImageUploadResize({ noResize: true }).then((imageData) => setPictureResponse(imageData)).catch(() => {})}>
            {picture || pictureResponse ? t('categories.changePicture') : t('categories.setPicture')}
          </Button>
          <Box mt={theme.spacing(0.25)}/>
          <Typography><b>{t('categories.translations')}</b></Typography>
          <Box paddingX={theme.spacing(0.5)}>
            {Object.keys(languages).map((item) => <Box key={item} mt={theme.spacing(0.15)} display="flex" flexDirection="row" alignItems="center">
              <Typography style={{ width: 25 }}>{item}</Typography>
              <Box mr={theme.spacing(0.15)}/>
              <OutlinedInput defaultValue={translations[item]} fullWidth onChange={(e) => setTranslations({ ...translations, [item]: e.target.value })}/>
            </Box>)}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowActionDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={itemAction} color="primary">{t('common.' + action)}</Button>
        </DialogActions>
      </Dialog>
      {/*----Loading----*/}
      {loading && <>
        <Box mt={theme.spacing(0.8)}/>
        <CircularProgress />
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Error----*/}
      {!loading && error && <>
        <Box mt={theme.spacing(0.6)}/>
        <Error color="error" style={{ width: theme.spacing(12), height: theme.spacing(12) }}/>
        <Typography color="textSecondary">{t('common.problemMsg')}</Typography>
        <Box mt={theme.spacing(0.2)}/>
        <Button variant="contained" color="primary" style={{ paddingInline: theme.spacing(3), height: 35 }} onClick={fetch}>
          {t('common.tryAgain')}
        </Button>
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Empty----*/}
      {!loading && !error && empty && <>
        <Box mt={theme.spacing(0.4)}/>
        <Typography color="textSecondary">{t('categories.emptyMsg')}</Typography>
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Success----*/}
      {!loading && !error && !empty && <>
        <TablePagination rowsPerPageOptions={fetchCountOptions} rowsPerPage={rowsPerPage} page={page} count={count}
          onChangePage={changePage} onChangeRowsPerPage={changeRowsPerPage} labelRowsPerPage={t('common.rowsPerPage')}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('common.of')} ${count}`}
          SelectProps={{ MenuProps: { elevation: 2 } }} component="div" style={{ alignSelf: 'flex-end' }}/>
        <Box mt={theme.spacing(0.4)}/>
      </>}
    </Box>
  </Paper>;
}

export default Categories;
