import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import { useTheme, Paper, Box, Typography, CircularProgress, Button, IconButton, Checkbox, Toolbar, Tooltip, TableContainer, Table,
  TableHead, TableBody, TableCell, TableRow, TablePagination, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText,
  OutlinedInput, FormControlLabel, MenuItem, Select, InputAdornment } from '@material-ui/core';
import { Error, Add, Edit, Delete, Search, ArrowForward, ArrowBack, Block, Check, Person, Grade, GetApp, Pause, PlayArrow } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { get, post, put, callFunction, postFile, delFile, getGeoPoint, getPointerFromId } from '../../api';
import { ImageUploadResize, useLoading, Map, Marker } from '../../utils';
import { getDir, getRTL } from '../../localization';
import { collections, fetchCountOptions, fetchCount } from '../../configs';

const Stores = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector(state => state);
  const showLoading = useLoading();
  const history = useHistory();
  //Used states
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(false);
  const [ items, setItems ] = useState([]);
  const [ count, setCount ] = useState(0);
  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(fetchCount);
  const [ selected, setSelected ] = useState([]);
  const [ filter, setFilter ] = useState('objectId');
  const [ searchText, setSearchText ] = useState('');
  const [ cityFilter, setCityFilter ] = useState('/');
  const [ categoryFilter, setCategoryFilter ] = useState('/');
  const [ disabledFilter, setDisabledFilter ] = useState(false);
  const [ showDeleteDialog, setShowDeleteDialog ] = useState(false);
  const [ showEnableToggleDialog, setShowEnableToggleDialog ] = useState(false);
  const [ showLocationMapDialog, setShowLocationMapDialog ] = useState(false);
  const [ showAssignManagerDialog, setShowAssignManagerDialog ] = useState(false);
  const [ showActionDialog, setShowActionDialog ] = useState(false);
  const [ action, setAction ] = useState(null);
  const [ cities, setCities ] = useState([]);
  const [ appCategories, setAppCategories ] = useState([]);
  const [ id, setId ] = useState('');
  const [ name, setName ] = useState('');
  const [ description, setDescription ] = useState('');
  const [ address, setAddress ] = useState('');
  const [ phone, setPhone ] = useState('');
  const [ picture, setPicture ] = useState(null);
  const [ pictureResponse, setPictureResponse ] = useState(null);
  const [ location, setLocation ] = useState(null);
  const [ openTime, setOpenTime ] = useState({ h: 0, mn: 0 });
  const [ closeTime, setCloseTime ] = useState({ h: 0, mn: 0 });
  const [ pauseStart, setPauseStart ] = useState({ h: 0, mn: 0 });
  const [ pauseEnd, setPauseEnd ] = useState({ h: 0, mn: 0 });
  const [ workingDays, setWorkingDays ] = useState([]);
  const [ isFeatured, setIsFeatured ] = useState(false);
  const [ active, setActive ] = useState(true);
  const [ enabled, setEnabled ] = useState(true);
  const [ fee, setFee ] = useState('');
  const [ categories, setCategories ] = useState([]);
  const [ city, setCity ] = useState('');
  const [ oldcity, setOldCity ] = useState('');
  const [ zoom, setZoom ] = useState(14);
  const [ center, setCenter ] = useState({ lat: 0, lng: 0 });
  const [ managerId, setManagerId ] = useState('');
  //Used attributes
  const mapRef = useRef(null);
  const empty = count === 0;
  const dir = getDir();
  const align = !getRTL() ? 'left' : 'right';
  const headers = [
    { id: 'id', label: t('stores.id'), width: '10%', align, disablePadding: true },
    { id: 'picture', label: t('stores.picture'), width: '10%', align, disablePadding: false },
    { id: 'name', label: t('stores.name'), width: '10%', align, disablePadding: false },
    { id: 'description', label: t('stores.description'), width: '10%', align, disablePadding: false },
    { id: 'address', label: t('stores.address'), width: '10%', align, disablePadding: false },
    { id: 'phone', label: t('stores.phone'), width: '10%', align, disablePadding: false },
    { id: 'status', label: t('stores.status'), width: '10%', align, disablePadding: false },
    { id: 'manager', label: t('stores.manager'), width: '10%', align, disablePadding: false },
    { id: 'data', label: t('stores.data'), width: '10%', align, disablePadding: false },
    { id: 'dateCreated', label: t('stores.dateCreated'), width: '10%', align, disablePadding: false }
  ];
  const filters = [
    { value: 'objectId', label: t('stores.id') },
    { value: 'name', label: t('stores.name') },
    { value: 'manager', label: t('stores.managerId') }
  ];
  //Methods
  const exportItems = () => {
    const header = headers.map((el) => el.label).join(',');
    const selectedItems = items.filter((item) => selected.includes(item.id))
    .map((item) => item.id + ',/,' + item.name + ',' + (item.description ? item.description.replaceAll(',', '') : '/') + ',' + item.address +
      ',' + item.phone + ',' + (item.enabled ? t('stores.enabled') : t('stores.disabled')) +
      ',' + (item.data.manager ? item.data.manager.fullname : '/') + ',/,' + item.dateCreated);
    const data = header + '\n' + selectedItems.join('\n');
    var encodedUri = encodeURI(data);
    var link = document.createElement("a");
    link.setAttribute("href", "data:text/csv;charset=utf-8," + encodedUri);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
  }

  const add = () => {
    setAction('add');
    setShowActionDialog(true);
    setId('');
    setName('');
    setDescription('');
    setAddress('');
    setPhone('');
    setPicture(null);
    setPictureResponse(null);
    setLocation(null);
    setOpenTime({ h: 0, mn: 0 });
    setCloseTime({ h: 0, mn: 0 });
    setPauseStart({ h: 0, mn: 0 });
    setPauseEnd({ h: 0, mn: 0 });
    setWorkingDays([]);
    setIsFeatured(false);
    setActive(true);
    setEnabled(true);
    setFee('');
    setCategories([]);
    setCity(cities.length > 0 ? cities[0].objectId : '');
    setOldCity('');
  }

  const edit = () => {
    if (selected.length > 1) {
      enqueueSnackbar(t('stores.oneOnly'), { variant: 'error' });
      return;
    }
    setAction('edit');
    setShowActionDialog(true);
    const item = items.find((el) => el.id === selected[0]);
    setId(item.id);
    setName(item.name);
    setDescription(item.description);
    setAddress(item.address);
    setPhone(item.phone);
    setPicture(item.picture);
    setPictureResponse(null);
    setLocation(item.data.location);
    setOpenTime(item.data.openTime || { h: 0, mn: 0 });
    setCloseTime(item.data.closeTime || { h: 0, mn: 0 });
    setPauseStart(item.data.pauseStart || { h: 0, mn: 0 });
    setPauseEnd(item.data.pauseEnd || { h: 0, mn: 0 });
    setWorkingDays(item.data.workingDays || []);
    setIsFeatured(item.data.isFeatured);
    setActive(item.data.active);
    setEnabled(item.enabled);
    setFee(item.data.fee);
    setCategories(item.data.categories.map((item) => item.objectId));
    setCity(item.data.city ? item.data.city.objectId : '');
    setOldCity(item.data.city ? item.data.city.objectId : '');
  }

  const remove = () => {
    setShowDeleteDialog(true);
  }

  const enableToggle = () => {
    setShowEnableToggleDialog(true);
  }

  const assignManager = () => {
    if (selected.length > 1) {
      enqueueSnackbar(t('stores.oneOnly'), { variant: 'error' });
      return;
    }
    setShowAssignManagerDialog(true);
    const item = items.find((el) => el.id === selected[0]);
    setId(item.id);
    setManagerId('');
  }

  const locationMap = () => {
    const item = selected.length > 0 ? items.find((el) => el.id === selected[0]) : null;
    setCenter(item ? { lat: item.data.location.latitude, lng: item.data.location.longitude } : { lat: 0, lng: 0 });
    setShowLocationMapDialog(true);
  }

  const showReviews = () => {
    if (selected.length > 1) {
      enqueueSnackbar(t('stores.oneOnly'), { variant: 'error' });
      return;
    }
    const item = items.find((el) => el.id === selected[0]);
    const navName = window.btoa(unescape(encodeURIComponent(item.name))).replace('/', '***');
    history.push('/reviews/' + item.id + '/' + navName);
  }

  const itemAction = () => {
    if (!name || !address || !phone || !location || !fee || !city || workingDays.length === 0 || categories.length === 0) {
      enqueueSnackbar(t('common.inputError'), { variant: 'error' });
      return;
    } else if (openTime.h === closeTime.h && openTime.mn === closeTime.mn) {
      enqueueSnackbar(t('stores.invalidTimeError'), { variant: 'error' });
      return;
    }
    showLoading(true);
    const params = { name, searchName: name.toLowerCase(), description, address, phone, openTime, closeTime, workingDays, active,
      enabled, isFeatured, fee: parseFloat(fee), location: getGeoPoint({ latitude: location.latitude, longitude: location.longitude }),
      city: getPointerFromId({ collection: collections.City, objectId: city }),
      categories: categories.map((objectId) => getPointerFromId({ collection: collections.Category, objectId })) };
    if (pauseStart.h !== pauseEnd.h || pauseStart.mn !== pauseEnd.mn) {
      params.pauseStart = pauseStart;
      params.pauseEnd = pauseEnd;
    } else {
      params.pauseStart = null;
      params.pauseEnd = null;
    }
    const promise = pictureResponse ? (
      postFile({ filename: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 6) + '.' + pictureResponse.type, base64Data: pictureResponse.base64Data })
    ) : Promise.resolve();
    promise.then((file) => {
      if (file) {
        params.picture = file;
        if (picture) delFile({ filename: picture.name }).catch(() => {});
      }
      if (action === 'add') return post({ collection: collections.Restaurant, isPublic: true, isRole: true, params });
      else if (action === 'edit') return put({ collection: collections.Restaurant, queryParams: [{ equalTo: { key: 'objectId', value: id } }], params });
    }).then(() => {
      if (action === 'edit' && city !== oldcity) return callFunction({ funcName: 'changeRegion', params: {
        cityId: city, storeId: id
      }});
    }).then(() => {
      if (action === 'edit' && pictureResponse) return callFunction({ funcName: 'assignStoreFile', params: {
        filename: params.picture.name(), storeId: id
      }});
    }).then(() => {
      if (action === 'add') {
        enqueueSnackbar(t('stores.addSuccess'), { variant: 'success' });
        setShowActionDialog(false);
        const newPage = 0;
        setPage(newPage);
        fetch({ newPage });
      } else if (action === 'edit') {
        enqueueSnackbar(t('stores.editSuccess'), { variant: 'success' });
        setShowActionDialog(false);
        fetch();
      }
    })
    .catch(() => {
      if (action === 'add') enqueueSnackbar(t('stores.addError'), { variant: 'error' });
      else if (action === 'edit') enqueueSnackbar(t('stores.editError'), { variant: 'error' });
    })
    .finally(() => showLoading(false));
  }

  const itemDelete = async () => {
    showLoading(true);
    callFunction({ funcName: 'deleteStores', params: { ids: selected } })
    .then(() => {
      enqueueSnackbar(t('stores.deleteSuccess'), { variant: 'success' });
      setShowDeleteDialog(false);
      setSelected([]);
      fetch();
    })
    .catch(() => enqueueSnackbar(t('stores.deleteError'), { variant: 'error' }))
    .finally(() => showLoading(false));
  }

  const itemToggleEnable = () => {
    showLoading(true);
    callFunction({ funcName: 'toggleEnableStores', params: { ids: selected } })
    .then(() => {
      enqueueSnackbar(t('stores.enableSuccess'), { variant: 'success' });
      setShowEnableToggleDialog(false);
      fetch();
    })
    .catch(() => enqueueSnackbar(t('stores.enableError'), { variant: 'error' }))
    .finally(() => showLoading(false));
  }

  const itemAssignManager = () => {
    showLoading(true);
    callFunction({ funcName: 'assignManager', params: { storeId: id, managerId: managerId ? managerId : undefined } })
    .then(() => {
      enqueueSnackbar(t('stores.assignSuccess'), { variant: 'success' });
      setShowAssignManagerDialog(false);
      fetch();
    })
    .catch(() => enqueueSnackbar(t('stores.assignError'), { variant: 'error' }))
    .finally(() => showLoading(false));
  }

  const itemChangeLocation = () => {
    if (center.lat === 0 && center.lng === 0) {
      enqueueSnackbar(t('stores.locationError'), { variant: 'error' });
      return;
    }
    setLocation({ latitude: center.lat(), longitude: center.lng() });
    setShowLocationMapDialog(false);
  }

  const changeState = (active) => {
    if (active === undefined) return;
    if (window.confirm(t('common.confirmMsg'))) {
      showLoading(true);
      let promise = Promise.resolve();
      selected.forEach((id) => {
        promise = promise.then(() => {
          return put({ collection: collections.Restaurant, queryParams: [{ equalTo: { key: 'objectId', value: id } }], params: { active } });
        });
      });
      promise.then(() => {
        enqueueSnackbar(t('stores.editSuccess'), { variant: 'success' });
        fetch();
      })
      .catch(() => enqueueSnackbar(t('stores.editError'), { variant: 'error' }))
      .finally(() => showLoading(false));
    }
  }

  const search = ({ newCityFilter, newCategoryFilter, newDisabledFilter } = {}) => {
    const newPage = 0;
    setPage(newPage);
    if (newCityFilter !== undefined) setCityFilter(newCityFilter);
    if (newCategoryFilter !== undefined) setCategoryFilter(newCategoryFilter);
    if (newDisabledFilter !== undefined) setDisabledFilter(newDisabledFilter);
    fetch({ newPage, newCityFilter, newCategoryFilter, newDisabledFilter });
  }

  const selectAll = (e) => {
    if (e.target.checked) setSelected(items.map((el) => el.id));
    else setSelected([]);
  }

  const selectItem = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) newSelected = newSelected.concat(selected, id);
    else if (selectedIndex === 0) newSelected = newSelected.concat(selected.slice(1));
    else if (selectedIndex === selected.length - 1) newSelected = newSelected.concat(selected.slice(0, -1));
    else if (selectedIndex > 0) newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    setSelected(newSelected);
  }

  const changePage = (e, newPage) => {
    setPage(newPage);
    fetch({ newPage });
  }

  const changeRowsPerPage = (e) => {
    const newPage = 0;
    const newRowsPerPage = parseInt(e.target.value);
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
    fetch({ newPage, newRowsPerPage });
  }

  const fetch = ({ newPage, newRowsPerPage, newCityFilter, newCategoryFilter, newDisabledFilter } = {}) => {
    const usedPage = newPage !== undefined ? newPage : page;
    const usedRowsPerPage = newRowsPerPage !== undefined ? newRowsPerPage : rowsPerPage;
    const usedCityFilter = newCityFilter !== undefined ? newCityFilter : cityFilter;
    const usedCategoryFilter = newCategoryFilter !== undefined ? newCategoryFilter : categoryFilter;
    const usedDisabledFilter = newDisabledFilter !== undefined ? newDisabledFilter : disabledFilter;
    setLoading(true);
    setError(false);
    get({ collection: collections.Restaurant, withCount: true, queryParams: [
      { descending: 'createdAt' },
      { limit: usedRowsPerPage },
      { skip: usedPage * usedRowsPerPage },
      { include: 'city' },
      { include: 'manager' },
      { include: 'categories' },
      usedDisabledFilter ? { equalTo: { key: 'enabled', value: false } } : {},
      usedCityFilter === '/' ? {} : { equalTo: { key: 'city', value: getPointerFromId({ collection: collections.City, objectId: usedCityFilter }) } },
      usedCategoryFilter === '/' ? {} : { equalTo: { key: 'categories', value: getPointerFromId({ collection: collections.Category, objectId: usedCategoryFilter }) } },
      searchText ? filter === 'manager' ? {
        equalTo: { key: 'manager', value: getPointerFromId({ collection: collections.User, objectId: searchText }) }
      } : { [filter === 'name' ? 'fullText' : 'startsWith']: { key: filter, value: searchText } } : {}
    ]})
    .then((data) => {
      setCount(data.count);
      setItems(data.results.map((item) => { return {
        id: item.objectId, name: item.name, description: item.description, address: item.address, phone: item.phone, picture: item.picture,
        enabled: item.enabled, dateCreated: (new Date(item.createdAt)).toLocaleDateString(), data: item
      }}));
    })
    .catch(() => setError(true))
    .finally(() => setLoading(false));
  }

  const initFetch = () => {
    //Fetch regions
    get({ collection: collections.City, queryParams: [ { descending: 'createdAt' } ]})
    .then((data) => setCities(data)).catch(() => {});
    //Fetch categories
    get({ collection: collections.Category, queryParams: [ { descending: 'createdAt' } ]})
    .then((data) => setAppCategories(data)).catch(() => {});
    //Fetch items
    fetch();
  }
  //Effect for fetching data
  useEffect(initFetch, []);
  //Render page
  return <Paper elevation={1}>
    <Box display="flex" flexDirection="column" alignItems="center" paddingX={theme.spacing(0.5)}>
      {/*----Title----*/}
      <Box mt={theme.spacing(0.2)}/>
      <Typography variant="h6"><b>{t('menu.stores')}</b></Typography>
      <Box mt={theme.spacing(0.2)}/>
      {/*----Content----*/}
      <Toolbar style={{ width: '100%', overflowX: 'auto', overflowY: 'hidden',
        backgroundColor: selected.length > 0 ? theme.palette.primary.main + '20' : 'transparent' }}>
        {selected.length > 0 ? <>
          <Typography style={{ flex: 1 }} color="inherit" variant="subtitle1">{selected.length + ' ' + t('common.selected')}</Typography>
          <Tooltip title={t('common.export')}><IconButton onClick={exportItems}><GetApp color="primary"/></IconButton></Tooltip>
          <Tooltip title={t('stores.showReviews')}><IconButton onClick={showReviews}><Grade color="primary"/></IconButton></Tooltip>
          <Tooltip title={t('stores.assignManager')}><IconButton onClick={assignManager}><Person color="secondary"/></IconButton></Tooltip>
          <Tooltip title={t('stores.setActive')}><IconButton onClick={() => changeState(true)}><PlayArrow color="action"/></IconButton></Tooltip>
          <Tooltip title={t('stores.setPause')}><IconButton onClick={() => changeState(false)}><Pause color="error"/></IconButton></Tooltip>
          <Tooltip title={t('stores.enableDisable')}><IconButton onClick={enableToggle}><Block color="action"/></IconButton></Tooltip>
          <Tooltip title={t('common.delete')}><IconButton onClick={remove}><Delete color="error"/></IconButton></Tooltip>
          <Tooltip title={t('common.edit')}><IconButton onClick={edit}><Edit color="action"/></IconButton></Tooltip>
        </> : <>
          <OutlinedInput placeholder={t('common.search')} style={{ minWidth: 200, height: 38 }}
            value={searchText} onChange={(e) => setSearchText(e.target.value)} onKeyUp={(e) => { if (e.key === 'Enter') search() }}
            endAdornment={<InputAdornment position="end">
              <IconButton size="small" onClick={search}><Search color="primary"/></IconButton>
            </InputAdornment>}
          />
          <Box mr={theme.spacing(0.2)}/>
          <Select variant="outlined" style={{ height: 38 }} MenuProps={{ elevation: 2, dir }} value={filter} onChange={(e) => setFilter(e.target.value)}>
            {filters.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
          </Select>
          <Box mr={theme.spacing(0.4)}/>
          <Typography>{t('stores.region')}</Typography>
          <Box mr={theme.spacing(0.2)}/>
          <Select variant="outlined" style={{ height: 38 }} MenuProps={{ elevation: 2 }} value={cityFilter}
            onChange={(e) => search({ newCityFilter: e.target.value })}>
            <MenuItem value="/">/</MenuItem>
            {cities.map((item) => <MenuItem key={item.objectId} value={item.objectId}>{item.name}</MenuItem>)}
          </Select>
          <Box mr={theme.spacing(0.4)}/>
          <Typography>{t('stores.category')}</Typography>
          <Box mr={theme.spacing(0.2)}/>
          <Select variant="outlined" style={{ height: 38 }} MenuProps={{ elevation: 2 }} value={categoryFilter}
            onChange={(e) => search({ newCategoryFilter: e.target.value })}>
            <MenuItem value="/">/</MenuItem>
            {appCategories.map((item) => <MenuItem key={item.objectId} value={item.objectId}>{item.translations[user.language]}</MenuItem>)}
          </Select>
          <Box mr={theme.spacing(0.4)}/>
          <FormControlLabel
            control={<Checkbox checked={disabledFilter} onChange={(e) => search({ newDisabledFilter: e.target.checked })} color="primary"/>}
            label={t('stores.showDisabled')}/>
          <Box flex={1}/>
          <Tooltip title={t('common.add')}><IconButton onClick={add}><Add color="primary"/></IconButton></Tooltip>
        </>}
      </Toolbar>
      <TableContainer>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox color="primary" indeterminate={selected.length > 0 && selected.length < items.length}
                  checked={items.length > 0 && selected.length === items.length} onChange={selectAll}/>
              </TableCell>
              {headers.map((item) => (
                <TableCell key={item.id} width={item.width} align={item.align} padding={item.disablePadding ? 'none' : 'default'}>
                  <b>{item.label}</b>
                </TableCell>
              ))}
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {/*----Success----*/}
            {!loading && !error && !empty && items.map((item) => {
              const isItemSelected = selected.indexOf(item.id) !== -1;
              const navName = window.btoa(unescape(encodeURIComponent(item.name))).replace('/', '***');
              return <TableRow hover key={item.id} selected={isItemSelected} onClick={(e) => selectItem(item.id)}>
                <TableCell padding="checkbox"><Checkbox color="primary" checked={isItemSelected}/></TableCell>
                <TableCell align={align} padding="none">{item.id}</TableCell>
                <TableCell align={align}>{item.picture ? (
                  <a target="_blank" rel="noopener noreferrer" href={item.picture.url}>
                    <img alt={item.picture.name} src={item.picture.url} width='100%'></img>
                  </a>
                ) : ''}</TableCell>
                <TableCell align={align}>{item.name}</TableCell>
                <TableCell align={align}>{item.description}</TableCell>
                <TableCell align={align}>{item.address}</TableCell>
                <TableCell align={align}>{item.phone}</TableCell>
                <TableCell align={align}>
                  {item.enabled ? (
                    <Box display="flex" alignItems="center">
                      <Check style={{ color: 'green' }}/>
                      <div>{t('stores.enabled')}</div>
                    </Box>
                  ) : (
                    <Box display="flex" alignItems="center">
                      <Block color="error"/>
                      <div>{t('stores.disabled')}</div>
                    </Box>
                  )}
                </TableCell>
                <TableCell align={align}>{item.data.manager ? item.data.manager.fullname : '/'}</TableCell>
                <TableCell align={align}>
                  {item.data.isFeatured && <><b style={{ color: 'red' }}>{t('stores.featured')}</b><br/></>}
                  <b>{t('stores.region') + ': '}</b><>{item.data.city ? item.data.city.name : ''}</><br/>
                  <b>{t('stores.state') + ': '}</b><>{item.data.active ? (
                    <span style={{ color: 'green' }}>{t('stores.active')}</span>
                  ) : (
                    <span style={{ color: 'red' }}>{t('stores.paused')}</span>
                  )}</><br/>
                  <b>{t('stores.fee') + ': '}</b><>{item.data.fee.toString()}</><br/>
                  <b>{t('stores.rating') + ': '}</b><>{item.data.rating.toString()}</><br/>
                  <b>{t('stores.reviews') + ': '}</b><>{item.data.reviews.toString()}</><br/>
                  <b>{t('stores.totalOrders') + ': '}</b><>{item.data.ordersTotal.toString()}</><br/>
                  <b>{t('stores.acceptedOrders') + ': '}</b><>{item.data.ordersAccepted.toString()}</>
                </TableCell>
                <TableCell align={align}>{item.dateCreated}</TableCell>
                <TableCell>
                  {item.data.city && <Link to={'/lists/' + item.data.city.objectId + '/' + item.id + '/' + navName}>
                    <IconButton>{dir === 'rtl' ? <ArrowBack color="secondary"/> : <ArrowForward color="secondary"/>}</IconButton>  
                  </Link>}
                </TableCell>
              </TableRow>;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/*----Delete confirmation dialog----*/}
      <Dialog dir={dir} open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('stores.dialog_delete')}</DialogTitle>
        <DialogContent><DialogContentText>{t('common.confirmMsg')}</DialogContentText></DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={itemDelete} color="primary">{t('common.delete')}</Button>
        </DialogActions>
      </Dialog>
      {/*----Enable/Disable Toggle confirmation dialog----*/}
      <Dialog dir={dir} open={showEnableToggleDialog} onClose={() => setShowEnableToggleDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('stores.dialog_enableDisable')}</DialogTitle>
        <DialogContent><DialogContentText>{t('common.confirmMsg')}</DialogContentText></DialogContent>
        <DialogActions>
          <Button onClick={() => setShowEnableToggleDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={itemToggleEnable} color="primary">{t('common.ok')}</Button>
        </DialogActions>
      </Dialog>
      {/*----Assign manager dialog----*/}
      <Dialog dir={dir} open={showAssignManagerDialog} onClose={() => setShowAssignManagerDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('stores.dialog_assignManager')}</DialogTitle>
        <DialogContent>
          <OutlinedInput placeholder={t('stores.managerId')} fullWidth value={managerId} onChange={(e) => setManagerId(e.target.value)}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAssignManagerDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={itemAssignManager} color="primary">{t('common.ok')}</Button>
        </DialogActions>
      </Dialog>
      {/*----Location Map dialog----*/}
      <Dialog dir={dir} open={showLocationMapDialog} onClose={() => setShowLocationMapDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('stores.locationMap')}</DialogTitle>
        <DialogContent>
          <Map height={500} mapRef={mapRef} zoom={zoom} center={center}
            onZoomChanged={() => setZoom(mapRef.current.getZoom())} onCenterChanged={() => setCenter(mapRef.current.getCenter())}>
            <Marker position={center}/>
          </Map>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowLocationMapDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={itemChangeLocation} color="primary">{t('common.ok')}</Button>
        </DialogActions>
      </Dialog>
      {/*----Add/Edit dialog----*/}
      <Dialog dir={dir} open={showActionDialog} onClose={() => setShowActionDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('stores.dialog_' + action)}</DialogTitle>
        <DialogContent>
          <OutlinedInput placeholder={t('stores.name')} autoFocus fullWidth value={name} onChange={(e) => setName(e.target.value)}/>
          <Box mt={theme.spacing(0.15)}/>
          <OutlinedInput placeholder={t('stores.description')} fullWidth value={description} onChange={(e) => setDescription(e.target.value)}/>
          <Box mt={theme.spacing(0.15)}/>
          <OutlinedInput placeholder={t('stores.address')} fullWidth value={address} onChange={(e) => setAddress(e.target.value)}/>
          <Box mt={theme.spacing(0.15)}/>
          <OutlinedInput placeholder={t('stores.phone')} fullWidth type="phone" value={phone} onChange={(e) => setPhone(e.target.value)}/>
          <Box mt={theme.spacing(0.15)}/>
          <OutlinedInput placeholder={t('stores.fee')} fullWidth type="number" value={fee} onChange={(e) => setFee(e.target.value)}/>
          <Box mt={theme.spacing(0.15)}/>
          <Button color="primary" variant="contained"
            onClick={() => ImageUploadResize().then((imageData) => setPictureResponse(imageData)).catch(() => {})}>
            {picture || pictureResponse ? t('stores.changePicture') : t('stores.setPicture')}
          </Button>
          <Box mt={theme.spacing(0.15)}/>
          <Button color="primary" variant="contained" onClick={locationMap}>
            {location ? t('stores.changeLocation') : t('stores.setLocation')}
          </Button>
          {action === 'add' && <>
            <Box mt={theme.spacing(0.15)}/>
            <FormControlLabel
              control={<Checkbox color="primary" checked={enabled} onChange={(e) => setEnabled(e.target.checked)}/>}
              label={t('stores.enabled')}
            />
          </>}
          <Box mt={theme.spacing(0.15)}/>
          <FormControlLabel
            control={<Checkbox color="primary" checked={isFeatured} onChange={(e) => setIsFeatured(e.target.checked)}/>}
            label={t('stores.featured')}
          />
          <Box mt={theme.spacing(0.05)}/>
          <FormControlLabel
            control={<Checkbox color="primary" checked={active} onChange={(e) => setActive(e.target.checked)}/>}
            label={t('stores.active')}
          />
          <Box mt={theme.spacing(0.15)}/>
          <Box display="flex" alignItems="center">
            <Typography>{t('stores.workingDays')}</Typography>
            <Box mr={theme.spacing(0.4)}/>
            <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
              {Array.apply(null, Array(7)).map((_, i) => <FormControlLabel key={i} label={t('stores.day' + i.toString())}
                control={<Checkbox color="primary" checked={workingDays.includes(i)} onChange={(e) => {
                  const newWorkingDays = [...workingDays];
                  if (e.target.checked) newWorkingDays.push(i);
                  else newWorkingDays.splice(newWorkingDays.indexOf(i), 1);
                  setWorkingDays(newWorkingDays);
                }}/>}
              />)}
            </Box>
          </Box>
          <Box mt={theme.spacing(0.15)}/>
          <Box display="flex" alignItems="center">
            <Typography>{t('stores.openTime')}</Typography>
            <Box mr={theme.spacing(0.4)}/>
            <OutlinedInput placeholder={t('common.h')} style={{ width: '100px' }} type="number" value={openTime.h} onChange={(e) => setOpenTime({ ...openTime, h: parseInt(e.target.value) })}/>
            <Box mr={theme.spacing(0.4)}/>
            <OutlinedInput placeholder={t('common.mn')} style={{ width: '100px' }} type="number" value={openTime.mn} onChange={(e) => setOpenTime({ ...openTime, mn: parseInt(e.target.value) })}/>
          </Box>
          <Box mt={theme.spacing(0.15)}/>
          <Box display="flex" alignItems="center">
            <Typography>{t('stores.closeTime')}</Typography>
            <Box mr={theme.spacing(0.4)}/>
            <OutlinedInput placeholder={t('common.h')} style={{ width: '100px' }} type="number" value={closeTime.h} onChange={(e) => setCloseTime({ ...closeTime, h: parseInt(e.target.value) })}/>
            <Box mr={theme.spacing(0.4)}/>
            <OutlinedInput placeholder={t('common.mn')} style={{ width: '100px' }} type="number" value={closeTime.mn} onChange={(e) => setCloseTime({ ...closeTime, mn: parseInt(e.target.value) })}/>
          </Box>
          <Box mt={theme.spacing(0.15)}/>
          <Box display="flex" alignItems="center">
            <Typography>{t('stores.pauseStart')}</Typography>
            <Box mr={theme.spacing(0.4)}/>
            <OutlinedInput placeholder={t('common.h')} style={{ width: '100px' }} type="number" value={pauseStart.h} onChange={(e) => setPauseStart({ ...pauseStart, h: parseInt(e.target.value) })}/>
            <Box mr={theme.spacing(0.4)}/>
            <OutlinedInput placeholder={t('common.mn')} style={{ width: '100px' }} type="number" value={pauseStart.mn} onChange={(e) => setPauseStart({ ...pauseStart, mn: parseInt(e.target.value) })}/>
          </Box>
          <Box mt={theme.spacing(0.15)}/>
          <Box display="flex" alignItems="center">
            <Typography>{t('stores.pauseEnd')}</Typography>
            <Box mr={theme.spacing(0.4)}/>
            <OutlinedInput placeholder={t('common.h')} style={{ width: '100px' }} type="number" value={pauseEnd.h} onChange={(e) => setPauseEnd({ ...pauseEnd, h: parseInt(e.target.value) })}/>
            <Box mr={theme.spacing(0.4)}/>
            <OutlinedInput placeholder={t('common.mn')} style={{ width: '100px' }} type="number" value={pauseEnd.mn} onChange={(e) => setPauseEnd({ ...pauseEnd, mn: parseInt(e.target.value) })}/>
          </Box>
          <Box mt={theme.spacing(0.15)}/>
          <Box display="flex" alignItems="center">
            <Typography>{t('stores.region')}</Typography>
            <Box mr={theme.spacing(0.4)}/>
            <Select variant="outlined" MenuProps={{ elevation: 2 }} value={city} onChange={(e) => setCity(e.target.value)}>
              {cities.map((item) => <MenuItem key={item.objectId} value={item.objectId}>{item.name}</MenuItem>)}
            </Select>
          </Box>
          <Box mt={theme.spacing(0.15)}/>
          <Box display="flex" alignItems="center">
            <Typography>{t('stores.categories')}</Typography>
            <Box mr={theme.spacing(0.4)}/>
            <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
              {appCategories.map((item) => <FormControlLabel key={item.objectId} label={item.translations[user.language]}
                control={<Checkbox color="primary" checked={categories.includes(item.objectId)} onChange={(e) => {
                  const newCategories = [...categories];
                  if (e.target.checked) newCategories.push(item.objectId);
                  else newCategories.splice(newCategories.indexOf(item.objectId), 1);
                  setCategories(newCategories);
                }}/>}
              />)}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowActionDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={itemAction} color="primary">{t('common.' + action)}</Button>
        </DialogActions>
      </Dialog>
      {/*----Loading----*/}
      {loading && <>
        <Box mt={theme.spacing(0.8)}/>
        <CircularProgress />
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Error----*/}
      {!loading && error && <>
        <Box mt={theme.spacing(0.6)}/>
        <Error color="error" style={{ width: theme.spacing(12), height: theme.spacing(12) }}/>
        <Typography color="textSecondary">{t('common.problemMsg')}</Typography>
        <Box mt={theme.spacing(0.2)}/>
        <Button variant="contained" color="primary" style={{ paddingInline: theme.spacing(3), height: 35 }} onClick={initFetch}>
          {t('common.tryAgain')}
        </Button>
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Empty----*/}
      {!loading && !error && empty && <>
        <Box mt={theme.spacing(0.4)}/>
        <Typography color="textSecondary">{t('stores.emptyMsg')}</Typography>
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Success----*/}
      {!loading && !error && !empty && <>
        <TablePagination rowsPerPageOptions={fetchCountOptions} rowsPerPage={rowsPerPage} page={page} count={count}
          onChangePage={changePage} onChangeRowsPerPage={changeRowsPerPage} labelRowsPerPage={t('common.rowsPerPage')}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('common.of')} ${count}`}
          SelectProps={{ MenuProps: { elevation: 2 } }} component="div" style={{ alignSelf: 'flex-end' }}/>
        <Box mt={theme.spacing(0.4)}/>
      </>}
    </Box>
  </Paper>;
}

export default Stores;
