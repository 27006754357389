import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme, Paper, Box, Typography, CircularProgress, Button, IconButton, Checkbox, Toolbar, Tooltip, TableContainer, Table,
  TableHead, TableBody, TableCell, TableRow, TablePagination, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText,
  OutlinedInput, Select, MenuItem, Radio } from '@material-ui/core';
import { Error, Delete, Send, GetApp } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { get, post, del, getPointerFromId, postFile, delFile, callFunction } from '../../api';
import { ImageUploadResize, useLoading } from '../../utils';
import { getDir, getRTL } from '../../localization';
import { collections, fetchCountOptions, fetchCount } from '../../configs';

//App types
const appTypes = ['food', 'driver', 'manager'];

const Notifications = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const showLoading = useLoading();
  //Used states
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(false);
  const [ items, setItems ] = useState([]);
  const [ count, setCount ] = useState(0);
  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(fetchCount);
  const [ selected, setSelected ] = useState([]);
  const [ appTypeFilter, setAppTypeFilter ] = useState('/');
  const [ cityFilter, setCityFilter ] = useState('/');
  const [ showDeleteDialog, setShowDeleteDialog ] = useState(false);
  const [ showSendDialog, setShowSendDialog ] = useState(false);
  const [ cities, setCities ] = useState([]);
  const [ title, setTitle ] = useState('');
  const [ body, setBody ] = useState('');
  const [ pictureResponse, setPictureResponse ] = useState(null);
  const [ appType, setAppType ] = useState(appTypes[0]);
  const [ city, setCity ] = useState('/');
  const [ user, setUser ] = useState('');
  const [ action, setAction ] = useState('page');
  const [ pageName, setPageName ] = useState('');
  const [ pageId, setPageId ] = useState('');
  const [ url, setUrl ] = useState('');
  const [ customData, setCustomData ] = useState('');
  //Used attributes
  const empty = count === 0;
  const dir = getDir();
  const align = !getRTL() ? 'left' : 'right';
  const headers = [
    { id: 'id', label: t('notifications.id'), width: '15%', align, disablePadding: true },
    { id: 'title', label: t('notifications.title'), width: '15%', align, disablePadding: false },
    { id: 'body', label: t('notifications.body'), width: '15%', align, disablePadding: false },
    { id: 'picture', label: t('notifications.picture'), width: '10%', align, disablePadding: false },
    { id: 'appType', label: t('notifications.appType'), width: '10%', align, disablePadding: false },
    { id: 'target', label: t('notifications.target'), width: '15%', align, disablePadding: false },
    { id: 'data', label: t('notifications.data'), width: '10%', align, disablePadding: false },
    { id: 'dateSent', label: t('notifications.dateSent'), width: '10%', align, disablePadding: false }
  ];
  //Methods
  const exportItems = () => {
    const header = headers.map((el) => el.label).join(',');
    const selectedItems = items.filter((item) => selected.includes(item.id))
    .map((item) => item.id + ',' + item.title + ',' + item.body + ',/,' + t('common.' + item.appType) +
      ',' + (item.user ? t('notifications.user') + ': ' + item.user.fullname : item.city ? t('notifications.region') + ': ' + item.city.name : t('common.all')) +
      ',' + (item.data ? JSON.stringify(item.data) : '/') + ',' + item.dateSent);
    const data = header + '\n' + selectedItems.join('\n');
    var encodedUri = encodeURI(data);
    var link = document.createElement("a");
    link.setAttribute("href", "data:text/csv;charset=utf-8," + encodedUri);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
  }

  const send = () => {
    setShowSendDialog(true);
    setTitle('');
    setBody('');
    setPictureResponse(null);
    setAppType(appTypes[0]);
    setCity('/');
    setUser('');
    setAction('page');
    setPageName('');
    setPageId('');
    setUrl('');
    setCustomData('');
  }

  const remove = () => {
    setShowDeleteDialog(true);
  }

  const itemSend = () => {
    if (!title && !body && !pageName && !customData) {
      enqueueSnackbar(t('common.inputError'), { variant: 'error' });
      return;
    }
    showLoading(true);
    const params = { appType };
    if (title) params.title = title;
    if (body) params.body = body;
    if (user) params.user = getPointerFromId({ collection: collections.User, objectId: user });
    else if (city !== '/') params.city = getPointerFromId({ collection: collections.City, objectId: city });
    let data = undefined;
    if (action === 'page' && pageName) {
      data = { screen: pageName };
      if (pageId) data.id = pageId;
    } else if (action === 'url' && url) {
      data = { url };
    } else if (action === 'data' && customData) {
      data = JSON.parse(customData);
    }
    if (data !== undefined) params.data = data;
    const promise = pictureResponse ? (
      postFile({ filename: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 6) + '.' + pictureResponse.type, base64Data: pictureResponse.base64Data })
    ) : Promise.resolve();
    promise.then((file) => {
      if (file) params.picture = file;
      const sendParams = { appType };
      if (title) sendParams.title = title;
      if (body) sendParams.body = body;
      if (data) sendParams.data = data;
      if (file) sendParams.imageUrl = file.url();
      if (user) sendParams.userId = user;
      else if (city !== '/') sendParams.cityId = city;
      return callFunction({ funcName: 'sendPush', params: sendParams });
    })
    .then(() => post({ collection: collections.Notification, noACL: true, params }))
    .then(() => {
      enqueueSnackbar(t('notifications.sendSuccess'), { variant: 'success' });
      setShowSendDialog(false);
      const newPage = 0;
      setPage(newPage);
      fetch({ newPage });
    })
    .catch((error) => {
      if (error.message === 'USER_DOES_NOT_EXISTS') enqueueSnackbar(t('notifications.notUserError'), { variant: 'error' });
      else if (error.message === 'USER_PUSH_TOKEN_MISSING') enqueueSnackbar(t('notifications.notAppTypeError'), { variant: 'error' });
      else enqueueSnackbar(t('notifications.sendError'), { variant: 'error' });
    })
    .finally(() => showLoading(false));
  }

  const itemDelete = async () => {
    try {
      showLoading(true);
      for (const id of selected) {
        await del({ collection: collections.Notification, queryParams: [{ equalTo: { key: 'objectId', value: id } }] });
        const item = items.find((el) => el.id === id);
        if (item.picture) delFile({ filename: item.picture.name }).catch(() => {});
      }
      enqueueSnackbar(t('notifications.deleteSuccess'), { variant: 'success' });
      setShowDeleteDialog(false);
      setSelected([]);
      fetch();
    } catch {
      enqueueSnackbar(t('notifications.deleteError'), { variant: 'error' });
    } finally {
      showLoading(false);
    }
  }

  const changeAppTypeFilter = (e) => {
    const newPage = 0;
    const newAppTypeFilter = e.target.value;
    setPage(newPage);
    setAppTypeFilter(newAppTypeFilter);
    fetch({ newPage, newAppTypeFilter });
  }

  const changeCityFilter = (e) => {
    const newPage = 0;
    const newCityFilter = e.target.value;
    setPage(newPage);
    setCityFilter(newCityFilter);
    fetch({ newPage, newCityFilter });
  }

  const selectAll = (e) => {
    if (e.target.checked) setSelected(items.map((el) => el.id));
    else setSelected([]);
  }

  const selectItem = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) newSelected = newSelected.concat(selected, id);
    else if (selectedIndex === 0) newSelected = newSelected.concat(selected.slice(1));
    else if (selectedIndex === selected.length - 1) newSelected = newSelected.concat(selected.slice(0, -1));
    else if (selectedIndex > 0) newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    setSelected(newSelected);
  }

  const changePage = (e, newPage) => {
    setPage(newPage);
    fetch({ newPage });
  }

  const changeRowsPerPage = (e) => {
    const newPage = 0;
    const newRowsPerPage = parseInt(e.target.value);
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
    fetch({ newPage, newRowsPerPage });
  }

  const fetch = ({ newPage, newRowsPerPage, newAppTypeFilter, newCityFilter } = {}) => {
    const usedPage = newPage !== undefined ? newPage : page;
    const usedRowsPerPage = newRowsPerPage !== undefined ? newRowsPerPage : rowsPerPage;
    const usedAppTypeFilter = newAppTypeFilter !== undefined ? newAppTypeFilter : appTypeFilter;
    const usedCityFilter = newCityFilter !== undefined ? newCityFilter : cityFilter;
    setLoading(true);
    setError(false);
    get({ collection: collections.Notification, withCount: true, queryParams: [
      { descending: 'createdAt' },
      { limit: usedRowsPerPage },
      { skip: usedPage * usedRowsPerPage },
      { include: 'user' },
      { include: 'city' },
      usedAppTypeFilter === '/' ? {} : { equalTo: { key: 'appType', value: usedAppTypeFilter } },
      usedCityFilter === '/' ? {} : { equalTo: { key: 'city', value: getPointerFromId({ collection: collections.City, objectId: usedCityFilter }) } }
    ]})
    .then((data) => {
      setCount(data.count);
      setItems(data.results.map((item) => { return {
        id: item.objectId, title: item.title, body: item.body, picture: item.picture, data: item.data, appType: item.appType,
        city: item.city, user: item.user, dateSent: (new Date(item.createdAt)).toLocaleDateString()
      }}));
    })
    .catch(() => setError(true))
    .finally(() => setLoading(false));
  }

  const initFetch = () => {
    //Fetch regions
    get({ collection: collections.City, queryParams: [ { descending: 'createdAt' } ]})
    .then((data) => setCities(data)).catch(() => {});
    //Fetch items
    fetch();
  }
  //Effect for fetching data
  useEffect(initFetch, []);
  //Render page
  return <Paper elevation={1}>
    <Box display="flex" flexDirection="column" alignItems="center" paddingX={theme.spacing(0.5)}>
      {/*----Title----*/}
      <Box mt={theme.spacing(0.2)}/>
      <Typography variant="h6"><b>{t('menu.notifications')}</b></Typography>
      <Box mt={theme.spacing(0.2)}/>
      {/*----Content----*/}
      <Toolbar style={{ width: '100%', overflowX: 'auto', overflowY: 'hidden',
        backgroundColor: selected.length > 0 ? theme.palette.primary.main + '20' : 'transparent' }}>
        {selected.length > 0 ? <>
          <Typography style={{ flex: 1 }} color="inherit" variant="subtitle1">{selected.length + ' ' + t('common.selected')}</Typography>
          <Tooltip title={t('common.export')}><IconButton onClick={exportItems}><GetApp color="primary"/></IconButton></Tooltip>
          <Tooltip title={t('common.delete')}><IconButton onClick={remove}><Delete color="error"/></IconButton></Tooltip>
        </> : <>
          <Typography>{t('notifications.appType')}</Typography>
          <Box mr={theme.spacing(0.2)}/>
          <Select variant="outlined" style={{ height: 38 }} MenuProps={{ elevation: 2 }} value={appTypeFilter} onChange={changeAppTypeFilter}>
            <MenuItem value="/">/</MenuItem>
            {appTypes.map((item) => <MenuItem key={item} value={item}>{t('common.' + item)}</MenuItem>)}
          </Select>
          <Box mr={theme.spacing(0.4)}/>
          <Typography>{t('notifications.region')}</Typography>
          <Box mr={theme.spacing(0.2)}/>
          <Select variant="outlined" style={{ height: 38 }} MenuProps={{ elevation: 2 }} value={cityFilter} onChange={changeCityFilter}>
            <MenuItem value="/">/</MenuItem>
            {cities.map((item) => <MenuItem key={item.objectId} value={item.objectId}>{item.name}</MenuItem>)}
          </Select>
          <Box flex={1}/>
          <Tooltip title={t('notifications.send')}><IconButton onClick={send}><Send color="primary"/></IconButton></Tooltip>
        </>}
      </Toolbar>
      <TableContainer>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox color="primary" indeterminate={selected.length > 0 && selected.length < items.length}
                  checked={items.length > 0 && selected.length === items.length} onChange={selectAll}/>
              </TableCell>
              {headers.map((item) => (
                <TableCell key={item.id} width={item.width} align={item.align} padding={item.disablePadding ? 'none' : 'default'}>
                  <b>{item.label}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/*----Success----*/}
            {!loading && !error && !empty && items.map((item) => {
              const isItemSelected = selected.indexOf(item.id) !== -1;
              return <TableRow hover key={item.id} selected={isItemSelected} onClick={(e) => selectItem(item.id)}>
                <TableCell padding="checkbox"><Checkbox color="primary" checked={isItemSelected}/></TableCell>
                <TableCell align={align} padding="none">{item.id}</TableCell>
                <TableCell align={align}>{item.title}</TableCell>
                <TableCell align={align}>{item.body}</TableCell>
                <TableCell align={align}>{item.picture ? (
                  <a target="_blank" rel="noopener noreferrer" href={item.picture.url}>
                    <img alt={item.picture.name} src={item.picture.url} width='100%'></img>
                  </a>
                ) : ''}</TableCell>
                <TableCell align={align}>{t('common.' + item.appType)}</TableCell>
                <TableCell align={align}>{(() => {
                  if (item.user) return <><b>{t('notifications.user') + ': '}</b> {item.user.fullname}</>;
                  else if (item.city) return <><b>{t('notifications.region') + ': '}</b> {item.city.name}</>;
                  else return t('common.all');
                })()}</TableCell>
                <TableCell align={align}>{item.data ? JSON.stringify(item.data) : '/'}</TableCell>
                <TableCell align={align}>{item.dateSent}</TableCell>
              </TableRow>;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/*----Delete confirmation dialog----*/}
      <Dialog dir={dir} open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('notifications.dialog_delete')}</DialogTitle>
        <DialogContent><DialogContentText>{t('common.confirmMsg')}</DialogContentText></DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={itemDelete} color="primary">{t('common.delete')}</Button>
        </DialogActions>
      </Dialog>
      {/*----Send dialog----*/}
      <Dialog dir={dir} open={showSendDialog} onClose={() => setShowSendDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('notifications.dialog_send')}</DialogTitle>
        <DialogContent>
          <Typography><b>{t('notifications.target')}</b></Typography>
          <Box paddingX={theme.spacing(0.25)}>
            <Box display="flex" alignItems="center">
              <Typography>{t('notifications.appType')}</Typography>
              <Box mr={theme.spacing(0.4)}/>
              <Select variant="outlined" MenuProps={{ elevation: 2 }} value={appType} onChange={(e) => setAppType(e.target.value)}>
                {appTypes.map((item) => <MenuItem key={item} value={item}>{t('common.' + item)}</MenuItem>)}
              </Select>
            </Box>
            <Box mt={theme.spacing(0.15)}/>
            <Box display="flex" alignItems="center">
              <Typography>{t('notifications.region')}</Typography>
              <Box mr={theme.spacing(0.4)}/>
              <Select variant="outlined" MenuProps={{ elevation: 2 }} value={city} onChange={(e) => setCity(e.target.value)}>
                <MenuItem value="/">/</MenuItem>
                {cities.map((item) => <MenuItem key={item.objectId} value={item.objectId}>{item.name}</MenuItem>)}
              </Select>
            </Box>
            <Box mt={theme.spacing(0.15)}/>
            <OutlinedInput placeholder={t('notifications.userId')} fullWidth value={user} onChange={(e) => setUser(e.target.value)}/>
          </Box>
          <Box mt={theme.spacing(0.15)}/>
          <Typography><b>{t('notifications.content')}</b></Typography>
          <Box mt={theme.spacing(0.15)}/>
          <Box paddingX={theme.spacing(0.25)}>
            <OutlinedInput placeholder={t('notifications.title')} autoFocus fullWidth value={title} onChange={(e) => setTitle(e.target.value)}/>
            <Box mt={theme.spacing(0.15)}/>
            <OutlinedInput placeholder={t('notifications.body')} fullWidth value={body} onChange={(e) => setBody(e.target.value)}/>
            <Box mt={theme.spacing(0.15)}/>
            <Button color="primary" variant="contained"
              onClick={() => ImageUploadResize({ noResize: true }).then((imageData) => setPictureResponse(imageData)).catch(() => {})}>
              {pictureResponse ? t('notifications.changePicture') : t('notifications.setPicture')}
            </Button>
          </Box>
          <Box mt={theme.spacing(0.15)}/>
          <Typography><b>{t('notifications.action')}</b></Typography>
          <Box paddingX={theme.spacing(0.25)}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Radio color="primary" value="page" checked={action === "page"} onChange={(e) => setAction(e.target.value)}/>
              <Typography>{t('notifications.open_page')}</Typography>
            </Box>
            <OutlinedInput disabled={action !== 'page'} placeholder={t('notifications.pageName')} fullWidth value={pageName} onChange={(e) => setPageName(e.target.value)}/>
            <Box mt={theme.spacing(0.15)}/>
            <OutlinedInput disabled={action !== 'page'} placeholder={t('notifications.pageId')} fullWidth value={pageId} onChange={(e) => setPageId(e.target.value)}/>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Radio color="primary" value="url" checked={action === "url"} onChange={(e) => setAction(e.target.value)}/>
              <Typography>{t('notifications.open_url')}</Typography>
            </Box>
            <OutlinedInput disabled={action !== 'url'} placeholder={t('notifications.url')} fullWidth value={url} onChange={(e) => setUrl(e.target.value)}/>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Radio color="primary" value="data" checked={action === "data"} onChange={(e) => setAction(e.target.value)}/>
              <Typography>{t('notifications.custom_data')}</Typography>
            </Box>
            <OutlinedInput disabled={action !== 'data'} placeholder={t('notifications.data')} fullWidth value={customData} onChange={(e) => setCustomData(e.target.value)}/>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowSendDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={itemSend} color="primary">{t('notifications.send')}</Button>
        </DialogActions>
      </Dialog>
      {/*----Loading----*/}
      {loading && <>
        <Box mt={theme.spacing(0.8)}/>
        <CircularProgress />
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Error----*/}
      {!loading && error && <>
        <Box mt={theme.spacing(0.6)}/>
        <Error color="error" style={{ width: theme.spacing(12), height: theme.spacing(12) }}/>
        <Typography color="textSecondary">{t('common.problemMsg')}</Typography>
        <Box mt={theme.spacing(0.2)}/>
        <Button variant="contained" color="primary" style={{ paddingInline: theme.spacing(3), height: 35 }} onClick={initFetch}>
          {t('common.tryAgain')}
        </Button>
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Empty----*/}
      {!loading && !error && empty && <>
        <Box mt={theme.spacing(0.4)}/>
        <Typography color="textSecondary">{t('notifications.emptyMsg')}</Typography>
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Success----*/}
      {!loading && !error && !empty && <>
        <TablePagination rowsPerPageOptions={fetchCountOptions} rowsPerPage={rowsPerPage} page={page} count={count}
          onChangePage={changePage} onChangeRowsPerPage={changeRowsPerPage} labelRowsPerPage={t('common.rowsPerPage')}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('common.of')} ${count}`}
          SelectProps={{ MenuProps: { elevation: 2 } }} component="div" style={{ alignSelf: 'flex-end' }}/>
        <Box mt={theme.spacing(0.4)}/>
      </>}
    </Box>
  </Paper>;
}

export default Notifications;
