import { combineReducers } from 'redux';
import { userReducer, updateReducer } from './../api';
import { themeReducer } from './../theme';

//Combining all reducers
export const rootReducer = combineReducers({
  user: userReducer,
  update: updateReducer,
  theme: themeReducer
});
