import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { initStore } from "./store";
import { initAPI } from "./api";
import { initFirebase } from './push';
import Root from './navigation/root';

//Initialize the store
const store = initStore();
//Initialize the API
initAPI();
//Initialize Firebase
initFirebase();

//Wrapping the app inside the store provider to pass the store to the components tree
const StoreWrapper = () => <Provider store={store}><Root/></Provider>;

//Rendering the app
ReactDOM.render(<StoreWrapper/>, document.getElementById('root'));
