import { Create, Check, Motorcycle, LocalPizza, PersonPin } from '@material-ui/icons';

//App type to identify users
export const APP_TYPE = 'staff';

//Parse server URL and App ID
export const parseConfigs = {
    appId: 'switchApp',
    serverURL: 'https://api.switchfood.net'
    //serverURL: 'http://192.168.1.33'
};

//Google Map API key
export const googleMapApiKey = 'AIzaSyBXwpe962gPC087UFh-ULi4XiIuOuY6cSI';

//Firebase web push certificate
export const webPushKey = 'BL1QW6pDejviHCcbjPMon7LB2cju_qXvVhEcyZzblDGEFCnYdCZuyLfBaST4kbouxCH9l5Ic4sUBA8rltOjtz_I';

//Image upload configs
export const imageConfigs = { maxWidth: 600, maxHeight: 600 };

//How many items per list fetch
export const fetchCountOptions = [5, 10, 15, 20, 50, 100, 500, 1000];
export const fetchCount = 15;

//Collection names
export const collections = {
    User: 'User',
    Ad: 'Ad',
    Order: 'Order',
    Address: 'Address',
    Promo: 'Promo',
    Restaurant: 'Restaurant',
    Food: 'Food',
    Category: 'Category',
    List: 'List',
    Review: 'Review',
    Message: 'Message',
    City: 'City',
    Notification: 'Notification',
    FileObject: 'FileObject'
};

//Used currencies
export const currencies = [ 'dzd', 'eur', 'usd' ];

//Used ad types
export const adTypes = [ 'carousel', 'banner' ];

//Used promo types
export const promoTypes = [ 'percentage', 'percentage+free', 'fixed', 'freedelivery', 'freeall' ];
export const promoUseTypes = [ 'oneuse', 'twouse', 'threeuse', 'unlimited' ];

//Used app types
export const appTypes = [ 'food', 'driver', 'manager', 'staff' ];

//Used staff types and role name
export const staffTypes = { admin: 'Admin', staff: 'Staff' };
export const staffRoleName = 'Staff';

//Delivery Types
export const deliveryTypes = [ 'delivery', 'pickup' ];

//Order status for delivery
export const orderStatusDelivery = {
    status0: { key: 'placed', color: '#f2bc57', icon: Create },
    status1: { key: 'confirmed', color: '#6bc426', icon: Check },
    status2: { key: 'onTheWay', color: '#228ff2', icon: Motorcycle },
    status3: { key: 'delivered', color: '#919191', icon: PersonPin }
};

//Order status for pickup
export const orderStatusPickup = {
    status0: { key: 'placed', color: '#f2bc57', icon: Create },
    status1: { key: 'confirmed', color: '#6bc426', icon: Check },
    status2: { key: 'prepared', color: '#228ff2', icon: LocalPizza },
    status3: { key: 'picked', color: '#919191', icon: PersonPin }
};
