import { API_ERRORS } from "./../index";
var Parse = require('parse');

//Method for calling a cloud function
export const callFunction = ({ funcName, params } = {}) => {
    return new Promise((resolve, reject) => {
        //Check if any parameter is missing
        if (!funcName) throw API_ERRORS.FUNCTION_NAME_MISSING;
        //Call cloud function
        Parse.Cloud.run(funcName, params).then((data) => resolve(data))
        .catch((error) => reject(error));
    });
}
