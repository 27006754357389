import { API_ERRORS, callFunction } from "./../index";
var Parse = require('parse');

//Method for creating files
export const postFile = ({ filename, base64Data } = {}) => {
    return new Promise((resolve, reject) => {
        //Check if any parameter is missing
        if (!filename || !base64Data) throw API_ERRORS.FILE_PARAMS_MISSING;
        //Create the file object
        const file = new Parse.File(filename, { base64: base64Data });
        //Save the file
        file.save().then(() => resolve(file))
        .catch((error) => {
            reject(API_ERRORS.FAILED_TO_CREATE_FILE + ' - ' + error.code);
        });
    });
}

//Method for deleting files
export const delFile = ({ filename } = {}) => {
    return new Promise((resolve, reject) => {
        //Check if any parameter is missing
        if (!filename) throw API_ERRORS.FILE_PARAMS_MISSING;
        //Delete the file
        callFunction({ funcName: 'deleteFile', params: { filename } }).then(() => resolve())
        .catch(() => {
            reject(API_ERRORS.FAILED_TO_DELETE_FILE);
        });
    });
}
