import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useTheme, Paper, Box, Typography, CircularProgress, Button, IconButton, Checkbox, Toolbar, Tooltip, TableContainer, Table,
  TableHead, TableBody, TableCell, TableRow, TablePagination, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText,
  OutlinedInput, MenuItem, Select, InputAdornment, FormControlLabel } from '@material-ui/core';
import { Error, Add, Edit, Delete, Search, ArrowForward, ArrowBack, GetApp, Block, Check } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { get, post, callFunction, getPointerFromId } from '../../api';
import { useLoading } from '../../utils';
import { getDir, getRTL } from '../../localization';
import { collections, fetchCountOptions, fetchCount } from '../../configs';

const Lists = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const showLoading = useLoading();
  const history = useHistory();
  let { c_id, s_id, s_name } = useParams();
  if (s_name) s_name = decodeURIComponent(escape(window.atob(s_name.replace('***', '/'))));
  //Used states
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(false);
  const [ items, setItems ] = useState([]);
  const [ count, setCount ] = useState(0);
  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(fetchCount);
  const [ selected, setSelected ] = useState([]);
  const [ filter, setFilter ] = useState('name');
  const [ searchText, setSearchText ] = useState('');
  const [ showDeleteDialog, setShowDeleteDialog ] = useState(false);
  const [ showEnableToggleDialog, setShowEnableToggleDialog ] = useState(false);
  const [ showActionDialog, setShowActionDialog ] = useState(false);
  const [ action, setAction ] = useState(null);
  const [ id, setId ] = useState('');
  const [ name, setName ] = useState('');
  const [ enabled, setEnabled ] = useState(true);
  //Used attributes
  const empty = count === 0;
  const dir = getDir();
  const align = !getRTL() ? 'left' : 'right';
  const headers = [
    { id: 'id', label: t('lists.id'), width: '25%', align, disablePadding: true },
    { id: 'name', label: t('lists.name'), width: '25%', align, disablePadding: false },
    { id: 'status', label: t('lists.status'), width: '25%', align, disablePadding: false },
    { id: 'dateCreated', label: t('lists.dateCreated'), width: '25%', align, disablePadding: false }
  ];
  const filters = [
    { value: 'objectId', label: t('lists.id') },
    { value: 'name', label: t('lists.name') }
  ];
  //Methods
  const exportItems = () => {
    const header = headers.map((el) => el.label).join(',');
    const selectedItems = items.filter((item) => selected.includes(item.id))
    .map((item) => item.id + ',' + item.name + ',' + item.dateCreated);
    const data = header + '\n' + selectedItems.join('\n');
    var encodedUri = encodeURI(data);
    var link = document.createElement("a");
    link.setAttribute("href", "data:text/csv;charset=utf-8," + encodedUri);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
  }

  const add = () => {
    setAction('add');
    setShowActionDialog(true);
    setId('');
    setName('');
    setEnabled(true);
  }

  const edit = () => {
    if (selected.length > 1) {
      enqueueSnackbar(t('lists.oneOnly'), { variant: 'error' });
      return;
    }
    setAction('edit');
    setShowActionDialog(true);
    const item = items.find((el) => el.id === selected[0]);
    setId(item.id);
    setName(item.name);
  }

  const remove = () => {
    setShowDeleteDialog(true);
  }

  const enableToggle = () => {
    if (selected.length > 1) {
      enqueueSnackbar(t('lists.oneOnly'), { variant: 'error' });
      return;
    }
    setShowEnableToggleDialog(true);
    const item = items.find((el) => el.id === selected[0]);
    setId(item.id);
    setEnabled(item.enabled);
  }

  const itemAction = () => {
    if (!name) {
      enqueueSnackbar(t('common.inputError'), { variant: 'error' });
      return;
    }
    showLoading(true);
    const params = { name };
    let promise = null;
    if (action === 'add') {
      params.restaurant = getPointerFromId({ collection: collections.Restaurant, objectId: s_id });
      params.enabled = enabled;
      promise = post({ collection: collections.List, isPublic: true, params });
    } else if (action === 'edit') {
      params.id = id;
      promise = callFunction({ funcName: 'editList', params });
    }
    promise.then((obj) => {
      return callFunction({ funcName: 'assignList', params: {
        id: obj && action === 'add' ? obj.objectId : id, restaurantId: s_id
      }});
    }).then(() => {
      if (action === 'add') {
        enqueueSnackbar(t('lists.addSuccess'), { variant: 'success' });
        setShowActionDialog(false);
        const newPage = 0;
        setPage(newPage);
        fetch({ newPage });
      } else if (action === 'edit') {
        enqueueSnackbar(t('lists.editSuccess'), { variant: 'success' });
        setShowActionDialog(false);
        fetch();
      }
    })
    .catch(() => {
      if (action === 'add') enqueueSnackbar(t('lists.addError'), { variant: 'error' });
      else if (action === 'edit') enqueueSnackbar(t('lists.editError'), { variant: 'error' });
    })
    .finally(() => showLoading(false));
  }

  const itemDelete = async () => {
    showLoading(true);
    callFunction({ funcName: 'deletelists', params: { ids: selected } })
    .then(() => {
      enqueueSnackbar(t('lists.deleteSuccess'), { variant: 'success' });
      setShowDeleteDialog(false);
      setSelected([]);
      fetch();
    })
    .catch(() => enqueueSnackbar(t('lists.deleteError'), { variant: 'error' }))
    .finally(() => showLoading(false));
  }

  const itemToggleEnable = () => {
    showLoading(true);
    const newEnabled = !enabled;
    callFunction({ funcName: 'editList', params: { id, enabled: newEnabled } })
    .then(() => get({ collection: collections.Food, queryParams: [
      { limit: 1000 },
      { equalTo: { key: 'list', value: getPointerFromId({ collection: collections.List, objectId: id }) } }
    ]}))
    .then((data) => {
      let promise = Promise.resolve();
      data.forEach((item) => {
        promise = promise.then(() => callFunction({ funcName: 'editProduct', params: { id: item.objectId, enabled: newEnabled } }))
      });
      return promise;
    })
    .then(() => {
      enqueueSnackbar(t('lists.enableSuccess'), { variant: 'success' });
      setShowEnableToggleDialog(false);
      fetch();
    })
    .catch(() => enqueueSnackbar(t('lists.enableError'), { variant: 'error' }))
    .finally(() => showLoading(false));
  }

  const search = () => {
    const newPage = 0;
    setPage(newPage);
    fetch({ newPage });
  }

  const selectAll = (e) => {
    if (e.target.checked) setSelected(items.map((el) => el.id));
    else setSelected([]);
  }

  const selectItem = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) newSelected = newSelected.concat(selected, id);
    else if (selectedIndex === 0) newSelected = newSelected.concat(selected.slice(1));
    else if (selectedIndex === selected.length - 1) newSelected = newSelected.concat(selected.slice(0, -1));
    else if (selectedIndex > 0) newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    setSelected(newSelected);
  }

  const changePage = (e, newPage) => {
    setPage(newPage);
    fetch({ newPage });
  }

  const changeRowsPerPage = (e) => {
    const newPage = 0;
    const newRowsPerPage = parseInt(e.target.value);
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
    fetch({ newPage, newRowsPerPage });
  }

  const fetch = ({ newPage, newRowsPerPage } = {}) => {
    const usedPage = newPage !== undefined ? newPage : page;
    const usedRowsPerPage = newRowsPerPage !== undefined ? newRowsPerPage : rowsPerPage;
    setLoading(true);
    setError(false);
    get({ collection: collections.List, withCount: true, queryParams: [
      { descending: 'updatedAt' },
      { limit: usedRowsPerPage },
      { skip: usedPage * usedRowsPerPage },
      { equalTo: { key: 'restaurant', value: getPointerFromId({ collection: collections.Restaurant, objectId: s_id }) } },
      searchText ? { [filter === 'name' ? 'fullText' : 'startsWith']: { key: filter, value: searchText } } : {}
    ]})
    .then((data) => {
      setCount(data.count);
      setItems(data.results.map((item) => { return {
        id: item.objectId, name: item.name, enabled: item.enabled, dateCreated: (new Date(item.createdAt)).toLocaleDateString()
      }}));
    })
    .catch(() => setError(true))
    .finally(() => setLoading(false));
  }
  //Effect for fetching data
  useEffect(fetch, []);
  //Render page
  return <Paper elevation={1}>
    <Box display="flex" flexDirection="column" alignItems="center" paddingX={theme.spacing(0.5)}>
      {/*----Title----*/}
      <Box mt={theme.spacing(0.2)}/>
      <Typography variant="h6"><b>{t('lists.lists')}</b>{': ' + s_name}</Typography>
      <Box mt={theme.spacing(0.2)}/>
      {/*----Content----*/}
      <Toolbar style={{ width: '100%', overflowX: 'auto', overflowY: 'hidden',
        backgroundColor: selected.length > 0 ? theme.palette.primary.main + '20' : 'transparent' }}>
        {selected.length > 0 ? <>
          <Typography style={{ flex: 1 }} color="inherit" variant="subtitle1">{selected.length + ' ' + t('common.selected')}</Typography>
          <Tooltip title={t('common.export')}><IconButton onClick={exportItems}><GetApp color="primary"/></IconButton></Tooltip>
          <Tooltip title={t('lists.enableDisable')}><IconButton onClick={enableToggle}><Block color="action"/></IconButton></Tooltip>
          <Tooltip title={t('common.delete')}><IconButton onClick={remove}><Delete color="error"/></IconButton></Tooltip>
          <Tooltip title={t('common.edit')}><IconButton onClick={edit}><Edit color="action"/></IconButton></Tooltip>
        </> : <>
          <Tooltip title={t('common.goBack')}>
            <IconButton onClick={() => history.goBack()}>
              {dir === 'rtl' ? <ArrowForward color="action"/> : <ArrowBack color="action"/>}
            </IconButton>
          </Tooltip>
          <Box mr={theme.spacing(0.2)}/>
          <OutlinedInput placeholder={t('common.search')} style={{ minWidth: 200, height: 38 }}
            value={searchText} onChange={(e) => setSearchText(e.target.value)} onKeyUp={(e) => { if (e.key === 'Enter') search() }}
            endAdornment={<InputAdornment position="end">
              <IconButton size="small" onClick={search}><Search color="primary"/></IconButton>
            </InputAdornment>}
          />
          <Box mr={theme.spacing(0.2)}/>
          <Select variant="outlined" style={{ height: 38 }} MenuProps={{ elevation: 2, dir }} value={filter} onChange={(e) => setFilter(e.target.value)}>
            {filters.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
          </Select>
          <Box flex={1}/>
          <Tooltip title={t('common.add')}><IconButton onClick={add}><Add color="primary"/></IconButton></Tooltip>
        </>}
      </Toolbar>
      <TableContainer>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox color="primary" indeterminate={selected.length > 0 && selected.length < items.length}
                  checked={items.length > 0 && selected.length === items.length} onChange={selectAll}/>
              </TableCell>
              {headers.map((item) => (
                <TableCell key={item.id} width={item.width} align={item.align} padding={item.disablePadding ? 'none' : 'default'}>
                  <b>{item.label}</b>
                </TableCell>
              ))}
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {/*----Success----*/}
            {!loading && !error && !empty && items.map((item) => {
              const isItemSelected = selected.indexOf(item.id) !== -1;
              const navName = window.btoa(unescape(encodeURIComponent(item.name))).replace('/', '***');
              return <TableRow hover key={item.id} selected={isItemSelected} onClick={(e) => selectItem(item.id)}>
                <TableCell padding="checkbox"><Checkbox color="primary" checked={isItemSelected}/></TableCell>
                <TableCell align={align} padding="none">{item.id}</TableCell>
                <TableCell align={align}>{item.name}</TableCell>
                <TableCell align={align}>
                  {item.enabled ? (
                    <Box display="flex" alignItems="center">
                      <Check style={{ color: 'green' }}/>
                      <div>{t('lists.enabled')}</div>
                    </Box>
                  ) : (
                    <Box display="flex" alignItems="center">
                      <Block color="error"/>
                      <div>{t('lists.disabled')}</div>
                    </Box>
                  )}
                </TableCell>
                <TableCell align={align}>{item.dateCreated}</TableCell>
                <TableCell>
                  <Link to={'/products/' + c_id + '/' + s_id + '/' + item.id + '/' + navName}>
                    <IconButton>{dir === 'rtl' ? <ArrowBack color="secondary"/> : <ArrowForward color="secondary"/>}</IconButton>
                  </Link>
                </TableCell>
              </TableRow>;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/*----Delete confirmation dialog----*/}
      <Dialog dir={dir} open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('lists.dialog_delete')}</DialogTitle>
        <DialogContent><DialogContentText>{t('common.confirmMsg')}</DialogContentText></DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={itemDelete} color="primary">{t('common.delete')}</Button>
        </DialogActions>
      </Dialog>
      {/*----Enable/Disable Toggle confirmation dialog----*/}
      <Dialog dir={dir} open={showEnableToggleDialog} onClose={() => setShowEnableToggleDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('lists.dialog_enableDisable')}</DialogTitle>
        <DialogContent><DialogContentText>{t('common.confirmMsg')}</DialogContentText></DialogContent>
        <DialogActions>
          <Button onClick={() => setShowEnableToggleDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={itemToggleEnable} color="primary">{t('common.ok')}</Button>
        </DialogActions>
      </Dialog>
      {/*----Add/Edit dialog----*/}
      <Dialog dir={dir} open={showActionDialog} onClose={() => setShowActionDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('lists.dialog_' + action)}</DialogTitle>
        <DialogContent>
          <OutlinedInput placeholder={t('lists.name')} autoFocus fullWidth value={name} onChange={(e) => setName(e.target.value)}/>
          {action === 'add' && <>
            <Box mt={theme.spacing(0.15)}/>
            <FormControlLabel
              control={<Checkbox color="primary" checked={enabled} onChange={(e) => setEnabled(e.target.checked)}/>}
              label={t('lists.enabled')}
            />
          </>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowActionDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={itemAction} color="primary">{t('common.' + action)}</Button>
        </DialogActions>
      </Dialog>
      {/*----Loading----*/}
      {loading && <>
        <Box mt={theme.spacing(0.8)}/>
        <CircularProgress />
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Error----*/}
      {!loading && error && <>
        <Box mt={theme.spacing(0.6)}/>
        <Error color="error" style={{ width: theme.spacing(12), height: theme.spacing(12) }}/>
        <Typography color="textSecondary">{t('common.problemMsg')}</Typography>
        <Box mt={theme.spacing(0.2)}/>
        <Button variant="contained" color="primary" style={{ paddingInline: theme.spacing(3), height: 35 }} onClick={fetch}>
          {t('common.tryAgain')}
        </Button>
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Empty----*/}
      {!loading && !error && empty && <>
        <Box mt={theme.spacing(0.4)}/>
        <Typography color="textSecondary">{t('lists.emptyMsg')}</Typography>
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Success----*/}
      {!loading && !error && !empty && <>
        <TablePagination rowsPerPageOptions={fetchCountOptions} rowsPerPage={rowsPerPage} page={page} count={count}
          onChangePage={changePage} onChangeRowsPerPage={changeRowsPerPage} labelRowsPerPage={t('common.rowsPerPage')}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('common.of')} ${count}`}
          SelectProps={{ MenuProps: { elevation: 2 } }} component="div" style={{ alignSelf: 'flex-end' }}/>
        <Box mt={theme.spacing(0.4)}/>
      </>}
    </Box>
  </Paper>;
}

export default Lists;
