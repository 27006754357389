import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

//An object that holds the translations
export const languages = {
  'en': { name: 'English', isRTL: false, translations: () => require('./langs/en.json') },
  'fr': { name: 'Français', isRTL: false, translations: () => require('./langs/fr.json') },
  'ar': { name: 'العربية', isRTL: true, translations: () => require('./langs/ar.json') }
};

//Initialize localization
export const initLocalization = (language = null) => {
  i18n.use(LanguageDetector).use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },
    fallbackLng: 'en',
    resources: {
      en: { translation: languages.en.translations() },
      fr: { translation: languages.fr.translations() },
      ar: { translation: languages.ar.translations() }
    }
  });
  if (language) i18n.changeLanguage(language);
}

export const changeLanguage = (language) => {
  if (language) i18n.changeLanguage(language);
}

export const getLanguage = () => {
  return i18n.language;
}

export const getRTL = () => {
  return languages[i18n.language.length === 2 ? i18n.language : i18n.language.slice(0, 2)].isRTL;
}

export const getDir = () => {
  return languages[i18n.language.length === 2 ? i18n.language : i18n.language.slice(0, 2)].isRTL ? 'rtl' : 'ltr';
}
