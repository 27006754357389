
//Method for getting the center of a polygon
export const getPolygonCenter = ({ polygonArr }) => {
    var minX, maxX, minY, maxY;
    for (var i = 0; i < polygonArr.length; i++) {
      minX = (polygonArr[i][0] < minX || minX == null) ? polygonArr[i][0] : minX;
      maxX = (polygonArr[i][0] > maxX || maxX == null) ? polygonArr[i][0] : maxX;
      minY = (polygonArr[i][1] < minY || minY == null) ? polygonArr[i][1] : minY;
      maxY = (polygonArr[i][1] > maxY || maxY == null) ? polygonArr[i][1] : maxY;
    }
    return [(minX + maxX) / 2, (minY + maxY) / 2];
}
