import { parseConfigs } from "./../configs";
const Parse = require('parse');

//API error messages
export const API_ERRORS = {
    COLLECTION_MISSING: 'COLLECTION_MISSING',
    QUERY_PARAMS_MISSING: 'QUERY_PARAMS_MISSING',
    POST_PARAMS_MISSING: 'POST_PARAMS_MISSING',
    PUT_PARAMS_MISSING: 'PUT_PARAMS_MISSING',
    LOGIN_PARAMS_MISSING: 'LOGIN_PARAMS_MISSING',
    UPDATE_USER_PARAMS_MISSING: 'UPDATE_USER_PARAMS_MISSING',
    FUNCTION_NAME_MISSING: 'FUNCTION_NAME_MISSING',
    FILE_PARAMS_MISSING: 'FILE_PARAMS_MISSING',
    FAILED_TO_GET_USER: 'FAILED_TO_GET_USER',
    FAILED_TO_FIND: 'FAILED_TO_FIND',
    FAILED_TO_CREATE_OBJECT: 'FAILED_TO_CREATE_OBJECT',
    FAILED_TO_UPDATE_OBJECT: 'FAILED_TO_UPDATE_OBJECT',
    FAILED_TO_DELETE_OBJECT: 'FAILED_TO_DELETE_OBJECT',
    FAILED_TO_LOGIN: 'FAILED_TO_LOGIN',
    FAILED_TO_LOGOUT: 'FAILED_TO_LOGOUT',
    FAILED_TO_UPDATE_USER: 'FAILED_TO_UPDATE_USER',
    FAILED_TO_CREATE_FILE: 'FAILED_TO_CREATE_FILE',
    FAILED_TO_DELETE_FILE: 'FAILED_TO_DELETE_FILE'
}

//Initialize the api with the required configs
export const initAPI = () => {
    Parse.initialize(parseConfigs.appId);
    Parse.serverURL = parseConfigs.serverURL;
}

export { getUser, putUser, login, logout } from './modules/auth';
export { get, post, put, del, getPointerFromId, getGeoPoint, getPolygon } from './modules/objects';
export { postFile, delFile } from './modules/files';
export { callFunction } from './modules/cloud';
export { getConfig } from './modules/config';
export { userReducer, dispatchUser, updateReducer, dispatchUpdate } from './store';
