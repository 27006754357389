import React, { useState } from 'react';
import { useStore } from 'react-redux';
import { useTheme, Box, Button, Paper, InputAdornment, OutlinedInput } from '@material-ui/core';
import { Person, Lock } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useLoading } from './../../utils';
import { callFunction, login, dispatchUser, dispatchUpdate } from './../../api';
const logoLight = require('./../../assets/svg/logo-light.svg');
const logoDark = require('./../../assets/svg/logo-dark.svg');

export const Login = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const showLoading = useLoading();
  const store = useStore();
  //Used states
  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');
  //Methods
  const handleLogin = () => {
    if (!username || !password) {
      enqueueSnackbar(t('login.missingFields'), { variant: 'error' });
      return;
    }
    showLoading(true);
    callFunction({ funcName: 'loginStaff', params: { username, password } })
    .then((res) => {
      return login({ sessionToken: res.sessionToken });
    })
    .then((user) => {
      dispatchUser(store, user);
      dispatchUpdate(store, Math.random());
    }).catch((error) => {
      if (error && error.message === 'USER_DOES_NOT_EXISTS') enqueueSnackbar(t('login.usernameInvalid'), { variant: 'error' });
      else if (error && error.message === 'USER_UNAUTHORIZED') enqueueSnackbar(t('login.unauthorizedUser'), { variant: 'error' });
      else if (error && error.code === 101) enqueueSnackbar(t('login.passwordInvalid'), { variant: 'error' });
      else enqueueSnackbar(t('login.loginError'), { variant: 'error' });
    }).finally(() => {
      showLoading(false);
    });
  }
  //Render page
  return <Box position="fixed" display="flex" alignItems="center" justifyContent="center" width="100vw" height="100vh" style={{ backgroundColor: theme.palette.primary.main }}>
    <Paper elevation={2}>
      <Box display="flex" flexDirection="column" alignItems="center" style={{ width: 460, padding: '50px 60px' }}>
        <img src={theme.palette.type === 'light' ? logoLight : logoDark} alt="Switch Food" style={{ width: '240px' }}></img>
        <Box mt={6}/>
        <OutlinedInput placeholder={t('login.username')} value={username} onChange={(e) => setUsername(e.target.value)} fullWidth
          startAdornment={<InputAdornment position="start">
            <Person color="primary" style={{ opacity: 0.5 }}/>
          </InputAdornment>}
        />
        <Box mt={1}/>
        <OutlinedInput placeholder={t('login.password')} fullWidth type="password" value={password} onChange={(e) => setPassword(e.target.value)}
          startAdornment={<InputAdornment position="start">
            <Lock color="primary" style={{ opacity: 0.5 }}/>
          </InputAdornment>} onKeyUp={(e) => { if (e.key === 'Enter') handleLogin() }}
        />
        <Box mt={3}/>
        <Button variant="contained" color="primary" fullWidth onClick={handleLogin}>{t('login.login')}</Button>
      </Box>
    </Paper>
  </Box>;
}
