//Action types
const SET_USER = 'SET_USER';
const UPDATE_USER = 'UPDATE_USER';

//Action creators
const setUserAction = (user) => {
    return { type: SET_USER, payload: user };
};
const setUpdateAction = (update) => {
    return { type: UPDATE_USER, payload: update };
};

//Reducers
export const userReducer = (state = null, action) => {
    switch (action.type) {
        case SET_USER:
            return action.payload;
        default:
            return state;
    }
};
export const updateReducer = (state = null, action) => {
    switch (action.type) {
        case UPDATE_USER:
            return action.payload;
        default:
            return state;
    }
};

//Save user in the store
export const dispatchUser = (store, user) => {
    store.dispatch(setUserAction(user));
}
export const dispatchUpdate = (store, update) => {
    store.dispatch(setUpdateAction(update));
}
