import React, { useState, useEffect } from 'react';
import { useSelector, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Switch, Route, Redirect, Link, useLocation } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles, useTheme, useMediaQuery, Drawer, AppBar, Toolbar, List, IconButton, ListItem, ListItemIcon, ListItemText, Avatar,
  Box, Typography, Button, Divider, Badge, Popover, ListItemSecondaryAction, Tooltip } from '@material-ui/core';
import { Menu as MenuIcon, ChevronLeft, ChevronRight, ExitToApp, Notifications, Close, Delete } from '@material-ui/icons';
import { ADMIN_STACK, MAIN_STACK, MENU_STACK } from './../../pages';
import { dispatchUser, logout, putUser } from '../../api';
import { getRTL, getDir } from '../../localization';
import { staffTypes, APP_TYPE, webPushKey } from '../../configs';
import { useSnackbar } from 'notistack';
import { get, set } from 'idb-keyval';
import firebase from 'firebase';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const logo = require('./../../assets/svg/logo.svg');
const audio = new Audio('alert.mp3');
const broadcastCh = new BroadcastChannel('msg_channel');
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    zIndex: theme.zIndex.drawer + 1
  },
  appBarShift: {
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    zIndex: theme.zIndex.drawer - 1,
    width: `calc(100% - ${drawerWidth}px)`
  },
  logo: {
    width: 160
  },
  hide: {
    display: 'none'
  },
  avatarButton: {
    padding: 0
  },
  avatarText: {
    textTransform: 'none'
  },
  list: {
    paddingTop: 0
  },
  menu: {
    borderRadius: 5
  },
  listIcon: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short
    })
  },
  drawerOpen: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    width: drawerWidth,
    borderWidth: 0
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    },
    overflowX: 'hidden',
    borderWidth: 0
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    padding: theme.spacing(4)
  }
}));

const MainStack = () => {
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const store = useStore();
  const { t } = useTranslation();
  const { user } = useSelector(state => state);
  const { enqueueSnackbar } = useSnackbar();
  const isSM = useMediaQuery(theme.breakpoints.up('sm'));
  const isRTL = getRTL();
  const dir = getDir();
  //Used states
  const [ drawerOpen, setDrawerOpen ] = useState(false);
  const [ menuEl, setMenuEl ] = useState(null);
  const [ notifEl, setNotifEl ] = useState(null);
  const [ notifsMenu, setNotifsMenu ] = useState([]);
  //Used methods
  const deleteAllNotifs = async () => {
    const notifs = [];
    await set('NOTIFS', notifs);
    setNotifsMenu(notifs);
    setNotifEl(null);
    broadcastCh.postMessage('updateNotifs');
  }

  const deleteNotif = async (id) => {
    let index = -1;
    for (let i = 0; i < notifsMenu.length; i++) { if (notifsMenu[i].id === id) { index = i; break; } }
    if (index >= 0) {
      const notifs = [...notifsMenu];
      notifs.splice(index, 1);
      await set('NOTIFS', notifs);
      setNotifsMenu(notifs);
    }
    broadcastCh.postMessage('updateNotifs');
  }
  //Effect for handling notifications
  useEffect(() => {
    if (user) {
      //Checking saved notifs
      get('NOTIFS').then((data) => {
        const notifs = data || [];
        setNotifsMenu(notifs);
      }).catch(() => {});
      //Checking and saving push token
      const messaging = firebase.messaging();
      messaging.getToken({ vapidKey: webPushKey }).then((token) => {
        if (token && user.pushToken[APP_TYPE] !== token) {
          return putUser({ params: { pushToken: { ...user.pushToken, [APP_TYPE]: token } } });
        }
      }).catch(() => {});
      //Checking new push messages
      messaging.onMessage(async (payload) => {
        const data = await get('NOTIFS');
        const notifs = data || [];
        let isNew = true;
        for (const item of notifs) { if (item.id === payload.data.notifId) { isNew = false; break; } }
        if (isNew) notifs.unshift({ id: payload.data.notifId, title: payload.notification.title, data: payload.data });
        await set('NOTIFS', notifs);
        setNotifsMenu(notifs);
        enqueueSnackbar(payload.notification.title, { variant: 'info', onClick: payload.data && payload.data.page ? () => {
          window.location.href = '/' + payload.data.page + '/' + payload.data.id;
        } : undefined });
        if (localStorage.getItem('ALERT') === "true") audio.play();
      });
      //Check messages from other tabs
      broadcastCh.onmessage = async (ev) => {
        if (ev.data === 'updateNotifs') {
          const data = await get('NOTIFS');
          const notifs = data || [];
          setNotifsMenu(notifs);
        }
      };
    }
  }, [user, enqueueSnackbar]);
  //Render stack
  return <div className={classes.root} dir={dir}>
    <AppBar elevation={2} position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: drawerOpen })}
      style={{ [!isRTL ? 'right' : 'left']: 0 }}>
      <Toolbar>
        {isRTL && <Box mr={-theme.spacing(0.2)}/>}
        <IconButton color="inherit" edge="start" onClick={() => setDrawerOpen(true)}
          className={clsx({ [classes.hide]: drawerOpen })}>
          <MenuIcon/>
        </IconButton>
        <Box mr={theme.spacing(!drawerOpen ? (!isRTL ? 0.5 : 0.7) : (!isRTL ? 0 : 0.2))}/>
        <img src={logo} alt="Switch Food" className={classes.logo}></img>
        <Box flex={1}/>
        <IconButton color="inherit" onClick={(e) => setNotifEl(e.currentTarget)}>
          <Badge badgeContent={notifsMenu.length} color="secondary"><Notifications/></Badge>
        </IconButton>
        <Button color="inherit" className={classes.avatarButton} onClick={(e) => setMenuEl(e.currentTarget)}>
          <Box mr={theme.spacing(0.25)}/>
          <Typography className={classes.avatarText}>{user.fullname}</Typography>
          <Box mr={theme.spacing(0.15)}/>
          <Avatar alt={user.fullname} src={user.picture ? user.picture.url : undefined}/>
        </Button>
        <Menu dir={dir} anchorEl={menuEl} open={Boolean(menuEl)} onClose={() => setMenuEl(null)} getContentAnchorEl={null} PaperProps={{ elevation: 1 }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          classes={{ paper: classes.menu }}>
          {Object.keys(MENU_STACK).map((key) => {
            const Icon = MENU_STACK[key].icon;
            return <MenuItem key={key} component={Link} to={"/" + key} onClick={() => setMenuEl(null)}>
              <Icon fontSize="small" style={{ color: theme.palette.action.active }}/>
              <Box mr={theme.spacing(0.35)}/>
              <ListItemText primary={t('menu.' + key)} style={{ textAlign: !isRTL ? 'left' : 'right' }}/>
            </MenuItem>;
          })}
          <Divider/>
          <MenuItem onClick={() => {
            putUser({ params: { pushToken: { ...user.pushToken, staff: undefined } } })
            .then(() => logout()).finally(() => dispatchUser(store, null));
            setMenuEl(null);
          }}>
            <ExitToApp fontSize="small" style={{ color: theme.palette.action.active }}/>
            <Box mr={theme.spacing(0.35)}/>
            <ListItemText primary={t('menu.logout')} style={{ textAlign: !isRTL ? 'left' : 'right' }}/>
          </MenuItem>
        </Menu>
        <Popover anchorEl={notifEl} open={Boolean(notifEl)} onClose={() => setNotifEl(null)} getContentAnchorEl={null} PaperProps={{ elevation: 1 }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          classes={{ paper: classes.menu }}>
          {notifsMenu.length > 0 && <>
            <Box display="flex" justifyContent={!isRTL ? 'flex-end' : 'flex-start'} p={1}>
              <IconButton size="small" onClick={deleteAllNotifs}><Delete color="error"/></IconButton>
            </Box>
            <Box mt={-2}/>
          </>}
          <List>
            {notifsMenu.length === 0 && <ListItem>
              <ListItemText secondary={t('common.notifsEmptyMsg')} style={{ textAlign: !isRTL ? 'left' : 'right' }}/>
            </ListItem>}
            {notifsMenu.map((item) => <ListItem key={item.id}>
              <ListItemText primary={item.title} style={{ textAlign: !isRTL ? 'left' : 'right', cursor: item.data && item.data.page ? 'pointer' : undefined }}
                onClick={item.data && item.data.page ? () => { window.location.href = '/' + item.data.page + '/' + item.data.id; } : undefined}/>
              <Box mr={2}/>
              <ListItemSecondaryAction><IconButton size="small" onClick={() => deleteNotif(item.id)}><Close/></IconButton></ListItemSecondaryAction>
            </ListItem>)}
          </List>
        </Popover>
      </Toolbar>
    </AppBar>
    <Drawer variant="permanent" className={clsx({ [classes.drawerOpen]: drawerOpen, [classes.drawerClose]: !drawerOpen })}
      classes={{ paper: clsx({ [classes.drawerOpen]: drawerOpen, [classes.drawerClose]: !drawerOpen })}} PaperProps={{ elevation: 1 }}>
      <div className={classes.toolbar}>
        <IconButton onClick={() => setDrawerOpen(false)}>{!isRTL ? <ChevronLeft /> : <ChevronRight />}</IconButton>
      </div>
      <List className={classes.list}>
        {(() => {
          let stackArr = null;
          if (user.staffType === staffTypes.admin) stackArr = { ...ADMIN_STACK, ...MAIN_STACK };
          else stackArr = MAIN_STACK;
          return Object.keys(stackArr).map((key) => {
            const Icon = stackArr[key].icon;
            const title = t('menu.' + key);
            const showable = Icon !== null;
            return !showable ? null : <ListItem button key={key} component={Link} to={"/" + key} selected={'/' + key === location.pathname}>
              <Tooltip title={title}>
                <ListItemIcon className={classes.listIcon} style={drawerOpen || isSM ? { [!isRTL ? 'marginLeft' : 'marginRight']: theme.spacing(1) } : {}}>
                  <Icon/>
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={title} style={{ textAlign: !isRTL ? 'left' : 'right' }}/>
            </ListItem>;
          });
        })()}
      </List>
    </Drawer>
    <main className={classes.content}>
      <div className={classes.toolbar}/>
      <Switch>
        <Route path={"/support/:s_id"}><MAIN_STACK.support.component/></Route>
        <Route path={"/orders/:s_id"}><MAIN_STACK.orders.component/></Route>
        <Route path={"/reviews/:s_id/:s_name"}><MAIN_STACK.reviews.component/></Route>
        <Route path={"/lists/:c_id/:s_id/:s_name"}><MAIN_STACK.lists.component/></Route>
        <Route path={"/products/:c_id/:s_id/:l_id/:l_name"}><MAIN_STACK.products.component/></Route>
        {user.staffType === staffTypes.admin && Object.keys(ADMIN_STACK).map((key) => {
          const Component = ADMIN_STACK[key].component;
          const routable = ADMIN_STACK[key].icon !== null;
          return !routable ? null : <Route key={key} path={"/" + key}><Component/></Route>;
        })}
        {Object.keys(MAIN_STACK).map((key) => {
          const Component = MAIN_STACK[key].component;
          const routable = MAIN_STACK[key].icon !== null;
          return !routable ? null : <Route key={key} path={"/" + key}><Component/></Route>;
        })}
        {Object.keys(MENU_STACK).map((key) => {
          const Component = MENU_STACK[key].component;
          return <Route key={key} path={"/" + key}><Component/></Route>;
        })}
        <Redirect to={user.staffType === staffTypes.admin ? "/statistics" : "/promos"}/>
      </Switch>
    </main>
  </div>;
}

export default MainStack;
