import firebase from 'firebase';

export const initFirebase = () => {
    firebase.initializeApp({
        apiKey: "AIzaSyBXwpe962gPC087UFh-ULi4XiIuOuY6cSI",
        projectId: "switch-proj",
        storageBucket: "switch-proj.appspot.com",
        messagingSenderId: "121610801380",
        appId: "1:121610801380:web:5da5ab782d489ef2f25cf1",
        measurementId: "G-E47GP6WQ9T"
    });
}
