import { API_ERRORS } from "./../index";
import { staffRoleName } from "./../../configs";
var Parse = require('parse');

//Method for fetching objects
export const get = ({ collection, queryParams, withCount, count, isFindOne } = {}) => {
    return new Promise((resolve, reject) => {
        //Check if a collection name is provided
        if (!collection) throw API_ERRORS.COLLECTION_MISSING;
        //Check if any query parameter is missing
        if (!queryParams) throw API_ERRORS.QUERY_PARAMS_MISSING;
        //Create query object
        let query = createQuery({ collection, queryParams, withCount });
        //Handle finding one result or multiple results
        let getFunc = count ? () => query.count() : isFindOne ? () => query.first() : () => query.find();
        //Fetching results
        getFunc().then((data) => {
            let result = null;
            if (count) result = data;
            else if (data !== undefined) {
                if (withCount) result = { count: data.count, results: data.results.map(item => item.toJSON()) };
                else if (Array.isArray(data)) result = data.map(item => item.toJSON());
                else result = data.toJSON();
            }
            resolve(result);
        }).catch((error) => {
            reject(API_ERRORS.FAILED_TO_FIND + ' - ' + error.code)
        });
    });
}

//Method for creating objects
export const post = ({ collection, params, noACL, isPublic, isRole } = {}) => {
    return new Promise((resolve, reject) => {
        //Check if a collection name is provided
        if (!collection) throw API_ERRORS.COLLECTION_MISSING;
        //Check if any parameter is missing
        if (!params || Object.keys(params).length === 0) throw API_ERRORS.POST_PARAMS_MISSING;
        //Create collection object
        const obj = new Parse.Object(collection);
        //Extract parameters
        for (let key in params) obj.set(key, params[key]);
        //Fetching user to associate with the object
        const user = Parse.User.current();
        if (user) {
            if (!noACL) {
                //Set permissions
                let acl = new Parse.ACL(user);
                if (isRole) acl.setRoleWriteAccess(staffRoleName, true);
                if (isPublic) acl.setPublicReadAccess(true);
                obj.setACL(acl);
            }
            //Save the new object
            obj.save().then((obj) => {
                resolve(obj.toJSON());
            }).catch((error) => {
                reject(API_ERRORS.FAILED_TO_CREATE_OBJECT + ' - ' + error.code);
            });
        } else {
            reject(API_ERRORS.FAILED_TO_CREATE_OBJECT);
        }
    });
}

//Method for updating objects
export const put = ({ collection, queryParams, params } = {}) => {
    return new Promise((resolve, reject) => {
        //Check if a collection name is provided
        if (!collection) throw API_ERRORS.COLLECTION_MISSING;
        //Check if any query parameter is missing
        if (!queryParams) throw API_ERRORS.QUERY_PARAMS_MISSING;
        //Check if any parameter is missing
        if (!params || Object.keys(params).length === 0) throw API_ERRORS.PUT_PARAMS_MISSING;
        //Create query object
        let query = createQuery({ collection, queryParams });
        //Fetching results
        query.first().then((obj) => {
            //Extract parameters
            for (let key in params) obj.set(key, params[key]);
            //Save the updated object
            return obj.save();
        }).then((obj) => {
            resolve(obj.toJSON());
        })
        .catch((error) => {
            reject(API_ERRORS.FAILED_TO_UPDATE_OBJECT + ' - ' + error.code);
        });
    });
}

//Method for deleting objects
export const del = ({ collection, queryParams } = {}) => {
    return new Promise((resolve, reject) => {
        //Check if a collection name is provided
        if (!collection) throw API_ERRORS.COLLECTION_MISSING;
        //Check if any query parameter is missing
        if (!queryParams) throw API_ERRORS.QUERY_PARAMS_MISSING;
        //Create query object
        let query = createQuery({ collection, queryParams });
        //Fetching results
        query.first().then((obj) => {
            //Delete the object
            return obj.destroy();
        }).then((obj) => {
            resolve(obj.toJSON());
        })
        .catch((error) => {
            reject(API_ERRORS.FAILED_TO_DELETE_OBJECT + ' - ' + error.code);
        });
    });
}

//Create query object and apply filters
export const createQuery = ({ collection, queryParams, withCount } = {}) => {
    //Handle empty parameters
    if (!collection || !queryParams) return null;
    //Create query object
    let query = new Parse.Query(collection);
    //Handle counting
    if (withCount) query.withCount();
    //Handle filtering the query
    for (let i = 0; i < queryParams.length; i++) {
        let queryParam = queryParams[i];
        if (queryParam.equalTo) {
            if (!queryParam.equalTo.key || queryParam.equalTo.value === undefined) throw API_ERRORS.QUERY_PARAMS_MISSING;
            query.equalTo(queryParam.equalTo.key, queryParam.equalTo.value);
        }
        if (queryParam.exists) {
            query.exists(queryParam.exists);
        }
        if (queryParam.include) {
            query.include(queryParam.include);
        }
        if (queryParam.limit) {
            query.limit(queryParam.limit);
        }
        if (queryParam.skip) {
            query.skip(queryParam.skip);
        }
        if (queryParam.lessThanOrEqualTo) {
            if (!queryParam.lessThanOrEqualTo.key || queryParam.lessThanOrEqualTo.value === undefined) throw API_ERRORS.QUERY_PARAMS_MISSING;
            query.lessThanOrEqualTo(queryParam.lessThanOrEqualTo.key, queryParam.lessThanOrEqualTo.value);
        }
        if (queryParam.greaterThanOrEqualTo) {
            if (!queryParam.greaterThanOrEqualTo.key || queryParam.greaterThanOrEqualTo.value === undefined) throw API_ERRORS.QUERY_PARAMS_MISSING;
            query.greaterThanOrEqualTo(queryParam.greaterThanOrEqualTo.key, queryParam.greaterThanOrEqualTo.value);
        }
        if (queryParam.fullText) {
            if (!queryParam.fullText.key || queryParam.fullText.value === undefined) throw API_ERRORS.QUERY_PARAMS_MISSING;
            query.fullText(queryParam.fullText.key, queryParam.fullText.value);
        }
        if (queryParam.startsWith) {
            if (!queryParam.startsWith.key || queryParam.startsWith.value === undefined) throw API_ERRORS.QUERY_PARAMS_MISSING;
            query.startsWith(queryParam.startsWith.key, queryParam.startsWith.value);
        }
        if (queryParam.polygonContains) {
            if (!queryParam.polygonContains.key || queryParam.polygonContains.value === undefined) throw API_ERRORS.QUERY_PARAMS_MISSING;
            query.polygonContains(queryParam.polygonContains.key, queryParam.polygonContains.value);
        }
        if (queryParam.near) {
            if (!queryParam.near.key || queryParam.near.value === undefined) throw API_ERRORS.QUERY_PARAMS_MISSING;
            query.near(queryParam.near.key, queryParam.near.value);
        }
        if (queryParam.descending) {
            query.descending(queryParam.descending);
        }
        if (queryParam.ascending) {
            query.ascending(queryParam.ascending);
        }
        if (queryParam.withinKilometers) {
            if (!queryParam.withinKilometers.key || !queryParam.withinKilometers.location || !queryParam.withinKilometers.distance || !queryParam.withinKilometers.sorted) throw API_ERRORS.QUERY_PARAMS_MISSING;
            query.withinKilometers(queryParam.withinKilometers.key, queryParam.withinKilometers.location, queryParam.withinKilometers.distance, queryParam.withinKilometers.sorted);
        }
    }
    //Return the filtered query
    return query;
}

//Function for getting an object pointer from id
export const getPointerFromId = ({ collection, objectId }) => {
    const ObjectCol = Parse.Object.extend(collection);
    const obj = new ObjectCol();
    obj.id = objectId;
    return obj;
}

//Function for getting a GeoPoint from object | object <= { latitude, longitude }
export const getGeoPoint = ({ latitude, longitude }) => {
    const point = new Parse.GeoPoint({ latitude, longitude });
    return point;
}

//Function for getting a Polygon from array | array <= [ [latitude, longitude], ... ]
export const getPolygon = ({ polygonArr }) => {
    const polygon = new Parse.Polygon(polygonArr);
    return polygon;
}
