import React, { useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme, Paper, Box, Typography, Avatar, Button, OutlinedInput, InputAdornment } from '@material-ui/core';
import { Person, Lock, Email, Phone } from '@material-ui/icons';
import { delFile, dispatchUser, postFile, putUser } from '../../api';
import { useLoading, ImageUploadResize } from '../../utils';
import { useSnackbar } from 'notistack';
import * as EmailValidator from 'email-validator';

const Profile = () => {
  const theme = useTheme();
  const store = useStore();
  const { t } = useTranslation();
  const { user } = useSelector(state => state);
  const { enqueueSnackbar } = useSnackbar();
  const showLoading = useLoading();
  //Used states
  const [ fullname, setFullname ] = useState(user.fullname);
  const [ email, setEmail ] = useState(user.email);
  const [ phone, setPhone ] = useState(user.phone);
  const [ newPassword, setNewPassword ] = useState('');
  const [ confirmPassword, setConfirmPassword ] = useState('');
  //Methods
  const save = () => {
    if (!EmailValidator.validate(email)) {
      enqueueSnackbar(t('profile.emailError'), { variant: 'error' });
    } else if (!fullname || !phone || ((newPassword && !confirmPassword) || (!newPassword && confirmPassword))) {
      enqueueSnackbar(t('common.inputError'), { variant: 'error' });
    } else if (newPassword !== confirmPassword) {
      enqueueSnackbar(t('profile.passwordError'), { variant: 'error' });
    } else {
      const params = { fullname };
      if (email !== user.email) params.email = email;
      if (phone !== user.phone) params.phone = phone;
      if (newPassword) params.password = newPassword;
      showLoading(true);
      putUser({ params })
      .then((user) => {
        dispatchUser(store, user);
        setNewPassword('');
        setConfirmPassword('');
        enqueueSnackbar(t('profile.saveSuccess'), { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar(t('profile.saveError'), { variant: 'error' });
      })
      .finally(() => showLoading(false));
    }
  }

  const changePicture = () => {
    ImageUploadResize()
    .then(({ type, base64Data }) => {
      showLoading(true);
      return postFile({ filename: user.username + '.' + type, base64Data });
    })
    .then((file) => {
      if (user.picture) delFile({ filename: user.picture.name }).catch(() => {});
      return putUser({ params: { picture: file } });
    })
    .then((user) => dispatchUser(store, user))
    .catch(() => enqueueSnackbar(t('profile.pictureError'), { variant: 'error' }))
    .finally(() => showLoading(false));
  }
  //Render page
  return <Paper elevation={1} style={{ maxWidth: 600, margin: 'auto' }}>
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box mt={theme.spacing(0.2)}/>
      <Typography variant="h6"><b>{t('menu.profile')}</b></Typography>
      <Box mt={theme.spacing(0.6)}/>
      <Avatar alt={user.fullname} src={user.picture ? user.picture.url : undefined} style={{ width: theme.spacing(10), height: theme.spacing(10) }}/>
      <Box mt={theme.spacing(0.15)}/>
      <Button variant="contained" color="primary" onClick={changePicture} style={{ paddingInline: theme.spacing(2), height: 30 }}>
        <Typography style={{ fontSize: 13, fontWeight: 700 }}>{t('profile.change')}</Typography>
      </Button>
      <Box mt={theme.spacing(0.4)}/>
      <Box width="70%">
        <OutlinedInput placeholder={t('profile.fullname')} value={fullname} onChange={(e) => setFullname(e.target.value)} fullWidth
          startAdornment={<InputAdornment position="start">
            <Person color="primary" style={{ opacity: 0.5 }}/>
          </InputAdornment>} type="text"
        />
        <Box mt={theme.spacing(0.15)}/>
        <OutlinedInput placeholder={t('profile.email')} value={email} onChange={(e) => setEmail(e.target.value)} fullWidth
          startAdornment={<InputAdornment position="start">
            <Email color="primary" style={{ opacity: 0.5 }}/>
          </InputAdornment>} type="email"
        />
        <Box mt={theme.spacing(0.15)}/>
        <OutlinedInput placeholder={t('profile.phone')} value={phone} onChange={(e) => setPhone(e.target.value)} fullWidth
          startAdornment={<InputAdornment position="start">
            <Phone color="primary" style={{ opacity: 0.5 }}/>
          </InputAdornment>} type="phone"
        />
        <Box mt={theme.spacing(0.15)}/>
        <OutlinedInput placeholder={t('profile.newPassword')} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} fullWidth
          startAdornment={<InputAdornment position="start">
            <Lock color="primary" style={{ opacity: 0.5 }}/>
          </InputAdornment>} type="password"
        />
        <Box mt={theme.spacing(0.15)}/>
        <OutlinedInput placeholder={t('profile.confirmPassword')} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} fullWidth
          startAdornment={<InputAdornment position="start">
            <Lock color="primary" style={{ opacity: 0.5 }}/>
          </InputAdornment>} type="password"
        />
      </Box>
      <Box mt={theme.spacing(0.4)}/>
      <Button variant="contained" color="primary" onClick={save} style={{ paddingInline: theme.spacing(6) }}>{t('profile.save')}</Button>
      <Box mt={theme.spacing(0.4)}/>
    </Box>
  </Paper>;
}

export default Profile;
