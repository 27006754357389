import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useTheme, Paper, Box, Typography, CircularProgress, Button, IconButton, Checkbox, Toolbar, Tooltip, TableContainer, Table,
  TableHead, TableBody, TableCell, TableRow, TablePagination, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText,
  OutlinedInput, FormControlLabel, MenuItem, Select, InputAdornment } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Error, Delete, Edit, Search, Motorcycle, Cancel, Check, Close, GetApp } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { get, callFunction, getPointerFromId } from '../../api';
import { PerPage, useLoading } from '../../utils';
import { getDir, getRTL } from '../../localization';
import { collections, fetchCountOptions, deliveryTypes, orderStatusDelivery, orderStatusPickup } from '../../configs';

const FINISHED_ORDER_STATUS = 2;

const Orders = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const showLoading = useLoading();
  const { s_id } = useParams();
  //Used states
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(false);
  const [ items, setItems ] = useState([]);
  const [ count, setCount ] = useState(0);
  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(PerPage.get());
  const [ selected, setSelected ] = useState([]);
  const [ filter, setFilter ] = useState('objectId');
  const [ searchText, setSearchText ] = useState(s_id || '');
  const [ cityFilter, setCityFilter ] = useState('/');
  const [ typeFilter, setTypeFilter ] = useState('/');
  const [ statusFilter, setStatusFilter ] = useState('/');
  const [ canceledFilter, setCanceledFilter ] = useState(false);
  const [ withoutDriver, setWithoutDriver ] = useState(false);
  const [ showDeleteDialog, setShowDeleteDialog ] = useState(false);
  const [ showEditDialog, setShowEditDialog ] = useState(false);
  const [ showActionDialog, setShowActionDialog ] = useState(false);
  const [ action, setAction ] = useState(null);
  const [ cities, setCities ] = useState([]);
  const [ id, setId ] = useState('');
  const [ driverId, setDriverId ] = useState('');
  const [ reason, setReason ] = useState('');
  const [ noChoose, setNoChoose ] = useState(false);
  const [ noNotifs, setNoNotifs ] = useState(false);
  const [ fromDate, setFromDate ] = useState(null);
  const [ toDate, setToDate ] = useState(null);
  const [ options, setOptions ] = useState({});
  const [ oldStatus, setOldStatus ] = useState(null);
  const [ newStatus, setNewStatus ] = useState(null);
  const [ oldCanceled, setOldCanceled ] = useState(null);
  const [ newCanceled, setNewCanceled ] = useState(null);
  const [ type, setType ] = useState(null);
  //Used attributes
  const empty = count === 0;
  const dir = getDir();
  const align = !getRTL() ? 'left' : 'right';
  const headers = [
    { id: 'id', label: t('orders.id'), width: '10%', align, disablePadding: true },
    { id: 'user', label: t('orders.user'), width: '10%', align, disablePadding: false },
    { id: 'driver', label: t('orders.driver'), width: '10%', align, disablePadding: false },
    { id: 'store', label: t('orders.store'), width: '10%', align, disablePadding: false },
    { id: 'type', label: t('orders.type'), width: '10%', align, disablePadding: false },
    { id: 'status', label: t('orders.status'), width: '10%', align, disablePadding: false },
    { id: 'promo', label: t('orders.promo'), width: '10%', align, disablePadding: false },
    { id: 'products', label: t('orders.products'), width: '10%', align, disablePadding: false },
    { id: 'data', label: t('orders.data'), width: '10%', align, disablePadding: false },
    { id: 'dateCreated', label: t('orders.dateCreated'), width: '10%', align, disablePadding: false }
  ];
  const filters = [
    { value: 'objectId', label: t('orders.id') },
    { value: 'user', label: t('orders.userId') },
    { value: 'driver', label: t('orders.driverId') },
    { value: 'restaurant', label: t('orders.storeId') }
  ];
  let total = undefined, currency = '';
  items.forEach((item) => {
    if (!currency) currency = item.city ? t('currency.' + item.city.currency) : '';
    if (item.filter && item.payment !== undefined) {
      if (total === undefined) total = 0;
      total += item.payment;
    }
  });
  //Methods
  const exportItems = () => {
    const header = searchText && filter === 'driver' ? (
      [t('orders.id'), t('orders.store'), t('orders.paymentMethod'), t('orders.delivery'), t('orders.service'), t('orders.payment'),
        t('orders.dateCreated')].join(',')
    ) : searchText && filter === 'restaurant' ? (
      [t('orders.id'), t('orders.type'), t('orders.promo'), t('orders.products'), t('orders.paymentMethod'),
        t('orders.itemsTotal'), t('orders.discount'), t('orders.payment'), t('orders.dateCreated')].join(',')
    ) : (
      headers.map((el) => el.label).join(',')
    );
    const selectedItems = items.filter((item) => selected.includes(item.id))
    .map((item) => {
      const currency = item.city ? t('currency.' + item.city.currency) : '';
      if (searchText && filter === 'driver') return item.id + ',' + (item.restaurant ? item.restaurant.name : '/') +
      ',' + (item.options.paymentMethod ? t('orders.' + item.options.paymentMethod) : '/') +
      ',' + (item.options.delivery ? item.options.delivery.toString() + currency + (item.options.freeDelivery ? ' - ' + t('orders.freeDelivery') : '') : '/') +
      ',' + (item.options.service ? item.options.service.toString() + currency : '/') +
      ',' + (item.payment !== undefined ? item.payment.toFixed(1) + currency : '/') + ',' + item.dateCreated.replace(',', '');
      else if (searchText && filter === 'restaurant') return item.id + ',' + t('orders.' + item.type) + ',' + (item.promo ? item.promo.code : '/') +
      ',' + item.food.map((el, i) => {
        let res = '';
        if (item.options.values[i][el.objectId]) {
          res = 'x' + item.options.values[i][el.objectId].quantity + ' ' + el.name;
          if (item.options.values[i][el.objectId].price) res += ' | ' + item.options.values[i][el.objectId].price + currency;
          if (item.options.values[i][el.objectId].variants && item.options.values[i][el.objectId].variants.length > 0) {
            res += ' | ' + item.options.values[i][el.objectId].variants.map((item) => item.name + ': ' + item.value.name).join(', ');
          }
          if (item.options.values[i][el.objectId].instructions && item.options.values[i][el.objectId].instructions.length > 0) {
            res += ' | ' + t('orders.instructions') + ': ' + item.options.values[i][el.objectId].instructions.map((item) => item.name).join(', ');
          }
        }
        return res;
      }).join(' + ') +
      ',' + (item.options.paymentMethod ? t('orders.' + item.options.paymentMethod) : '/') +
      ',' + (item.options.itemsTotal ? item.options.itemsTotal.toString() + currency : '/') +
      ',' + (item.options.discount ? '-' + item.options.discount.toString() + currency : '/') +
      ',' + (item.payment !== undefined ? item.payment.toFixed(1) + currency : '/') + ',' + item.dateCreated.replace(',', '');
      else return item.id + ',' + (item.user ? item.user.fullname : '/') + ',' + (item.driver ? item.driver.fullname : '/') +
      ',' + (item.restaurant ? item.restaurant.name : '/') + ',' + t('orders.' + item.type) +
      ',' + (item.canceled ? t('orders.canceled') : item.type === 'delivery' ? t('orders.' + orderStatusDelivery['status' + item.status.toString()].key) : item.type === 'pickup' ? t('orders.' + orderStatusPickup['status' + item.status.toString()].key) : '/') +
      ',' + (item.promo ? item.promo.code : '/') + ',' + item.food.map((el, i) => item.options.values[i][el.objectId] ? ('x' + item.options.values[i][el.objectId].quantity + ' ' + el.name + (item.options.values[i][el.objectId].price ? ' | ' + item.options.values[i][el.objectId].price + currency : '')) : '').join(' + ') +
      ',' + (item.options.total ? t('orders.total') + ': ' + item.options.total.toString() + currency : '/') + ',' + item.dateCreated.replace(',', '');
    });
    let data = header + '\n' + selectedItems.join('\n');
    if (searchText && (filter === 'driver' || filter === 'restaurant') && total !== undefined) {
      data += '\n\n' + t('orders.total') + ': ' + total.toFixed(1) + currency;
    }
    var encodedUri = encodeURI(data);
    var link = document.createElement("a");
    link.setAttribute("href", "data:text/csv;charset=utf-8," + encodedUri);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
  }

  const removeProduct = (orderId, productIndex) => {
    if (orderId === undefined || productIndex === undefined) return;
    if (window.confirm(t('common.confirmMsg'))) {
      const item = items.find((el) => el.id === orderId);
      const newValues = [ ...item.options.values ];
      newValues.splice(productIndex, 1);
      const newOptions = { values: newValues };
      const newFood = [ ...item.food ];
      newFood.splice(productIndex, 1);
      const foodIds = newFood.map((el) => el.objectId);
      showLoading(true);
      callFunction({ funcName: 'editOrder', params: { id: orderId, options: newOptions, foodIds } })
      .then(() => {
        enqueueSnackbar(t('orders.editSuccess'), { variant: 'success' });
        fetch();
      })
      .catch(() => enqueueSnackbar(t('orders.editError'), { variant: 'error' }))
      .finally(() => showLoading(false));
    }
  }

  const remove = () => {
    setShowDeleteDialog(true);
  }

  const edit = () => {
    if (selected.length > 1) {
      enqueueSnackbar(t('orders.oneOnly'), { variant: 'error' });
      return;
    }
    setShowEditDialog(true);
    const item = items.find((el) => el.id === selected[0]);
    setId(item.id);
    setOptions(item.options);
    setType(item.type);
    setOldStatus(item.status);
    setNewStatus(item.status);
    setOldCanceled(item.canceled);
    setNewCanceled(item.canceled);
  }

  const doAction = (newAction) => {
    if (selected.length > 1) {
      enqueueSnackbar(t('orders.oneOnly'), { variant: 'error' });
      return;
    }
    const item = items.find((el) => el.id === selected[0]);
    if ((newAction !== 'cancelManager' && item.status >= 3) ||
      (item.canceled && newAction !== 'chooseDriver' && newAction !== 'assignDriver') ||
      (item.type === 'delivery' && item.status >= 2 && newAction !== 'chooseDriver' && newAction !== 'assignDriver' && newAction !== 'cancelManager') ||
      (item.type === 'pickup' && (newAction === 'chooseDriver' || newAction === 'assignDriver'))) {
        enqueueSnackbar(t('orders.notAllowedError'), { variant: 'error' });
        return;
    }
    setId(item.id);
    if (newAction === 'assignDriver') {
      setDriverId('');
    } else if (newAction === 'cancelManager') {
      setReason('');
      setNoNotifs(false);
    } else if (newAction !== 'chooseDriver') {
      if (item.status === 0) {
        newAction = 'acceptManager';
        setNoChoose(false);
      }
      else if (item.status === 1) newAction = 'finishManager';
      else if (item.status === 2) newAction = 'confirmManager';
    }
    setAction(newAction);
    setShowActionDialog(true);
  }

  const itemDelete = async () => {
    showLoading(true);
    callFunction({ funcName: 'deleteOrders', params: { ids: selected } })
    .then(() => {
      enqueueSnackbar(t('orders.deleteSuccess'), { variant: 'success' });
      setShowDeleteDialog(false);
      setSelected([]);
      fetch();
    })
    .catch(() => enqueueSnackbar(t('orders.deleteError'), { variant: 'error' }))
    .finally(() => showLoading(false));
  }

  const itemEdit = async () => {
    if ((type === 'delivery' && !options.delivery) || (options.service !== 0 && !options.service) || !options.itemsTotal
      || (options.discount !== 0 && !options.discount) || !options.total) {
      enqueueSnackbar(t('common.inputError'), { variant: 'error' });
      return;
    }
    const newOptions = { service: parseInt(options.service), itemsTotal: parseInt(options.itemsTotal),
      discount: parseInt(options.discount), total: parseInt(options.total) };
    if (type === 'delivery') {
      newOptions.freeDelivery = options.freeDelivery;
      newOptions.delivery = parseInt(options.delivery);
    }
    const params = { id, options: newOptions };
    if (oldStatus !== newStatus) params.status = newStatus;
    if (oldCanceled !== newCanceled) params.canceled = newCanceled;
    showLoading(true);
    callFunction({ funcName: 'editOrder', params })
    .then(() => {
      enqueueSnackbar(t('orders.editSuccess'), { variant: 'success' });
      setShowEditDialog(false);
      fetch();
    })
    .catch(() => enqueueSnackbar(t('orders.editError'), { variant: 'error' }))
    .finally(() => showLoading(false));
  }

  const itemAction = () => {
    if ((action === 'assignDriver' && !driverId) || (action === 'cancelManager' && !reason)) {
      enqueueSnackbar(t('orders.inputError'), { variant: 'error' });
      return;
    }
    showLoading(true);
    let params = {};
    if (action === 'assignDriver') params = { orderId: id, driverId };
    else if (action === 'chooseDriver') params = { orderId: id };
    else if (action === 'cancelManager') params = { objectId: id, reason, noNotifs, fromAdmin: true };
    else if (action === 'acceptManager') params = { objectId: id, noChoose };
    else params = { objectId: id };
    callFunction({ funcName: action, params })
    .then(() => {
      enqueueSnackbar(t('orders.done'), { variant: 'success' });
      setShowActionDialog(false);
      if (action === 'cancelManager') setSelected([]);
      fetch();
    })
    .catch((error) => {
      if (error.message === 'DRIVER_DISCONNECTED') enqueueSnackbar(t('orders.disconnectedError'), { variant: 'error' });
      else if (error.message === 'ORDER_CANCELED') enqueueSnackbar(t('orders.canceledError'), { variant: 'error' });
      else if (error.message === 'ORDER_FULLFILLED') enqueueSnackbar(t('orders.fulfilledError'), { variant: 'error' });
      else enqueueSnackbar(t('orders.actionError'), { variant: 'error' });
    })
    .finally(() => showLoading(false));
  }

  const search = ({ newCityFilter, newTypeFilter, newStatusFilter, newCanceledFilter, newWithoutDriverFilter, newFromDate, newToDate } = {}) => {
    const newPage = 0;
    setPage(newPage);
    if (newCityFilter !== undefined) setCityFilter(newCityFilter);
    if (newTypeFilter !== undefined) setTypeFilter(newTypeFilter);
    if (newStatusFilter !== undefined) setStatusFilter(newStatusFilter);
    if (newCanceledFilter !== undefined) setCanceledFilter(newCanceledFilter);
    if (newWithoutDriverFilter !== undefined) setWithoutDriver(newWithoutDriverFilter);
    if (newFromDate !== undefined) {
      if (newFromDate) newFromDate.setHours(0, 0, 0, 0);
      setFromDate(newFromDate);
    }
    if (newToDate !== undefined) {
      if (newToDate) newToDate.setHours(23, 59, 59, 999);
      setToDate(newToDate);
    }
    fetch({ newPage, newCityFilter, newTypeFilter, newStatusFilter, newCanceledFilter, newWithoutDriverFilter, newFromDate, newToDate });
  }

  const selectAll = (e) => {
    if (e.target.checked) setSelected(items.map((el) => el.id));
    else setSelected([]);
  }

  const selectItem = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) newSelected = newSelected.concat(selected, id);
    else if (selectedIndex === 0) newSelected = newSelected.concat(selected.slice(1));
    else if (selectedIndex === selected.length - 1) newSelected = newSelected.concat(selected.slice(0, -1));
    else if (selectedIndex > 0) newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    setSelected(newSelected);
  }

  const changePage = (e, newPage) => {
    setPage(newPage);
    fetch({ newPage });
  }

  const changeRowsPerPage = (e) => {
    const newPage = 0;
    const newRowsPerPage = parseInt(e.target.value);
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
    PerPage.set(newRowsPerPage);
    fetch({ newPage, newRowsPerPage });
  }

  const fetch = ({ newPage, newRowsPerPage, newCityFilter, newTypeFilter, newStatusFilter, newCanceledFilter, newWithoutDriverFilter, newFromDate, newToDate } = {}) => {
    const usedPage = newPage !== undefined ? newPage : page;
    const usedRowsPerPage = newRowsPerPage !== undefined ? newRowsPerPage : rowsPerPage;
    const usedCityFilter = newCityFilter !== undefined ? newCityFilter : cityFilter;
    const usedTypeFilter = newTypeFilter !== undefined ? newTypeFilter : typeFilter;
    const usedStatusFilter = newStatusFilter !== undefined ? newStatusFilter : statusFilter;
    const usedCanceledFilter = newCanceledFilter !== undefined ? newCanceledFilter : canceledFilter;
    const usedWithoutDriverFilter = newWithoutDriverFilter !== undefined ? newWithoutDriverFilter : withoutDriver;
    const usedFromDate = newFromDate !== undefined ? newFromDate : fromDate;
    const usedToDate = newToDate !== undefined ? newToDate : toDate;
    setLoading(true);
    setError(false);
    get({ collection: collections.Order, withCount: true, queryParams: [
      { descending: 'createdAt' },
      { limit: usedRowsPerPage },
      { skip: usedPage * usedRowsPerPage },
      { include: 'user' },
      { include: 'driver' },
      { include: 'restaurant' },
      { include: 'city' },
      { include: 'food' },
      { include: 'promo' },
      searchText && filter === 'objectId' ? {} : { equalTo: { key: 'canceled', value: usedCanceledFilter } },
      usedWithoutDriverFilter ? { equalTo: { key: 'driver', value: null } } : {},
      usedTypeFilter === '/' ? {} : { equalTo: { key: 'deliveryType', value: usedTypeFilter } },
      usedStatusFilter === '/' ? {} : { equalTo: { key: 'status', value: usedStatusFilter } },
      usedCityFilter === '/' ? {} : { equalTo: { key: 'city', value: getPointerFromId({ collection: collections.City, objectId: usedCityFilter }) } },
      usedFromDate ? { greaterThanOrEqualTo: { key: 'createdAt', value: usedFromDate } } : {},
      usedToDate ? { lessThanOrEqualTo: { key: 'createdAt', value: usedToDate } } : {},
      searchText && filter === 'objectId' ? { startsWith: { key: filter, value: searchText } } : {},
      searchText && filter === 'user' ? { equalTo: { key: filter, value: getPointerFromId({ collection: collections.User, objectId: searchText }) } } : {},
      searchText && filter === 'driver' ? { equalTo: { key: filter, value: getPointerFromId({ collection: collections.User, objectId: searchText }) } } : {},
      searchText && filter === 'restaurant' ? { equalTo: { key: filter, value: getPointerFromId({ collection: collections.Restaurant, objectId: searchText }) } } : {}
    ]})
    .then((data) => {
      setCount(data.count);
      setItems(data.results.map((item) => { return {
        id: item.objectId, user: item.user, driver: item.driver, restaurant: item.restaurant, type: item.deliveryType,
        status: item.status, promo: item.promo, food: item.food, options: item.options, canceled: item.canceled,
        city: item.city, dateCreated: (new Date(item.createdAt)).toLocaleString(),
        filter: searchText && (filter === 'driver' || filter === 'restaurant') ? filter : undefined,
        payment: !item.canceled && item.status >= FINISHED_ORDER_STATUS ? (filter === 'driver' ? (
          item.options.paymentMethod === 'cash' ? (
            !item.options.freeDelivery ? item.options.service : item.options.service - item.options.delivery
          ) : (
            -item.options.delivery
          )
        ) : (
          item.options.paymentMethod === 'cash' ? (
            (item.options.itemsTotal - (item.options.discount || 0)) * item.restaurant.fee
          ) : (
            -((item.options.itemsTotal - (item.options.discount || 0)) * (1 - item.restaurant.fee))
          )
        )) : undefined
      }}));
    })
    .catch(() => setError(true))
    .finally(() => setLoading(false));
  }

  const initFetch = () => {
    //Fetch regions
    get({ collection: collections.City, queryParams: [ { descending: 'createdAt' } ]})
    .then((data) => setCities(data)).catch(() => {});
    //Fetch items
    fetch();
  }
  //Effect for fetching data
  useEffect(initFetch, []);
  //Render page
  return <Paper elevation={1}>
    <Box display="flex" flexDirection="column" alignItems="center" paddingX={theme.spacing(0.5)}>
      {/*----Title----*/}
      <Box mt={theme.spacing(0.2)}/>
      <Typography variant="h6"><b>{t('menu.orders')}</b></Typography>
      <Box mt={theme.spacing(0.2)}/>
      {/*----Content----*/}
      <Toolbar style={{ width: '100%', overflowX: 'auto', overflowY: 'hidden',
        backgroundColor: selected.length > 0 ? theme.palette.primary.main + '20' : 'transparent' }}>
        {selected.length > 0 ? <>
          <Typography style={{ flex: 1 }} color="inherit" variant="subtitle1">{selected.length + ' ' + t('common.selected')}</Typography>
          <Tooltip title={t('common.export')}><IconButton onClick={exportItems}><GetApp color="primary"/></IconButton></Tooltip>
          <Tooltip title={t('orders.chooseDriver')}><IconButton onClick={() => doAction('chooseDriver')}><Motorcycle color="secondary"/></IconButton></Tooltip>
          <Tooltip title={t('orders.assignDriver')}><IconButton onClick={() => doAction('assignDriver')}><Motorcycle color="action"/></IconButton></Tooltip>
          <Tooltip title={t('orders.performAction')}><IconButton onClick={doAction}><Check color="primary"/></IconButton></Tooltip>
          <Tooltip title={t('orders.cancelManager')}><IconButton onClick={() => doAction('cancelManager')}><Cancel color="error"/></IconButton></Tooltip>
          <Tooltip title={t('common.edit')}><IconButton onClick={edit}><Edit color="action"/></IconButton></Tooltip>
          <Tooltip title={t('common.delete')}><IconButton onClick={remove}><Delete color="error"/></IconButton></Tooltip>
        </> : <>
          <OutlinedInput placeholder={t('common.search')} style={{ minWidth: 200, height: 38 }}
            value={searchText} onChange={(e) => setSearchText(e.target.value)} onKeyUp={(e) => { if (e.key === 'Enter') search() }}
            endAdornment={<InputAdornment position="end">
              <IconButton size="small" onClick={search}><Search color="primary"/></IconButton>
            </InputAdornment>}
          />
          <Box mr={theme.spacing(0.2)}/>
          <Select variant="outlined" style={{ height: 38 }} MenuProps={{ elevation: 2, dir }} value={filter} onChange={(e) => setFilter(e.target.value)}>
            {filters.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
          </Select>
          <Box mr={theme.spacing(0.4)}/>
          <Typography>{t('orders.region')}</Typography>
          <Box mr={theme.spacing(0.2)}/>
          <Select variant="outlined" style={{ height: 38 }} MenuProps={{ elevation: 2 }} value={cityFilter}
            onChange={(e) => search({ newCityFilter: e.target.value })}>
            <MenuItem value="/">/</MenuItem>
            {cities.map((item) => <MenuItem key={item.objectId} value={item.objectId}>{item.name}</MenuItem>)}
          </Select>
          <Box mr={theme.spacing(0.4)}/>
          <Typography>{t('orders.type')}</Typography>
          <Box mr={theme.spacing(0.2)}/>
          <Select variant="outlined" style={{ height: 38 }} MenuProps={{ elevation: 2 }} value={typeFilter}
            onChange={(e) => search({ newTypeFilter: e.target.value })}>
            <MenuItem value="/">/</MenuItem>
            {deliveryTypes.map((item, i) => <MenuItem key={i} value={item}>{t('orders.' + item)}</MenuItem>)}
          </Select>
          <Box mr={theme.spacing(0.4)}/>
          <Typography>{t('orders.status')}</Typography>
          <Box mr={theme.spacing(0.2)}/>
          <Select variant="outlined" style={{ height: 38 }} MenuProps={{ elevation: 2 }} value={statusFilter}
            onChange={(e) => search({ newStatusFilter: e.target.value })}>
            <MenuItem value="/">/</MenuItem>
            {Object.keys(orderStatusDelivery).map((item, i) => <MenuItem key={i} value={i}>{t('orders.' + item)}</MenuItem>)}
          </Select>
          <Box mr={theme.spacing(0.4)}/>
          <Box display="flex" style={{ marginTop: -25 }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <React.Fragment>
                <KeyboardDatePicker disableToolbar variant="inline" format="MM/dd/yyyy" margin="normal" label={t('orders.from')}
                  value={fromDate} onChange={(date) => search({ newFromDate: date })} PopoverProps={{ PaperProps: { elevation: 2 } }}/>
                {fromDate && <IconButton size="small" style={{ alignSelf: 'center', marginTop: 25 }} onClick={() => search({ newFromDate: null })}><Close color="action"/></IconButton>}
                <Box mr={theme.spacing(0.2)}/>
                <KeyboardDatePicker disableToolbar variant="inline" format="MM/dd/yyyy" margin="normal" label={t('orders.to')}
                  value={toDate} onChange={(date) => search({ newToDate: date })} PopoverProps={{ PaperProps: { elevation: 2 } }}/>
                {toDate && <IconButton size="small" style={{ alignSelf: 'center', marginTop: 25 }} onClick={() => search({ newToDate: null })}><Close color="action"/></IconButton>}
              </React.Fragment>
            </MuiPickersUtilsProvider>
          </Box>
          <Box mr={theme.spacing(0.4)}/>
          {(filter !== 'objectId' || !searchText) && <>
            <FormControlLabel
              control={<Checkbox checked={canceledFilter} onChange={(e) => search({ newCanceledFilter: e.target.checked })} color="primary"/>}
              label={t('orders.canceled')}/>
            <Box mr={theme.spacing(0.2)}/>
          </>}
          <FormControlLabel
            control={<Checkbox checked={withoutDriver} onChange={(e) => search({ newWithoutDriverFilter: e.target.checked })} color="primary"/>}
            label={t('orders.withoutDriver')}/>
        </>}
      </Toolbar>
      <TableContainer>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox color="primary" indeterminate={selected.length > 0 && selected.length < items.length}
                  checked={items.length > 0 && selected.length === items.length} onChange={selectAll}/>
              </TableCell>
              {headers.map((item) => (
                <TableCell key={item.id} width={item.width} align={item.align} padding={item.disablePadding ? 'none' : 'default'}>
                  <b>{item.label}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/*----Success----*/}
            {!loading && !error && !empty && items.map((item) => {
              const currency = item.city ? t('currency.' + item.city.currency) : '';
              const isItemSelected = selected.indexOf(item.id) !== -1;
              return <TableRow hover key={item.id} selected={isItemSelected} onClick={(e) => selectItem(item.id)}>
                <TableCell padding="checkbox"><Checkbox color="primary" checked={isItemSelected}/></TableCell>
                <TableCell align={align} padding="none">{item.id}</TableCell>
                <TableCell align={align}>{item.user ? (
                  <Typography>{item.user.fullname}<br/>{item.user.phone || '/'}<br/><b>{'#' + item.user.objectId}</b></Typography>
                ) : '/'}</TableCell>
                <TableCell align={align}>{item.driver ? (
                  <Typography>{item.driver.fullname}<br/>{item.driver.phone || '/'}<br/><b>{'#' + item.driver.objectId}</b></Typography>
                ) : '/'}</TableCell>
                <TableCell align={align}>{item.restaurant ? (
                  <Typography>
                    {item.restaurant.name}<br/>
                    {item.restaurant.phone || '/'}<br/>
                    <b>{'#' + item.restaurant.objectId}</b>
                    {item.restaurant.manager && <><br/><b style={{ color: 'blue' }}>{t('orders.manager') + ' '}</b>{'#' + item.restaurant.manager.objectId}</>}
                  </Typography>
                ) : '/'}</TableCell>
                <TableCell align={align}>{t('orders.' + item.type)}</TableCell>
                <TableCell align={align}>{item.canceled ? (
                  <Box display="flex" alignItems="center">
                    <Cancel color="error"/>
                    <Box mr={theme.spacing(0.05)}/>
                    <Typography color="error">{t('orders.canceled')}</Typography>
                  </Box>
                ) : item.type === 'delivery' ? (() => {
                  const obj = orderStatusDelivery['status' + item.status.toString()];
                  return <Box display="flex" alignItems="center">
                    <obj.icon style={{ color: obj.color }}/>
                    <Box mr={theme.spacing(0.05)}/>
                    <Typography style={{ color: obj.color }}>{t('orders.' + obj.key)}</Typography>
                  </Box>;
                })() : item.type === 'pickup' ? (() => {
                  const obj = orderStatusPickup['status' + item.status.toString()];
                  return <Box display="flex" alignItems="center">
                    <obj.icon style={{ color: obj.color }}/>
                    <Box mr={theme.spacing(0.05)}/>
                    <Typography style={{ color: obj.color }}>{t('orders.' + obj.key)}</Typography>
                  </Box>;
                })() : '/'}</TableCell>
                <TableCell align={align}>{item.promo ? (
                  <Typography>{item.promo.code}<br/><b>{'#' + item.promo.objectId}</b></Typography>
                ) : '/'}</TableCell>
                <TableCell align={align}>{item.food.map((el, i) => item.options.values[i][el.objectId] ? <div key={i}>
                  <Box display="flex" alignItems="center">
                    {(item.status === 0 || item.status === 1) && <IconButton size="small" onClick={() => removeProduct(item.id, i)}><Delete color="error"/></IconButton>}
                    <Typography><b>{'x' + item.options.values[i][el.objectId].quantity + ' '}</b>{el.name}<b>{item.options.values[i][el.objectId].price ? ' | ' + item.options.values[i][el.objectId].price + currency : ''}</b><br/></Typography>
                  </Box>
                  {item.options.values[i][el.objectId].variants && item.options.values[i][el.objectId].variants.map((varItem, j) => <Typography key={j}><b>{varItem.name + ': '}</b>{varItem.value.name}<br/></Typography>)}
                  {item.options.values[i][el.objectId].instructions && item.options.values[i][el.objectId].instructions.length > 0 && <>
                    <Typography><b>{t('orders.instructions') + ':'}</b><br/></Typography>
                    <Typography>{item.options.values[i][el.objectId].instructions.map((instructionItem, j) => {
                      return instructionItem.name + (j < item.options.values[i][el.objectId].instructions.length - 1 ? ', ' : '');
                    })}</Typography>
                  </>}
                  {item.options.values[i][el.objectId].note && <Typography><b>{t('orders.note') + ': '}</b>{item.options.values[i][el.objectId].note}</Typography>}
                </div> : null)}</TableCell>
                <TableCell align={align}>
                  {!item.filter ? <><b>{t('orders.region') + ': '}</b><>{item.city ? item.city.name : '/'}</><br/></> : null}
                  <b>{t('orders.paymentMethod') + ': '}</b><>{item.options.paymentMethod ? t('orders.' + item.options.paymentMethod) : '/'}</><br/>
                  {!item.filter && item.options.note ? <><b>{t('orders.note') + ': '}</b><>{item.options.note}</><br/></> : null}
                  {item.filter === 'restaurant' ? <><b>{t('orders.itemsTotal') + ': '}</b><>{item.options.itemsTotal ? item.options.itemsTotal.toString() + currency : '/'}</><br/></> : null}
                  {item.filter !== 'driver' && item.options.discount ? <><b>{t('orders.discount') + ': '}</b><>{'-' + item.options.discount.toString() + currency}</><br/></> : null}
                  {item.filter !== 'restaurant' && item.options.delivery ? <><b>{t('orders.delivery') + ': '}</b><>{item.options.delivery.toString() + currency}</><br/></> : null}
                  {item.filter !== 'restaurant' && item.options.freeDelivery ? <><b style={{ color: 'red' }}>{t('orders.freeDelivery')}</b><br/></> : null}
                  {!item.filter || item.filter === 'driver'  ? <><b>{t('orders.service') + ': '}</b><>{item.options.service ? item.options.service.toString() + currency : '/'}</><br/></> : null}
                  {!item.filter ? <><b>{t('orders.total') + ': '}</b><>{item.options.total ? item.options.total.toString() + currency : '/'}</></> : null}
                  {item.filter && item.payment !== undefined ? <><b style={{ color: "blue" }}>{t('orders.payment') + ': '}</b><>{item.payment.toFixed(1) + currency}</></> : null}
                </TableCell>
                <TableCell align={align}>{item.dateCreated}</TableCell>
              </TableRow>;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/*----Delete confirmation dialog----*/}
      <Dialog dir={dir} open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('orders.dialog_delete')}</DialogTitle>
        <DialogContent><DialogContentText>{t('common.confirmMsg')}</DialogContentText></DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={itemDelete} color="primary">{t('common.delete')}</Button>
        </DialogActions>
      </Dialog>
      {/*----Edit dialog----*/}
      <Dialog dir={dir} open={showEditDialog} onClose={() => setShowEditDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('common.edit')}</DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center">
            <Typography>{t('orders.status')}</Typography>
            <Box mr={theme.spacing(0.15)}/>
            <Select variant="outlined" style={{ height: 38 }} MenuProps={{ elevation: 2 }} value={newStatus}
              onChange={(e) => setNewStatus(e.target.value)}>
              {Object.keys(orderStatusDelivery).map((item, i) => <MenuItem key={i} value={i}>{t('orders.' + item)}</MenuItem>)}
            </Select>
          </Box>
          <Box mt={theme.spacing(0.15)}/>
          <FormControlLabel label={t('orders.canceled')} onChange={(e) => setNewCanceled(e.target.checked)}
            control={<Checkbox checked={newCanceled} color="primary"/>}/>
          <Box/>
          {type === 'delivery' && <>
            <FormControlLabel label={t('orders.freeDelivery')} onChange={(e) => setOptions({ ...options, freeDelivery: e.target.checked })}
              control={<Checkbox checked={options.freeDelivery} color="primary"/>}/>
            <Box mt={theme.spacing(0.15)}/>
            <OutlinedInput placeholder={t('orders.delivery')} fullWidth type="number" value={options.delivery} onChange={(e) => setOptions({ ...options, delivery: e.target.value })}/>
          </>}
          <Box mt={theme.spacing(0.15)}/>
          <OutlinedInput placeholder={t('orders.service')} fullWidth type="number" value={options.service} onChange={(e) => setOptions({ ...options, service: e.target.value })}/>
          <Box mt={theme.spacing(0.15)}/>
          <OutlinedInput placeholder={t('orders.itemsTotal')} fullWidth type="number" value={options.itemsTotal} onChange={(e) => setOptions({ ...options, itemsTotal: e.target.value })}/>
          <Box mt={theme.spacing(0.15)}/>
          <OutlinedInput placeholder={t('orders.discount')} fullWidth type="number" value={options.discount} onChange={(e) => setOptions({ ...options, discount: e.target.value })}/>
          <Box mt={theme.spacing(0.15)}/>
          <OutlinedInput placeholder={t('orders.total')} fullWidth type="number" value={options.total} onChange={(e) => setOptions({ ...options, total: e.target.value })}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowEditDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={itemEdit} color="primary">{t('common.ok')}</Button>
        </DialogActions>
      </Dialog>
      {/*----Action dialog----*/}
      <Dialog dir={dir} open={showActionDialog} onClose={() => setShowActionDialog(false)} fullWidth PaperProps={{ elevation: 2 }}>
        <DialogTitle>{t('orders.' + action)}</DialogTitle>
        <DialogContent>
          {action === 'assignDriver' ? (
            <OutlinedInput placeholder={t('orders.driverId')} fullWidth value={driverId} onChange={(e) => setDriverId(e.target.value)}/>
          ) : action === 'cancelManager' ? (<>
            <OutlinedInput placeholder={t('orders.reason')} fullWidth value={reason} onChange={(e) => setReason(e.target.value)}/>
            <Box mt={theme.spacing(0.15)}/>
            <FormControlLabel label={t('orders.noNotifs')}
              control={<Checkbox checked={noNotifs} onChange={(e) => setNoNotifs(e.target.checked)} color="primary"/>}/>
          </>) : (<>
            <DialogContentText>{t('common.confirmMsg')}</DialogContentText>
            {action === 'acceptManager' ? <>
              <Box mt={theme.spacing(0.15)}/>
              <FormControlLabel label={t('orders.noChoose')}
                control={<Checkbox checked={noChoose} onChange={(e) => setNoChoose(e.target.checked)} color="primary"/>}/>
            </> : null}
          </>)}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowActionDialog(false)} color="primary">{t('common.cancel')}</Button>
          <Button onClick={itemAction} color="primary">{t('common.ok')}</Button>
        </DialogActions>
      </Dialog>
      {/*----Loading----*/}
      {loading && <>
        <Box mt={theme.spacing(0.8)}/>
        <CircularProgress />
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Error----*/}
      {!loading && error && <>
        <Box mt={theme.spacing(0.6)}/>
        <Error color="error" style={{ width: theme.spacing(12), height: theme.spacing(12) }}/>
        <Typography color="textSecondary">{t('common.problemMsg')}</Typography>
        <Box mt={theme.spacing(0.2)}/>
        <Button variant="contained" color="primary" style={{ paddingInline: theme.spacing(3), height: 35 }} onClick={initFetch}>
          {t('common.tryAgain')}
        </Button>
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Empty----*/}
      {!loading && !error && empty && <>
        <Box mt={theme.spacing(0.4)}/>
        <Typography color="textSecondary">{t('orders.emptyMsg')}</Typography>
        <Box mt={theme.spacing(0.8)}/>
      </>}
      {/*----Success----*/}
      {!loading && !error && !empty && <>
        <TablePagination rowsPerPageOptions={fetchCountOptions} rowsPerPage={rowsPerPage} page={page} count={count}
          onChangePage={changePage} onChangeRowsPerPage={changeRowsPerPage} labelRowsPerPage={t('common.rowsPerPage')}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('common.of')} ${count}`}
          SelectProps={{ MenuProps: { elevation: 2 } }} component="div" style={{ alignSelf: 'flex-end' }}/>
        {total !== undefined && <Typography style={{ alignSelf: 'flex-start' }}><b>{t('orders.total') + ': '}</b>{total.toFixed(1) + currency}</Typography>}
        <Box mt={theme.spacing(0.4)}/>
      </>}
    </Box>
  </Paper>;
}

export default Orders;
